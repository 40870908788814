import React from "react";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import FeaturesCart from "../pages/WebsiteDevelopment/FeaturesCart";
const Services1 = () => {
  return (
    <div className="py-[5%]">
      <div className="text-center leading-[28px] mx-[7%] ">

        <div className="my-[1%]">
          <Heading content=" Creating User-Centric Websites" />
        </div>
        <SubHeading SubHeading="Our team of experts provides comprehensive services in web design, UX, and UI, ensuring your website stands out from the competition. Let us help you create an exceptional online presence today." />
      </div>

      <div className="flex flex-wrap justify-center py-[2%] mx-[5%]">
        <FeaturesCart
          content={
            " User interface (UI) and user experience (UX) design are focused on creating a user-friendly."
          }
          img="/Images/a1.png"
          heading={"UI/UX Design"}
        />
        <FeaturesCart
          content={
            "This involves creating graphic elements such as logos, icons, illustrations, and other visual ."
          }
          img="/Images/a2.png"
          heading={"Graphic Design"}
        />
        <FeaturesCart
          content={
            "This involves designing a website that is optimized for different screen sizes and devices."
          }
          img="/Images/a3.png"
          heading={"Responsive Design"}
        />
        <FeaturesCart
          content={
            "We integrate all your blog post to keep your customers informed about the latest development"
          }
          img="/Images/a4.png"
          heading={"Branding  Design"}
        />
        <FeaturesCart
          content={
            "All SEO campaigns are assigned a Dedicated Project Coordinator who will communicate performance"
          }
          img="/Images/a5.png"
          heading={"Landing Page Design"}
        />
        <FeaturesCart
          content={
            "We can optimize your website to make it SEO friendly and help it reach the top in Google search "
          }
          img="/Images/a6.png"
          heading={"Identity Design"}
        />
      </div>
    </div>
  );
};

export default Services1;
