import React from "react";
import Heading from "../../components/Heading";

const ClientMobileDev = () => {
  // const [showMore, setShowMore] = useState(false);

  // const text =
  //   "At Abscod Informatics, we are dedicated to meeting all your digital needs with our exceptional services. Whether you require Mobile app development, Web development, Web Designing, Software development, or Digital marketing services, we have the expertise to deliver top-quality solutions. Our experienced team uses the latest techniques and technologies to help you achieve your goals, and we pride ourselves on delivering outstanding results that set you apart in a competitive digital landscape.We don't just connect you to our trusted network, but we also fulfill our promises. We offer a range of services, including Cross-platform and Hybrid Mobile app development, Content marketing, Android and IOS app development, SEO, Social media marketing, and Logo design. Our digital support reinforces your business approach, and our Mobile app developers strive to establish a robust software foundation for your new-age mobile application, which leads to exponential growth and profitability in no time. we are proud to be among the top IT outsourcing companies in India. Our partnership with you for business extension and growth is based on trust, quality, and excellence in service. At Abscod Informatics, we are committed to helping you succeed in the digital world."
  // const shortText = text.substring(0, text.length / 4); // take only first 25% of the text

  // const showMoreHandler = () => {
  //   setShowMore(true); // update state to show full text
  // };

  return (
    <div className="mx-[6%] text-center ">
      <div className="py-[5%]">
        <div className="flex flex-col md:flex-row flex-wrap justify-between">
          <Heading
            content={"India's Most Trusted Mobile App Development Company"}
          />
          <div className="  bg-gradient-to-r from-yellow-500 via-purple-500 to-black w-40 mx-auto h-2 rounded-full mt-[2%] "></div>
        </div>
        <div className=" flex flex-wrap  lg:flex-nowrap mt-[5%]">
          <div className="md:w-1/3">
            <img
              src="/Images/homeCompany.webp"
              alt=""
              className="rounded-lg object-cover w-[100%]  lg:h-[100%] xl:h-[84%] xl:mr-[-10px] "
            />
          </div>
          <div className="md:w-7/12 text-justify ml-0 md:ml-[3%] mt-[5%] text-[18px] leading-[35px] ">
            <div className="">
              At <b>Abscod Informatics</b>, we are dedicated to meeting all your
              digital needs with our exceptional services. Whether you require{" "}
              <h2 className="inline font-bold"> Mobile app development</h2>,
              <h2 className="inline font-bold"> Web development</h2>,
              <h2 className="inline font-bold"> Web Designing</h2>,
              <h2 className="inline font-bold"> Software development</h2>, or
              <h2 className="inline font-bold"> Digital marketing services</h2>,
              we have the expertise to deliver top-quality solutions. Our
              experienced team uses the latest techniques and technologies to
              help you achieve your goals, and we pride ourselves on delivering
              outstanding results that set you apart in a competitive digital
              landscape.
            </div>
            {/* <h1 className="mt-[1%] ">
              We don't just connect you to our trusted network, but we also
              fulfill our promises. We offer a range of services, including
              <b> Cross-platform</b> and Hybrid <b>Mobile app development</b>,
              <b> Content marketing</b>,<b> Android and IOS app development</b>,
              <b> SEO</b>,<b> Social media marketing</b>, and{" "}
              <b> Logo design</b>. Our digital support reinforces your business
              approach, and our <b>Mobile app developers</b> strive to establish
              a robust software foundation for your new-age mobile application,
              which leads to exponential growth and profitability in no time. we
              are proud to be among the top IT outsourcing companies in India.
              Our partnership with you for business extension and growth is
              based on trust, quality, and excellence in service. At Abscod
              Informatics, we are committed to helping you succeed in the
              digital world.
            </h1> */}
          </div>
          {/* <div className="md:w-[50%] text-justify text-[18px] leading-[30px] lg:hidden  md:block sm:block block  ">
            <h1 className="mb-4 ">{showMore ? text : shortText}</h1>
            {!showMore && (
              <button onClick={showMoreHandler} className="font-bold">
                Show more
              </button>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ClientMobileDev;
