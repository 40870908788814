import React from "react";
import Navbar from "../NavBar/Navbar";
import HeroSection from "../../components/HeroSection";
import Client from "../../pages/client/Client";
import Services1 from "../../components/Services1";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import WebDesignPre from "./WebDesignPre";

import FormHeroSection from "../../components/FormHeroSection";

const WebDesign = () => {
  return (
    <>
      <Navbar />
      <HeroSection
        background="linear-gradient(to right, #194f79, #194f79)"
        heading="Discover the Power of Our Solutions"
        img="/Images/webDesignHome.png"
        subheading="designs to boost businesses"
        deatailhedaing="Curated UI/UX design services that solve business challenges and create delightful user experiences.Overlay the image or video with a tagline that captures your value proposition, such as Innovative Solutions for Your Business."
      />
      <Client />
      <FormHeroSection />
      <Services1 />
      <WebDesignPre />
      <FAQ
        title="What is web design?"
        title1="What is user experience (UX) design?"
        title2="What is user interface (UI) design?"
        title3="Why is good web design important for my business?"
        title4="What is the difference between UX and UI design?"
        paragraph="Web design is the process of creating the visual and functional elements of a website, including layout, color scheme, typography, and imagery. It involves both the creative and technical aspects of designing a website."
        paragraph1="User experience (UX) design is the process of creating websites that are optimized for usability and user satisfaction. It involves understanding the user's needs and behavior and designing a website that meets those needs and is easy to use."
        paragraph2="User interface (UI) design is the process of creating the visual and interactive elements of a website, including buttons, forms, and menus. It focuses on making the website visually appealing and easy to use."
        paragraph3="Good web design is important for your business because it can impact your website's performance and user engagement. A well-designed website can help you attract and retain customers, build trust and credibility, and increase conversions and revenue."
        paragraph4="UX design focuses on the overall experience and usability of a website, while UI design focuses on the visual and interactive elements of a website. UX designers prioritize functionality and user satisfaction, while UI designers prioritize aesthetics and user engagement. Both are important aspects of web design and should be considered when creating a website."
      />
      <Footer />
    </>
  );
};

export default WebDesign;
