import React from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";
import FeaturesCart from "../WebsiteDevelopment/FeaturesCart";
const SEOWorkWeProvide = () => {
  return (
    <div className="py-[5%] mx-[5%]">
      <div className="text-center leading-[28px] ">
        <div className="my-[1%] text-center">
          <Heading content="Highlighting Outstanding Websites: Because they Deserve the Spotlight!" />
        </div>
        <SubHeading SubHeading="Allow Our SEO Experts to Boost Your Website's Ranking on Top Search Engines such as Google, Yahoo, and Bing!" />
      </div>

      <div className="flex flex-wrap justify-center py-[2%]">
        <FeaturesCart
          content={
            "All SEO campaigns are assigned a Dedicated Project Coordinator who will communicate performance and outcomes."
          }
          img="/Images/SocialAds1.png"
          heading={"SEO Manager"}
        />
        <FeaturesCart
          content={
            "We can optimize your website to make it SEO friendly and help it reach the top in Google search results."
          }
          img="/Images/KeywordTargeting.png"
          heading={"Keyword Targeting"}
        />
        <FeaturesCart
          content={
            "We promote effective communication between you and your audience by using easy-to-use components"
          }
          img="/Images/ContentRelevancy.png"
          heading={"Content Relevancy"}
        />
        <FeaturesCart
          content={
            "We integrate all your blog post to keep your customers informed about the latest development"
          }
          img="/Images/LinkBuilding.png"
          heading={"Link Building"}
        />
        <FeaturesCart
          content={
            "All SEO campaigns are assigned a Dedicated Project Coordinator who will communicate performance and outcomes."
          }
          img="/Images/Tracking.png"
          heading={"Keyword Tracking"}
        />
        <FeaturesCart
          content={
            "We can optimize your website to make it SEO friendly and help it reach the top in Google search results."
          }
          img="/Images/WebsiteAnalytics.png"
          heading={"Website Analytics"}
        />
      </div>
    </div>
  );
};

export default SEOWorkWeProvide;
