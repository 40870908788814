import React from "react";
import Navbar from "../NavBar/Navbar";
import ContactLandingPage from "./ContactLandingPage";
import Footer from "../Footer/Footer";


const ContactUs = () => {
  return (
    <div
      className="bg-[#000000]  "
    
    >
      <Navbar />
      <ContactLandingPage />
      <Footer />
    </div>
  );
};

export default ContactUs;
