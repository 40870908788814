import React from 'react'
import Navbar from './pages/NavBar/Navbar';
import Footer from './pages/Footer/Footer';

const TermsAndConditions = () => {
  return (
    <div>
      <Navbar />
      <div className=" h-24 bg-black"></div>
      <div className="px-[6%] text-lg py-[18%] md:py-[8%]  text-black ">
        <h1 className="text-center text-[30px] font-bold leading-8">
          <strong>Terms and Conditions </strong>
        </h1>
        <p className="text-center pb-3">Welcome to Abscod Informatics!</p>
        <p className="py-3  font-semibold text-[18px]">
          <strong> Introduction</strong>
        </p>
        <p className="text-justify">
          Welcome to the website of Abscod Informatics. These terms and
          conditions apply to your use of our website. By using our website, you
          agree to comply with and be bound by these terms and conditions.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Use of the Website</strong>
        </h1>
        <p className="text-justify">
          You may use our website for lawful purposes only. You are not
          permitted to use our website for any unlawful or fraudulent activity
          or to infringe on the rights of others. You are solely responsible for
          all content that you post or upload to our website.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Intellectual Property</strong>
        </h1>
        <p className="text-justify">
          All content on our website, including but not limited to text,
          graphics, images, logos, and software, is the property of Abscod
          Informatics or its licensors and is protected by intellectual property
          laws. You may not copy, reproduce, or distribute any content on our
          website without our prior written consent.
        </p>

        <p className="text-justify">
          This Agreement shall begin on the date hereof. Our Terms and
          Conditions were created with the help of the .
        </p>

        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Disclaimer of Warranties</strong>
        </h1>
        <p className="text-justify">
          Our website is provided on an "as is" and "as available" basis. We do
          not guarantee that our website will be uninterrupted or error-free. We
          make no warranties, express or implied, regarding the accuracy,
          completeness, or reliability of any content on our website.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Limitation of Liability</strong>
        </h1>
        <p className="text-justify">
          In no event shall Abscod Informatics be liable for any damages,
          including without limitation, direct or indirect, incidental,
          consequential, or punitive damages, arising out of or in connection
          with the use or inability to use our website.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Indemnification</strong>
        </h1>
        <p className="text-justify">
          You agree to indemnify and hold Abscod Informatics and its officers,
          directors, employees, and agents harmless from any and all claims,
          demands, losses, damages, or expenses arising out of or in connection
          with your use of our website.
        </p>

        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Modification of Terms</strong>
        </h1>
        <p className="text-justify">
          We reserve the right to modify these terms and conditions at any time
          without prior notice. Your continued use of our website after any
          changes have been made constitutes your acceptance of the new terms
          and conditions.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Governing Law</strong>
        </h1>
        <p className="text-justify">
          These terms and conditions shall be governed by and construed in
          accordance with the laws of the jurisdiction in which Abscod
          Informatics is located, without giving effect to any principles of
          conflicts of law.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Entire Agreement</strong>
        </h1>
        <p className="text-justify">
          These terms and conditions constitute the entire agreement between you
          and Abscod Informatics with respect to your use of our website.
        </p>
        <h1 className="py-3  font-semibold text-[18px]">
          <strong>Contact Us</strong>
        </h1>
        <p className="text-justify">
          If you have any questions about these terms and conditions, please
          contact us at info@abscod.com.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions