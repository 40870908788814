import React from "react";
import CustomerCart from "./CustomerCart";
import Counter from "../../components/Counter";

const Customer = () => {
  return (
    <div
      className="my-[3%]"
      style={{
        backgroundImage: `url("/Images/computer.jpg")`,
        backgroundSize: "cover",
      }}
    >
      <div className="    ">
        <div className="flex flex-wrap justify-around  md:justify-evenly md:py-[5%] py-[8%] ">
          <CustomerCart
            content="Projects Launched"
            value={<Counter maxCount={50} />}
            add="+"
            img="/Images/projectlaunch.svg"
          />
          <CustomerCart
            content="Satisfied Customers"
            value={<Counter maxCount={200} />}
            add="+"
            img="/Images/satisfied.svg"
          />
          <CustomerCart
            content="Years Experience"
            value={<Counter maxCount={3} />}
            add="+"
            img="/Images/projectclient1.svg"
          />
          <CustomerCart
            content="Client Retention"
            value={<Counter maxCount={98} />}
            add="%"
            img="/Images/projectclient2.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Customer;
