import React from "react";

const WebsiteStack = () => {
  const images = [
    {
      id: 1,
      src: "/Images/html5.webp",
      alt: "Image 1",
      name: "html5",
    },
    {
      id: 2,
      src: "/Images/css3.webp",
      alt: "Image 2",
      name: "css3",
    },
    {
      id: 3,
      src: "/Images/angular.webp",
      alt: "Image 3",
      name: "angular",
    },
    {
      id: 4,
      src: "/Images/bootstrap.webp",
      alt: "Image 1",
      name: "bootstrap",
    },
    {
      id: 5,
      src: "/Images/android.webp",
      alt: "Image 2",
      name: "android",
    },
    {
      id: 6,
      src: "/Images/blockchain.webp",
      alt: "Image 3",
      name: "blockchain",
    },
    {
      id: 7,
      src: "/Images/bitcoin.webp",
      alt: "Image 1",
      name: "bitcoin",
    },
    {
      id: 8,
      src: "/Images/dotnet.webp",
      alt: "Image 2",
      name: "dotnet",
    },
    {
      id: 9,
      src: "/Images/flutter.webp",
      alt: "Image 3",
      name: "flutter",
    },
    {
      id: 10,
      src: "/Images/ios.webp",
      alt: "Image 1",
      name: "ios",
    },
    {
      id: 11,
      src: "/Images/iot.webp",
      alt: "Image 2",
      name: "iot",
    },
    {
      id: 12,
      src: "/Images/laravel.webp",
      alt: "Image 3",
      name: "laravel",
    },
    {
      id: 13,
      src: "/Images/node.png",
      alt: "Image 1",
      name: "nodejs",
    },
    {
      id: 14,
      src: "/Images/php.webp",
      alt: "Image 2",
      name: "php",
    },
    {
      id: 15,
      src: "/Images/python.webp",
      alt: "Image 3",
      name: "python",
    },
    {
      id: 1,
      src: "/Images/reactnative.webp",
      alt: "Image 1",
      name: "reactnative",
    },
    {
      id: 16,
      src: "/Images/ionic.webp",
      alt: "Image 2",
      name: "ionic",
    },
    {
      id: 17,
      src: "/Images/chatbot.webp",
      alt: "Image 3",
      name: "chatbot",
    },
    {
      id: 18,
      src: "/Images/wordpress.png",
      alt: "Image 1",
      name: "wordpress",
    },
    {
      id: 19,
      src: "/Images/sharepoint.png",
      alt: "Image 2",
      name: "sharepoint",
    },
   
  ];
  return (
    <div className="p-[6%] flex  flex-wrap lg:flex-nowrap lg:gap-5 ">
      <div className="lg:w-1/2 md:w-full">
        <div className=" text-[#000000]  font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[28px] md:leading-[58px] mb-[5%] md:mb-[3%]">
          Custom Web Development Company
        </div>
        <p className="mb-[1rem] text-justify">
          As a leading website development company, our focus is on providing
          exceptional solutions and a superior user experience to our clients.
          We take great pride in delivering top-notch services that keep our
          customers satisfied and coming back to us for their web development
          needs.
        </p>
        <p className="mb-[1rem] text-justify">
          we utilize the latest and most advanced technologies to develop
          tailored web services that establish your brand's presence online. Our
          development process is driven by a focus on delivering solutions that
          are precisely aligned with the unique needs of your customers.
        </p>
        <p className="mb-[1rem] text-justify">
          We follow the same strategy for every start-up, be it a medium or a
          large scale organization, to understand how the user interacts and
          focus on implementing the best tactics to ensure maximum engagement
          and satisfaction to the user. With years of experience in providing
          customized web development services, we have successfully managed to
          deliver tons of projects in multiple industries.
        </p>
        <p className="mb-[1rem] text-justify">
          Our team of qualified developers comprises expert professionals with a
          wealth of experience in a wide range of technologies, including HTML5,
          CSS3, JavaScript, WordPress, PHP, Flutter, Node.js, ASP.NET, and Full
          Stack technologies. We leverage this expertise to deliver top-quality
          websites to our clients, while ensuring timely delivery and efficient
          project management. We are committed to delivering the highest quality
          solutions in the shortest possible timeframe, without compromising on
          quality or reliability
        </p>
      </div>
      <div className="lg:w-1/2 md:w-full ">
        <h1 className="text-[2.5rem] font-thin text-center mb-[30px]">
          Hire experts in
        </h1>
        <ul className="grid lg:grid-cols-4 md:grid-col-6  grid-cols-3 gap-2 md:gap-4">
          {images.map((image) => (
            <li
              key={image.id}
              className="text-center  object-cover flex flex-col items-center border-[2px] pt-[3%]"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-12  h-12"
              />
              <h1 className="uppercase px-[1%]">{image.name}</h1>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WebsiteStack;
