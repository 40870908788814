import React from 'react'
import {AiFillStar} from "react-icons/ai"

const WebsiteClientFeedbackCart = ({clientName,feedback,companyName}) => {
  return (
    <div className="bg-white rounded-[8px] shadow-2xl m-2  p-8 ">
      <h1 className="lg:h-[180px] md:h-[200px] h-[230px]">{feedback}</h1>
      <div className="flex justify-between items-center">
        <div>
          <h1 className="font-[500]  text-[18px]">{clientName}</h1>
          <h2>{companyName}</h2>
        </div>
        <div className="border-[2px] flex justify-evenly items-center p-4 w-16 h-6">
          5 <AiFillStar className="ml-1 text-[#fca102]" />
        </div>
      </div>
    </div>
  );
}

export default WebsiteClientFeedbackCart