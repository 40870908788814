import React from "react";
import Navbar from "../NavBar/Navbar";
import AboutHome from "./AboutHome";
import Customer from "../Customer/Customer";
import About from "../../components/About";
import Client from "../client/Client";
import Teams from "./Teams";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import FormHeroSection from "../../components/FormHeroSection";
const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <AboutHome />
      <About />
      <FormHeroSection />
      <Customer />
      <Teams />
      <Client />
      <FAQ
        title="What services does your company offer?"
        title1="How experienced is your team?"
        title2="Can you customize your services to meet my specific needs?"
        title3=" What is your approach to digital marketing?"
        title4=" Do you offer ongoing support after project completion?"
        paragraph="Our company offers a range of services, including website development, mobile applications, web designing (UX/UI), digital marketing, software development, marketing, search engine optimization, social media marketing, content marketing, logo design, and pay per click marketing."
        paragraph1="Our team consists of experienced professionals who have spent several years in their respective fields. They are proficient in the latest tools and technologies used in the industry and continuously upgrade their skills to stay relevant."
        paragraph2="Yes, we can customize our services to cater to your specific requirements. We understand that each business is unique, and we work closely with our clients to offer tailor-made solutions that best fit their needs."
        paragraph3="Our approach to digital marketing involves a combination of research, analysis, and creativity. We conduct thorough market research to understand your target audience, analyze your competition, and develop a customized strategy that delivers measurable results."
        paragraph4=" Yes, we offer ongoing support to our clients even after the project completion. Our team is always available to address any concerns or issues that you may have and ensure that your website or application is up-to-date and functioning optimally."
      />
      <Footer />
    </div>
  );
};

export default AboutUs;
