import React from "react";
import Navbar from "../NavBar/Navbar";
import MobileAppDevelopmentHome from "./MobileAppDevelopmentHome";
import MobileAppServicesArea from "./MobileAppServicesArea";
import Footer from "../Footer/Footer";
import FormHeroSection from "../../components/FormHeroSection";
import MobileAppProcess from "./MobileAppProcess";
import MobileFeature from "./MobileFeature";
import FAQ from "../../components/FAQ";
import MobileAppDevelopmentServices from "./MobileAppDevelopmentServices";

const MobileAppDevelopment = () => {
  return (
    <>
      <Navbar />
      <MobileAppDevelopmentHome />
      <MobileAppDevelopmentServices />
      <FormHeroSection />
      <MobileAppProcess/>
      <MobileAppServicesArea />
      <MobileFeature />
      <FAQ
        title="How long does it take to build a mobile app?"
        title1="How long does it take to build a mobile app?"
        title2="How long does it take to build a mobile app?"
        title3="For what businesses do you offer mobile app development services?"
        title4="For what businesses do you offer mobile app development services?"
        paragraph=" The time duration of building a mobile app is dependent on several
        factors. This includes your project requirement and budget,
        technology, features and functionalities, type of mobile app you want
        to build, such as an ecommerce app, fintech app, educational app and
        others. We break the project deliverables into set milestones on which
        we proceed only after getting your go-ahead. This way, we ensure the
        timely completion of every milestone and complete assurance of quality
        delivery."
        paragraph1="We manage the end-to-end requirements of your mobile app development
        needs and offer solutions across technology and platforms.
        Furthermore, we offer services like marketing to keep up with the
        holistic requirements of your mobile app."
        paragraph2=" Expand My Business manages the end-to-end requirement of your mobile
        app development requirements. We help you find the best suited partner
        from our pool of verified partners and agencies. Further, we assign
        you your delivery manager on our platform, who is responsible for
        handling your project for assured quality of services."
        paragraph3="  We have the experience of working with businesses across several
        industries and sectors. We handle hundreds of mobile application
        development service requests on a daily basis. With our vast pool of
        1000+ verified partners, we are capable of offering flexible solutions
        for all kinds of service requests."
        paragraph4="  We have the experience of working with businesses across several
        industries and sectors. We handle hundreds of mobile application
        development service requests on a daily basis. With our vast pool of
        1000+ verified partners, we are capable of offering flexible solutions
        for all kinds of service requests."
      />
      <Footer />
    </>
  );
};

export default MobileAppDevelopment;
