import React, { useState } from "react";
import Button from "../NavBar/Button";
import FreeQuote from "../FreeQuotes/FreeQuote";

const SEOServices = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  };
  return (
    <>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
      <div className="p-[6%] md:p-[1%] lg:px-[5%] lg:py-1 ">
        <div className="text-[2.5rem] mb-[0.5rem] pb-[3%]  text-center font-[500] leading-[1.2]">
          <b> SEO Services Includes</b>
        </div>
        <div className="relative mb-[5%]">
          <div className="w-full h-2 border-b border-[#bbb]"></div>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center w-10 h-4">
            <div className="w-12 h-4 bg-white rounded-full">
              <div className="h-12 w-12  mt-[-60px]  border-b-2  border-[#bbb] rounded-full">
               
                <img
                  src="/Images/circle.png"
                  alt=""
                  className="mt-[40px] object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-0 gap-10">
          <div className="p-8 md:border-l-0 shadow-lg md:shadow-none md:border-t-0 py-[10%] md:border-[1px]">
            <img
              src="/Images/KeywordResearch.png"
              alt=""
              className="object-cover"
              width="60px "
            />
            <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
              Keyword Research
            </h2>
            <p className="mb-[16px] text-justify font-[400] text-[18px]">
              Being the first of any SEO campaign. As a leading SEO services
              agency, we believe in finding and working on the keywords that
              offer the opportunity to rank well in search engines. Researching
              for the most appropriate targeted keywords is how we start the
              process.
            </p>
          </div>
          <div className="p-8 md:border-t-0 shadow-lg md:shadow-none md:border-[1px] py-[10%]">
            <img
              src="/Images/On-PageSEO.png"
              alt=""
              className="object-cover"
              width="90px"
            />
            <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
              On-Page SEO
            </h2>
            <p className="mb-[16px] text-justify font-[400] text-[18px]">
              As the top SEO company in the industry, we take care of the
              outside elements and help you with the technical setup like
              textual and visual content and user-friendliness of your website,
              so you get a higher ranking on search engines like creating
              high-quality backlinks for your website Google.
            </p>
          </div>
          <div className="p-8 md:border-t-0 shadow-lg md:shadow-none md:border-r-0 md:border-[1px] py-[10%]">
            <img
              src="/Images/Off-PageSEO.jpg"
              alt=""
              className="object-cover"
              width="70px"
            />
            <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
              Off-Page SEO
            </h2>
            <p className="mb-[16px] text-justify font-[400] text-[18px]">
              We make sure that search engines like Google see your site in
              terms of credibility, authority, popularity, and relevance with
              our effective off-page SEO services. We make efforts outside your
              website, like creating high-quality backlinks for your website.
            </p>
          </div>
          <div className="p-8 md:border-l-0 shadow-lg md:shadow-none md:border-[1px] py-[10%]">
            <img
              src="/Images/eCommerceSEO.png"
              alt=""
              className="object-cover"
              width="90px"
            />
            <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
              eCommerce SEO
            </h2>
            <p className="mb-[16px] text-justify font-[400] text-[18px]">
              Our eCommerce SEO services help your online store rank on top of
              search results so more and more online buyers can contact and buy
              from your business. If you are also looking to boost your
              eCommerce store's presence, then our tech-enabled eCommerce SEO
              services can help you bring in more orders.
            </p>
          </div>
          <div className="p-8 md:border-[1px] shadow-lg md:shadow-none py-[10%]">
            <img
              src="/Images/ContentWriting.png"
              alt=""
              className="object-cover"
              width="80px"
            />
            <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
              Content Writing
            </h2>
            <p className="mb-[16px] text-justify font-[400] text-[18px]">
              We make your website effective with our content writing services
              by creating content that represents your brand or services
              perfectly. Great marketing is only successful when you can
              convince your visitors about your products, and that's what we
              deliver with our high-quality content marketing services
            </p>
          </div>
          <div className="p-8 border-[1px] shadow-lg md:shadow-none md:border-r-0 py-[10%]">
            <img
              src="/Images/DedicatedSEO.png"
              alt=""
              className="object-cover"
              width="70px"
            />
            <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
              Dedicated SEO Professionals
            </h2>
            <p className="mb-[16px] text-justify font-[400] text-[18px]">
              Being the first of any SEO campaign. As a leading SEO services
              agency, we believe in finding and working on the keywords that
              offer the opportunity to rank well in search engines. Researching
              for the most appropriate targeted keywords is how we start the
              process.
            </p>
          </div>
        </div>
        <div className="lg:w-[20%] my-[3%] mx-auto">
          <Button
            onClick={popHandler}
            content="Request A Quote"
            color="black"
            background="linear-gradient(to right, #FFDB16, #FFDB16"
          />
        </div>
      </div>
    </>
  );
};

export default SEOServices;
