import React, { useState } from "react";
import Heading from "../../components/Heading";
import FreeQuote from "../FreeQuotes/FreeQuote";

const MobileAppServicesArea = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  };
  return (
    <>
      <div className="mx-[7%] py-[7%] ">
        <div className="text-center mb-[3%]">
          <Heading content="App Development Services" />
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-center">
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/shopping.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Shopping Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/delivery.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Delivery Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/fitness.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Fitness Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2  "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/socialmedia12.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Social Media Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/entertainment.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Entertainment Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/photography.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Photography Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/travel.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Travel Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/weather.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Weather Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/finance1.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Finance Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/News.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                News Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/education1.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Education Apps
              </p>
            </div>
          </div>
          <div
            className="group bg-white rounded-lg  hover:bg-[#e87a3b] transition-all duration-300  text-white font-bold py-3 px-2 "
            onClick={popHandler}
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex  items-center content-center  text-center">
              <img
                src="/Images/health.png"
                alt=""
                className="w-12 object-cover bg-black rounded-full group-hover:bg-[#e87a3b] transition-all duration-300"
              />
              <p className="ml-4 text-[20px] font-[600] group-hover:text-white text-black transition-all duration-300">
                Health Apps
              </p>
            </div>
          </div>
        </div>
      </div>

      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
    </>
  );
};

export default MobileAppServicesArea;







//  <div
//             className=" shadow-md bg-red-500 text-white font-bold py-3 px-2 rounded"
//             onClick={popHandler}
//           >
//             <div className="flex  items-center content-center  text-center">
//               <img src="/Images/delivery.png" alt="" className="w-12" />
//               <p className="ml-4 text-[20px]"> Delivery Apps</p>
//             </div>
//           </div>
//           <div
//             className=" shadow-md bg-red-500 text-white font-bold py-3 px-2 rounded"
//             onClick={popHandler}
//           >
//             <div className="flex  items-center content-center  text-center">
//               <img src="/Images/delivery.png" alt="" className="w-12" />
//               <p className="ml-4 text-[20px]"> Delivery Apps</p>
//             </div>
//           </div>
//           <div
//             className=" shadow-md bg-red-500 text-white font-bold py-3 px-2 rounded"
//             onClick={popHandler}
//           >
//             <div className="flex  items-center content-center  text-center">
//               <img src="/Images/delivery.png" alt="" className="w-12" />
//               <p className="ml-4 text-[20px]"> Delivery Apps</p>
//             </div>
//           </div>
//           <div
//             className=" shadow-md bg-red-500 text-white font-bold py-3 px-2 rounded"
//             onClick={popHandler}
//           >
//             <div className="flex  items-center content-center  text-center">
//               <img src="/Images/delivery.png" alt="" className="w-12" />
//               <p className="ml-4 text-[20px]"> Delivery Apps</p>
//             </div>
//           </div>
//           <div
//             className="shadow-md bg-blue-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Shopping Apps
//           </div>
//           <div
//             className="shadow-md bg-green-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Fitness Apps
//           </div>
//           <div
//             className="shadow-md bg-purple-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Social Media Apps
//           </div>
//           <div
//             className="shadow-md bg-yellow-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Entertainment Apps
//           </div>
//           <div
//             className="shadow-md bg-teal-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Travel Apps
//           </div>
//           <div
//             className="shadow-md bg-indigo-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Photography Apps
//           </div>
//           <div
//             className="shadow-md bg-pink-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Weather Apps
//           </div>
//           <div
//             className="shadow-md bg-orange-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Finance Apps
//           </div>
//           <div
//             className="shadow-md bg-gray-500 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             News Apps
//           </div>
//           <div
//             className="shadow-md bg-teal-600 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Education Apps
//           </div>
//           <div
//             className="shadow-md bg-pink-600 text-white font-bold py-6 px-4 rounded"
//             onClick={popHandler}
//           >
//             Health Apps
//           </div> 