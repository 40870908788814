import React ,{useRef} from "react";
// import ContactForm from "../ContactUs/ContactForm";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Pagination } from "swiper";
SwiperCore.use([Autoplay, Pagination]);
const SEOHome = () => {
      const swiperRef = useRef(null);
       const handleSwiper = (swiper) => {
         swiperRef.current = swiper;
       };

       const handleMouseEnter = () => {
         if (swiperRef.current) {
           swiperRef.current.autoplay.stop();
         }
       };
        const handleMouseLeave = () => {
          if (swiperRef.current) {
            swiperRef.current.autoplay.start();
          }
        };
  return (
    <div>
      <div className=" h-24 bg-black"></div>
      <div className="px-4 md:pt-[10%] pt-[5%] lg:px-1 xl:px-[6%] lg:pt-[3%] pb-[3%] bg-[#f9f9fd] xl:flex">
        <div className="w-full md:w-full lg:w-7/12">
          <h1 className="lg:text-[3.5rem] font-[Gilroy_Heavy] font text-[2.5rem] mb-5 leading-[1.2] lg:font-[900] ">
            Award Winning SEO & Digital Marketing Company
          </h1>
          <p className="text-[18px] font-[sans-serif] font-[400] mr-8 text-justify mb-[1rem]  leading-[1.5]">
            Abscod Online Marketing deals into 360 Degree digital marketing
            services including SEO, Social Media Marketing, Influencer
            Marketing, Celebrity Endorsements as well as Paid Digital Marketing.
            we have a proven track record of helping brands reduce their
            reliance on paid marketing investments and achieve sustainable
            organic growth on search engines. By leveraging the latest SEO
            techniques and strategies, we help our clients achieve better ROI
            and increase sales without the need for paid marketing.
          </p>
          <div
            className="mx-[3%] mt-[10%]  "
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Swiper
              style={{
                "--swiper-pagination-color": "#fbcc0d",
                "--swiper-pagination-bullet-inactive-color": "#000000",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-size": "16px",
              }}
              slidesPerView={1}
              loop={true}
              autoplay={{ delay: 1500 }}
              // pagination={{}}
              pagination={{
                // el: ".swiper-pagination-bullet",
                clickable: true,
              }}
              // navigation={true}
              modules={[Pagination]}
              className="mySwiper"
              onSwiper={handleSwiper}
            >
              <SwiperSlide>
                <h1 className="text-[36px] font-[National] text-center font-[700]">
                  200+ Projects Handled
                </h1>
                <p className="text-[18px] text-center font-[600] mb-[10%] lg:mb-[5%] md:mb-[7%]">
                  Google Certified
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="text-[36px] font-[National] text-center font-[700]">
                  Generated 25% More Organic Traffic
                </h1>
                <p className="text-[18px] text-center font-[600] mb-[10%] lg:mb-[5%] md:mb-[7%]">
                  Every Quarter
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="text-[36px] text-center font-[National] font-[800] leading-[1.2]">
                  Worked on 50,000+ Keywords
                </h1>
                <p className="text-[20px] text-center leading-[1.2] font-[600]  mb-[10%] lg:mb-[5%] md:mb-[7%]">
                  Till Date
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEOHome;
