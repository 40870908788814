import React from "react";
import Navbar from "../NavBar/Navbar";
import Main from "../../components/Main"
import FormHeroSection from "../../components/FormHeroSection";
import Client from "../client/Client";
import ClientMobileDev from "../client/ClientMobileDev";
import Customer from "../Customer/Customer";
import About from "../../components/About";
import Services from "../../components/Services";
import Footer from "../Footer/Footer";
import Testimonial from "../../components/Testimonial";
const Home = () => {
  return (
    <>
      <Navbar />
      <Main />
      <FormHeroSection />
      <Client />
      <About />
      <Customer />
      <ClientMobileDev />

      <Services />
      <Testimonial />
      <Footer />
    </>
  );
};

export default Home;
