import React from "react";

const FeaturesCart = ({ img, heading, content, background }) => {
  return (
    <div className="m-[1%] mb-[7%] md:mb-[3%] bg-white  flex flex-col content-evenly justify-evenly box-border border-[2px] items-start text-[#131111] px-[20px] py-[20px] max-w-[350px]  rounded-[30px] ">
      <div className="mx-auto h-32">
        <img src={img} alt="" className="h-28  object-cover" />
      </div>
      <h1 className=" mx-auto font-[500] mb-2 md:font-[700] text-[20px] md:text-[25px] leading-[22px] md:leading-[28px] ">
        {heading}
      </h1>

      <p className="text-[#202121]  text-justify mb-[5px]  ">{content}</p>
    </div>
  );
};

export default FeaturesCart;
