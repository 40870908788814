import React from "react";
import ContactForm from "./ContactForm";

import { FaInstagramSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ImYoutube2, ImLinkedin2, ImFacebook } from "react-icons/im";

const ContactLandingPage = () => {
  return (
    <>
      <div className="pb-[7%] flex justify-center md:flex-row lg:flex-row flex-col pt-[25%] md:pt-[12%] lg:pt-[9%]">
        <div className=" hidden sm:block md:mr-[1%] lg:mr-[3%] group">
         
          <div className="cursor-pointer mb-[15%] hover:!blur-none group-hover:scale-[0.85] hover:!scale-100 duration-500 w-56 h-40 md:mx-2 mx-1 bg-white rounded-lg shadow-md flex flex-col justify-center content-evenly items-center">
            <img src="/Images/emailContact.png" alt="" className="w-10 mb-1" />
            <h3 className="text-lg font-bold mb-1">Email</h3>
            <p className="text-gray-600 mb-1 text-center">info@abscod.com</p>
          </div>
          <div className="cursor-pointer mb-[15%] hover:!blur-none group-hover:scale-[0.85] hover:!scale-100 duration-500 w-56 h-40 md:mx-2 mx-1 bg-white rounded-lg shadow-md flex flex-col justify-center content-evenly items-center">
            <img src="/Images/phoneContact.png" alt="" className="w-10 mb-1" />
            <h3 className="text-lg font-bold mb-1">Call</h3>
            <p className="text-gray-600 mb-1 text-center">+918630062102</p>
            <p className="text-gray-600 mb-1 text-center">+919999283765</p>
            <p className="text-gray-600 mb-1 text-center">+919560438136</p>
          </div>
          <div className="cursor-pointer mb-[15%] hover:!blur-none group-hover:scale-[0.85] hover:!scale-100 duration-500 w-56 h-40 md:mx-2 mx-1 bg-white rounded-lg shadow-md flex flex-col justify-center content-evenly items-center">
            <img
              src="/Images/addressContact.png"
              alt=""
              className="w-10 mb-1"
            />
            <h3 className="text-lg font-bold mb-1">Address</h3>
            <p className="text-gray-600 mb-1 text-center">
              Grovy Optiva A5 Sector 68 Noida Uttar Pradesh
            </p>
          </div>
        </div>
        <ContactForm />

        <div className="hidden sm:block lg:ml-[3%] md:ml-1 ">
          <ul className="">
            <li className="shadow-md  w-20 hover:scale-[1.55]  duration-500 h-20 rounded-full bg-[#0077B5]  flex justify-center content-center items-center text-white mb-[70px] ">
              <Link
                to="https://www.linkedin.com/company/abscod-informatics/?original_referer="
                className="m-5"
              >
                <ImLinkedin2 className="h-10 w-10" />
              </Link>
            </li>
            <li className="shadow-md  hover:scale-[1.55]  duration-500 w-20 h-20 rounded-full bg-[#3b5998]  flex justify-center items-center text-white mb-[70px]">
              <Link
                to="https://www.facebook.com/abscod.informatics/"
                className="mx-5"
              >
                <ImFacebook className="h-10 w-10" />
              </Link>
            </li>
            <li className="shadow-md  hover:scale-[1.55]  duration-500 w-20 h-20 rounded-full bg-[#c4302b]  flex justify-center items-center text-white mb-[70px]">
              <Link
                to="https://www.youtube.com/@abscodinformatics"
                className="mx-5 "
              >
                <ImYoutube2 className="text-white h-10 w-10" />
              </Link>
            </li>
            <li
              className="shadow-md  hover:scale-[1.55]  duration-500 w-20 h-20 rounded-full bg-[#921cb0]  flex justify-center items-center text-white mb-[70px]"
              style={{
                background:
                  "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
              }}
            >
              <Link
                to="https://www.instagram.com/abscod.informatics/"
                className="mx-5 "
              >
                <FaInstagramSquare className="text-white h-10 w-10" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactLandingPage;
