import React from "react";
import Navbar from "../NavBar/Navbar";
import FormHeroSection from "../../components/FormHeroSection";
import WebsiteDevStackUse from "./WebsiteDevStackUse";
import WebFeatures from "./WebFeatures";
import WebOurPreWork from "./WebOurPreWork";
import Client from "../client/Client";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import WebsiteDevelopmentHome from "./WebsiteDevelopmentHome";
import WebsiteStack from "./WebsiteStack";
import WebsiteFeature from "./WebsiteFeature";
import WebsiteClientFeedback from "./WebsiteClientFeedback";
import WebsiteExpeties from "./WebsiteExpeties";

const WebsiteDevelopment = () => {
  return (
    <>
      <Navbar />
      <WebsiteDevelopmentHome />
      <WebsiteStack />
      <WebsiteFeature />
      <FormHeroSection/>
      <WebsiteClientFeedback />
      <WebsiteExpeties />
      <WebsiteDevStackUse />
      <WebFeatures />
      <WebOurPreWork />
      <Client />
      <FAQ
        title="What is website development?"
        title1="What website development services do you offer?"
        title2="How long does it take to develop a website?"
        title3="How much does website development cost?"
        title4="Can you help with website hosting and maintenance?"
        paragraph=" Website development is the process of designing, creating, and maintaining websites. It includes tasks such as web design, web content development, client-side and server-side scripting, network security configuration, and e-commerce development."
        paragraph1="We offer a range of website development services, including web design, web development, e-commerce development, custom web solutions, and website maintenance services."
        paragraph2="The time it takes to develop a website depends on the complexity of the project, the number of pages, the type of features required, and the availability of content. "
        paragraph3="The cost of website development varies depending on the scope of the project, the complexity of the website, and the features required.We provide detailed quotes to help you understand the costs involved."
        paragraph4="Yes, we offer website hosting and maintenance services. This includes regular backups, security updates, and technical support to ensure that your website runs smoothly."
      />
      <Footer />
    </>
  );
};

export default WebsiteDevelopment;
