import React from "react";
import Heading from "../../components/Heading";

const SoftwarePlan = () => {
  return (
    <div className="p-[6%]">
      <div className="py-[3%] text-center">
        <Heading content="Choose from Our Various Hiring Models" />
      </div>
      <div>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
          <div className="w-full  flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img src="/Images/price2b.jpg" alt="/" className="object-cover" />
            <h2 className="text-2xl font-bold text-center py-8">
              Dedicated Team
            </h2><p className="text-center ">
              {" "}
              If want your project to have an dedicated attention from the top
              developers then our dedicated software app development teams works
              relentlessly for your business
            </p>
            <div className="text-center text-[17px] font-[400]">
              <p className="py-4 border-b border-t mx-8 mt-8">
                No hidden charges
              </p>
              <p className="py-4 border-b mx-8 ">
                Developers available for part & full time
              </p>
              <p className="py-4 border-b mx-8">
                Monthly Billing for your project
              </p>
              <p className="py-4  mx-8">Pay only for Measurable Work</p>
            </div>
          </div>
          <div
            style={{ boxShadow: "4px 4px 4px rgba(0, 0, 0, 0)" }}
            className="w-full  bg-white flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300"
          >
            <img src="/Images/currency.png" alt="/" className="object-cover" />
            <h2 className="text-2xl font-bold text-center py-8">
              Fixed Price Model
            </h2> <p className="text-center ">
              If you are a large enterprise with a large project in your hand
              then our fixed time price payment contract will cater all your
              needs.
            </p>
            <div className="text-center text-[17px] font-[400]">
              <p className="py-4 border-b border-t mx-8 mt-8">
                No hidden charges
              </p>
              <p className="py-4 border-b mx-8 ">Delivery on fixed deadlines</p>
              <p className="py-4 border-b mx-8">In Budget Services</p>
              <p className="py-4  mx-8">No setup fees</p>
            </div>
          </div>
          <div className="w-full  flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img src="/Images/price2b.jpg" alt="/" className="object-cover" />
            <h2 className="text-2xl font-bold text-center py-8">Hourly</h2>

            <p className="text-center ">
              If your business idea is under undefined projects and needs
              ongoing work, then we suggest our hourly hiring model where you
              pay as you go hour wise rolling contract.
            </p>
            <div className="text-center text-[17px] font-[400]">
              <p className="py-4 border-b border-t mx-8 mt-8">
                No hidden charges
              </p>
              <p className="py-4 border-b mx-8 ">
                Requirement Based Working Hours
              </p>
              <p className="py-4 border-b mx-8">
                Monthly Billing for total number of hours
              </p>
              <p className="py-4  mx-8">Pay only for Measurable Work</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwarePlan;
