import React from "react";
const MobileAppDevelopmentHome = () => {
  return (
    <div
      style={{
        backgroundImage: `url("/Images/mobilehome.jpg")`,
        backgroundSize: "cover",
      }}
      className="lg:h-screen md:h-screen  md:px-[10px] px-[10px] pb-[3%] flex flex-wrap md:flex-row md:flex-nowrap md:justify-evenly "
    >
      <div className="md:pt-[10%] pt-[30%] flex flex-col justify-center items-center mx-auto  md:content-center  md:mx-[6%] md:justify-center  md:w-3/4 ">
        <p className="  tracking-[0.02em] text-[#f9f6f6] uppercase font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
          Mobile App Development
        </p>
        <p className="text-white mt-[3%] text-justify">
          We are a leading mobile app development service provider dedicated to
          delivering innovative and customized solutions to businesses of all
          sizes. Our team of expert developers and designers specialize in
          creating high-quality, user-friendly, and scalable mobile applications
          for iOS and Android platforms. From concept to launch, we provide
          end-to-end services that ensure your app meets your business
          objectives and exceeds your expectations. Let us help you bring your
          app idea to life and take your business to the next level.
        </p>
      </div>
      <div className="hidden sm:block mt-[10%] mr-[5%] w-[60%]"></div>
    </div>
  );
};

export default MobileAppDevelopmentHome;
