import React from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";

const WebsiteDevStackUse = () => {
  return (
    <div className="py-[3%] mx-[2%] md:mx-[5%]">
      <div className=" md:text-center text-justify mb-[1%] ">
        <span className="text-center">
          <Heading content="Custom Web Development Company" />
        </span>
        <div className="mt-[2%]">
          <SubHeading SubHeading="Our team of skilled developers and designers work closely with you to understand your unique needs and preferences,  your specific requirements." />
        </div>
      </div>

      <div className="flex  justify-evenly mt-2 flex-col md:flex-row py-[2%] md:py-[5%]">
        <div className="md:w-[40%] mb-[5%] md:my-auto mx-4 shadow-md shadow-gray-50">
          <span className="text-center">
            <Heading content="Front end Developer" />
          </span>
          <p className="text-justify mt-[1%]">
            At our company, we offer top-notch front-end website development
            services to help you create an engaging and responsive website that
            delivers a great user experience. Our team of skilled front-end
            developers has expertise in a wide range of technologies and
            frameworks, and we stay up-to-date with the latest trends and best
            practices to deliver exceptional results. We take a collaborative
            approach to front-end development, working closely with you to
            understand your unique needs and preferences and ensure that we
            create a website that aligns with your vision.Contact us today to
            learn more about our front-end website development services and how
            we can help you create a website that stands out from the
            competition
          </p>
        </div>
        <div className="flex flex-wrap  md:w-[35%] shadow-md shadow-gray-100 ">
          <div className="grid grid-cols-3 mx-auto ">
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%]">
              <img
                src="/Images/html.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">HTML</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img src="/Images/css.png" alt="" className="h-10 object-cover" />
              <h1 className="font-bold text-xl">CSS</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img src="/Images/js.png" alt="" className="h-10 object-cover" />
              <h1 className="font-bold text-xl">JS</h1>
            </div>
          </div>
          <div className="grid grid-cols-3 mx-auto">
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/react.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">REACT.JS</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img src="/Images/vue.png" alt="" className="h-10 object-cover" />
              <h1 className="font-bold text-xl">VUE</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/next.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">NEXT.JS</h1>
            </div>
          </div>
          <div className="grid grid-cols-2 mx-auto">
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/angular.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">ANGULAR.JS</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img src="/Images/jq.png" alt="" className="h-10 object-cover" />
              <h1 className="font-bold text-xl">jquery</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-evenly flex-col mt-2 md:flex-row py-[2%] md:py-[5%]">
        <div className="flex flex-wrap md:w-[35%] shadow-md shadow-gray-100 ">
          <div className="grid grid-cols-3 mx-auto">
            <div className="md:m-1 w-32  flex flex-col items-center text-center px-[2%]">
              <img
                src="/Images/Flask.jpg"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Flask</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/Meteor.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Meteor</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/Express.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Express.js</h1>
            </div>
          </div>
          <div className="grid grid-cols-3 mx-auto">
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/nest.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Nest.js</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/node.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Node.js</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/Django.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Django</h1>
            </div>
          </div>
          <div className="grid grid-cols-2 mx-auto">
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/Laravel.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Laravel</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/java.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">java</h1>
            </div>
          </div>
        </div>
        <div className="md:w-[40%] md:my-auto mx-4 mt-[3%] md:mt-0 shadow-md shadow-gray-50 ">
          <span className="text-center">
            <Heading content="Back end Developer" />
          </span>
          <p className="text-justify mt-[1%]">
            {" "}
            We offer professional back-end website development services that are
            designed to help you build a robust, scalable website that can
            handle your business needs. Our team of experienced back-end
            developers has expertise in a wide range of programming languages
            and frameworks, and we stay up-to-date with the latest trends and
            best practices to ensure that we deliver exceptional results.Contact
            us today to learn more about our back-end website development
            services and how we can help you build a website that meets your
            unique business needs.
          </p>
        </div>
      </div>
      <div className="flex justify-evenly mt-2 flex-col md:flex-row  md:py-[5%]">
        <div className="md:w-[40%] md:my-auto mx-4 shadow-md shadow-gray-50 ">
          <span className="text-center">
            <Heading content="Database" />
          </span>
          <p className="text-justify mt-[1%]">
            {" "}
            Our team specializes in creating database-driven websites that are
            tailored to your unique needs and requirements. We understand that a
            well-designed database is critical to the success of your website,
            which is why we have a team of experienced database developers who
            are skilled in building efficient and scalable databases. Whether
            you're looking to build a simple website with a few basic
            functionalities or a complex web application that requires advanced
            database management, we have the expertise and experience to deliver
            exceptional results. Contact us today to learn more about our
            database-driven website development services and how we can help you
            build a website that effectively stores, manages, and retrieves
            data.
          </p>
        </div>
        <div className="flex flex-wrap md:w-[35%] shadow-md shadow-gray-100 items-center py-[2%] md:py-0 ">
          <div className="grid grid-cols-3  mx-auto">
            <div className="m-1 w-32 flex flex-col items-center text-center px-[2%]">
              <img
                src="/Images/Oracle.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Oracle</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/MySQL.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">MySQL</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/mssql.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">MSSQL</h1>
            </div>
          </div>
          <div className="grid grid-cols-3 mx-auto">
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img src="/Images/postgre.png" alt="" className="h-10 " />
              <h1 className="font-bold text-xl">PostgreSQL</h1>
            </div>
            <div className="md:m-1 w-32 flex flex-col items-center  text-center px-[2%] ">
              <img
                src="/Images/MongoDB.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">MongoDB</h1>
            </div>
            <div className="md:m-1 w-32  flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/IBMDB2.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">IBMDB2</h1>
            </div>
          </div>
          <div className="grid grid-cols-2 mx-auto">
            <div className="md:m-1 w-32 flex flex-col items-center text-center px-[2%] ">
              <img
                src="/Images/Redis.png"
                alt=""
                className="h-10 object-cover"
              />
              <h1 className="font-bold text-xl">Redis</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteDevStackUse;
