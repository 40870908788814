import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import ContactUs from "../pages/ContactUs/ContactUs";
import AboutUs from "../pages/AboutUs/AboutUs";
import SoftwareDevelopment from "../pages/SoftWareDevelopMent/SoftwareDevelopment";
import WebsiteDevelopment from "../pages/WebsiteDevelopment/WebsiteDevelopment";
import MobileAppDevelopment from "../pages/MobileAppDevelopment/MobileAppDevelopment";
import SEOPAGE from "../pages/SEOPage/SEOPAGE";
import DigitalMArketing from "../pages/DigitalMarketing/DigitalMArketing";
import SocialMediaMarkecting from "../pages/SocialMediaMarkecting.jsx/SocialMediaMarkecting";
import ScrollToTop from "./ScrollToTop";
import WebDesign from "../pages/WebDesign/WebDesign";
import ContentMarketing from "../pages/ContentMarketing/ContentMarketing";
import LogoDesign from "../pages/LogoDesign/LogoDesign";
import Privacy from "../PrivacyPolicyTermsCondition/Privacy";
import FAQs from "../pages/FAQs/FAQs";
import UXUIDesign from "../pages/UXUIDesign/UxUiDesign";
import TermsAndConditions from "../TermsAndConditions";

const PagesRouting = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<Home />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/search-engine-optimization" element={<SEOPAGE />} />
        <Route exact path="/faq" element={<FAQs />} />
        <Route exact path="/logo-design" element={<LogoDesign />} />
        
        <Route exact path="/web-design-development" element={<WebDesign />} />
        <Route exact path="/content-marketing" element={<ContentMarketing />} />
        <Route exact path="/uxuidesign" element={<UXUIDesign />} />
        <Route
          exact
          path="/social-media-marketing"
          element={<SocialMediaMarkecting />}
        />
        <Route
          exact
          path="/software-development"
          element={<SoftwareDevelopment />}
        />
        <Route
          exact
          path="/website-development"
          element={<WebsiteDevelopment />}
        />
        <Route
          exact
          path="/mobile-application-development"
          element={<MobileAppDevelopment />}
        />
        <Route exact path="/digital-marketing" element={<DigitalMArketing />} />
        <Route exact path="/Privacy" element={<Privacy />} />
        <Route
          exact
          path="/TermsAndConditions"
          element={<TermsAndConditions />}
        />
      </Routes>
    </>
  );
};

export default PagesRouting;
