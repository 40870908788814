import React,{useState} from "react";
import Button from "../NavBar/Button";
import FreeQuote from "../FreeQuotes/FreeQuote";

const SEOWeWorkProvide = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  };
  const images = [
    {
      src: "/Images/Finance.png",
      alt: "Image 1",

      definition: "Finance",
    },
    {
      src: "/Images/Production.png",
      alt: "Image 2",
      definition: "Production",
    },
    // {
    //   src: "/Images/Technology.png",
    //   alt: "Image 1",

    //   definition: "Technology",
    // },
    // {
    //   src: "/Images/Financial.png",
    //   alt: "Image 2",
    //   definition: "Financial Services",
    // },
    // {
    //   src: "/Images/Investment.png",
    //   alt: "Image 1",

    //   definition: "Investment",
    // },
    // {
    //   src: "/Images/Trade.png",
    //   alt: "Image 2",
    //   definition: "Trade",
    // },
    // {
    //   src: "/Images/software.png",
    //   alt: "Image 1",

    //   definition: "Software",
    // },
    // {
    //   src: "/Images/agriculture.png",
    //   alt: "Image 2",
    //   definition: "Agriculture",
    // },
    {
      src: "/Images/information-technology.png",
      alt: "Image 1",

      definition: "Information Technology",
    },
    {
      src: "/Images/deal.png",
      alt: "Image 2",
      definition: "Real Estate",
    },
    // {
    //   src: "/Images/food.png",
    //   alt: "Image 1",

    //   definition: "Food Industry",
    // },
    {
      src: "/Images/StockMarket.png",
      alt: "Image 2",
      definition: "Stock Market",
    },
    {
      src: "/Images/Retail.png",
      alt: "Image 1",

      definition: "Retail",
    },
    // {
    //   src: "/Images/Mining.png",
    //   alt: "Image 2",
    //   definition: "Mining",
    // },
    // {
    //   src: "/Images/Construction.png",
    //   alt: "Image 1",

    //   definition: "Construction Engineering",
    // },
    // {
    //   src: "/Images/Engineering.png",
    //   alt: "Image 2",
    //   definition: "Engineering",
    // },
    // {
    //   src: "/Images/HealthCare.png",
    //   alt: "Image 1",

    //   definition: "Health Care",
    // },
    // {
    //   src: "/Images/VentureCapital.png",
    //   alt: "Image 2",
    //   definition: "Venture Capital",
    // },

    // {
    //   src: "/Images/BusinessManagement.png",
    //   alt: "Image 1",

    //   definition: "Business Management",
    // },
    {
      src: "/Images/Education.png",
      alt: "Image 2",
      definition: "Education",
    },
    {
      src: "/Images/TelecommuniCations.png",
      alt: "Image 1",

      definition: "Telecommuni Cations",
    },
    {
      src: "/Images/E-commerce.png",
      alt: "Image 2",
      definition: "E-commerce",
    },
    // {
    //   src: "/Images/ScienceTechnology.png",
    //   alt: "Image 1",

    //   definition: "Science Technology",
    // },
    {
      src: "/Images/Hospitality.png",
      alt: "Image 2",
      definition: "Hospitality Industry",
    },
    {
      src: "/Images/Media.png",
      alt: "Image 1",

      definition: "Media",
    },
    // {
    //   src: "/Images/MedicalDevice.png",
    //   alt: "Image 2",
    //   definition: "Medical Device",
    // },
    // {
    //   src: "/Images/Insurance.png",
    //   alt: "Image 2",
    //   definition: "Insurance",
    // },
    // {
    //   src: "/Images/Robotics.png",
    //   alt: "Image 1",

    //   definition: "Robotics",
    // },
    // {
    //   src: "/Images/Bank.png",
    //   alt: "Image 2",
    //   definition: "Bank",
    // },
    {
      src: "/Images/Transport.png",
      alt: "Image 2",
      definition: "Transport",
    },
  ];
  return (
    <>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
      <div className="px-[1%] md:px-[6%] py-[5%]">
        <div className="px-[3%]">
          <div className="text-[2.5rem] mb-2  lg:text-center font-[500] leading-[1.2]">
            <b>Various Industries that We Work For</b>
          </div>
          <h1 className="lg:text-center font-[400]  mb-[1rem] leading-[1.2] text-[20px] ">
            Any business that exists below the sky can be marketed over digital
            marketing medium. Be it a D2C Brand, B2B Brand. B2C brand or even a
            C2C brand, we are capable of generating business for each one of
            these.
          </h1>
        </div>
        <div className="mt-[5%]">
          <ul className="grid px-[3%] md:px-[3%] lg:grid-cols-4 xl:grid-cols-6  md:grid-cols-3 grid-cols-2 gap-5 md:gap-5 leading-[1.2]">
            {images.map((image) => (
              <li
                style={{ boxShadow: "4px 5px 15px 2px #dedede" }}
                key={image.id}
                className=" flex flex-col items-center  rounded-[4px] w-40 md:w-48 lg:px-4  pt-[3%] "
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-[70px] object-cover "
                />

                <p className="text-[22px]  font-sans leading-[22px] text-center pb-4 mt-4 font-[600]">
                  {image.definition}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:w-[20%] w-[50%] mt-[5%] mx-auto">
          <Button
            onClick={popHandler}
            content="Request A Quote"
            color="black"
            background="linear-gradient(to right, #FFDB16, #FFDB16"
          />
        </div>
      </div>
    </>
  );
};

export default SEOWeWorkProvide;
