import React from "react";

const SocialServicesChecklist = () => {
  return (
    <div className="md:p-[6%] flex  md:flex-row flex-col justify-between">
      <div className="lg:w-2/5">
        <img src="/Images/smochecklist.png" alt="" className="object-cover" />
      </div>
      <div className="md:w-1/2 md:mt-[3%] mt-[3%] px-[3%] md:px-0">
        <h1 className="text-[black] mb-[30px]  font-[500] md:font-[900] text-[28px] md:text-[42px] leading-[38px] md:leading-[48px]">
          Service Checklist With Abscod Online Marketing
        </h1>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">
            Social Media Page Management and Optimization
          </span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">
            Content Creation and Management
          </span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">
            Timely Analytics and Data Reporting
          </span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">Social Media Advertising</span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">Increasing Brand Awareness</span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">
            Brand's Online Reputation Management
          </span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">Influencer Outreach</span>
        </div>
        <div className="flex items-center mb-[20PX]">
          <img
            src="/Images/logoclick.png"
            alt=""
            className="w-6 object-cover"
          />
          <span className="ml-4  text-[18px]">Youtube Channel Management</span>
        </div>
      </div>
    </div>
  );
};

export default SocialServicesChecklist;
