import React from "react";
import Navbar from "../NavBar/Navbar";
import Services from "./Services";
import FAQ from "../../components/FAQ";
import Client from "../client/Client";
import HeroSection from "../../components/HeroSection";
import FormHeroSection from "../../components/FormHeroSection";
import Footer from "../Footer/Footer";

const ContentMarketing = () => {
  return (
    <>
      <Navbar />
      <HeroSection
        background="linear-gradient(to right, #194f79, #194f79)"
        heading="Creative Content Solutions"
        img="/Images/webDesignHome.png"
        subheading="Transform Your Online Presence"
        deatailhedaing="As a top-tier Content Marketing Company based in India, Abscod boasts a team of seasoned professionals with extensive experience in the field. Our specialized team is equipped to deliver customized web solutions tailored to your specific business needs, establishing your online presence and driving growth."
      />
      <Services />
      <FormHeroSection />
      <Client /><FAQ
        title="What is content marketing?"
        title1="Why is content marketing important for my business?"
        title2="What are the benefits of content marketing?"
        title3="What types of content should I be creating?"
        title4="How do I measure the success of my content marketing efforts?"
        paragraph="It involves creating content such as blog posts, videos, social media posts, and ebooks that educate, entertain, or inform your audience."
        paragraph1="Content marketing is important for your business because it can help you attract and retain customers, establish your brand as an industry authority, and generate leads and conversions. It also provides a cost-effective way to reach a large audience and build trust and credibility with your customers."
        paragraph2="Content marketing offers several benefits, including increased website traffic, improved search engine rankings, higher engagement and social media followers, and increased conversions and revenue. It also provides a way to build a community around your brand and establish long-term relationships with your customers."
        paragraph3="The types of content you should be creating depend on your target audience and business goals. Generally, you should aim to create content that is informative, entertaining, or educational and that aligns with your brand and messaging. "
        paragraph4="To measure the success of your content marketing efforts, you should track key metrics such as website traffic, engagement, leads, and conversions. You can use tools such as Google Analytics and social media analytics to gather data and analyze the performance of your content. You should also monitor customer feedback and engagement to understand how your content is resonating with your audience."
      />
      <Footer />
    </>
  );
};

export default ContentMarketing;
