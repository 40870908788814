import React from "react";

const SoftwareDevCostCart = () => {
  const images = [
    {
      src: "/Images/logoclick.png",
      alt: "Image 1",
      heading: "Business Analysis",
      definition:
        "We proceed with the deep understanding of your business, potential audience and the industry you serve. So, we can turn your idea into an application that delivers the results in the most effective ways.",
    },
    {
      src: "/Images/logoclick.png",
      alt: "Image 2",
      heading: "Requirements and Change Management",
      definition:
        "Abscod Informatics honors your requirements and ideas when it comes to custom software development. Our seamless solutions by our experienced app developers in India adds value to your business as they always come with innovative and integrated solutions by keeping a close eye on your ideas.",
    },
    {
      src: "/Images/Logoclick.png",
      alt: "Image 3",
      heading: "Risk Management",
      definition:
        "Leverage the benefit of our low risk, and predictable process to expedite your business far beyond your competition. Our detailed discussion program for the projects allows you to identify early risks and propose optimal risk management strategies to guarantee the success of the project.",
    },
    {
      src: "/Images/Logoclick.png",
      alt: "Image 4",
      heading: "Establishing a Communication Channel",
      definition:
        "We understand the importance of transparent communication channels between us and our esteemed clients. We believe that your time is very precious as we set up voice and video conferencing, on-site meetings, and your preferred setup to make the communication between us.",
    },
    {
      src: "/Images/Logoclick.png",
      alt: "Image 5",
      heading: "Quality Solutions",
      definition:
        "Software developers at Abscod Informatics offer the modern solution to your business so you get in trend features along with quality solutions. With more than a team of 200+ developers, our experience delivers the most advanced and excellent solution to your needs.",
    },
    {
      src: "/Images/Logoclick.png",
      alt: "Image 6",
      heading: "Addition of Security",
      definition:
        "Developing a secure software is one of our key verticals as we completely understand the safety of your and your user’s data. We are dedicated to creating safe and secure software solutions which support your business.",
    },
  ];
  return (
    <div className="bg-[#ffffff]">
     
        <div className="py-[5%] text-[#000000]  font-[600] md:font-[800] text-[28px] md:text-[42px] leading-[28px] md:leading-[48px] mb-[5%] md:mb-[3%]">
          Our Cost-Effective Approach to Custom Software Development
        </div>
       
      <div>
        <div>
          <ul className="grid px-[1%] lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 ">
            {images.map((image) => (
              <li
                style={{ boxShadow: "4px 5px 15px 2px #dedede" }}
                key={image.id}
                className="   rounded-[4px] p-[5%] "
              >
                <img
                  src="/Images/logoclick.png"
                  alt={image.alt}
                  className="w-[40px] object-cover mb-[25px] "
                />
                <h1 className="font-[600] text-[18px] leading-[1.2] mb-[16px] text-start">
                  {image.heading}
                </h1>
                <p className=" pb-4 mt-4 text-justify leading-[30px] text-[18px]">
                  {image.definition}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevCostCart;
