import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { RotatingLines } from "react-loader-spinner";

function Form({ setShowPopup, showPopup, popHandler }) {
  const captcha = useRef(null);
  const [captchaResponse, setCaptchaResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    user_email: "",
    user_project: "",
  });
  const form = useRef();
  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaResponse === "") {
      setLoading(false);
      toast.error("Please complete the captcha!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setLoading(true);

      try {
        const response = await fetch(
          "https://abscodmailserver-com.onrender.com/mail",
          {
            method: "POST",
            body: JSON.stringify({
              reciepents: [formData.user_email],
              subject: formData.user_name,
              msg: formData.user_project,
              phone: formData.user_phone,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(data);

        toast.success("Successfully submitted your request!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setCaptchaResponse(captcha.current.reset());
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.error("Error submitting your request. Please try again later.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setFormData({
        user_name: "",
        user_phone: "",
        user_email: "",
        user_project: "",
      });
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaResponse(value);
  };

  return (
    <div className="py-[3%] px-[2%] bg-white w-[90vh] ">
      <h2 className="text-2xl text-black font-bold mb-4 text-center">
        Request a Quote
      </h2>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <form ref={form} onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-col">
          
          <label htmlFor="name" className=" text-black font-[500] mb-2">
            Name <sup>*</sup>
          </label>
          <input
            type="text"
            name="user_name"
            id="name"
            value={formData.user_name}
            onChange={handleForm}
            className=" border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="mb-4 flex flex-col">
          <label htmlFor="phone" className=" text-black font-[500] mb-2">
            Phone Number<sup>*</sup>
          </label>
          <input
            type="tel"
            name="user_phone"
            id="phone"
            value={formData.user_phone}
            onChange={handleForm}
            className=" border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your phone number"
            pattern="[0-9]{10}"
            required
            title="Please enter a valid 10-digit phone number (no spaces or dashes)"
          />
        </div>
        <div className="mb-4 flex flex-col">
          <label htmlFor="email" className=" text-black font-[500] mb-2">
            Email<sup>*</sup>
          </label>
          <input
            type="email"
            name="user_email"
            id="email"
            value={formData.user_email}
            onChange={handleForm}
            className=" border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your email address"
            required
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          />
        </div>
        <div className="mb-4 flex flex-col">
          <label
            htmlFor="project-details"
            className=" text-black font-[500] mb-2"
          >
            Message<sup>*</sup>
          </label>
          <textarea
            name="user_project"
            id="project-details"
            value={formData.user_project}
            onChange={handleForm}
            className=" border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows={2}
            placeholder="Enter details about your project"
            required
          />
        </div>
        <div className="  flex md:flex-row flex-col justify-between items-center content-center">
          <ReCAPTCHA
            sitekey="6LdVeLclAAAAALk1Y-TlSuDmLj3gUZYJNeMBVNbv"
            // sitekey="6LcFx7glAAAAAM4S0LKLyUBzZlofxF429g9yrzZ-"
            onChange={handleCaptchaChange}
            ref={captcha}
          />
          <button
            className="bg-black my-[3%] md:my-0 w-[150px] h-14 uppercase px-4  text-white font-[700] rounded-[28px] transition duration-500 ease-in-out hover:bg-[#FFDB16] hover:text-black"
            type="submit"
            disabled={loading}
          >
            SUBMIT
          </button>
          {loading ? (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="30"
              visible={true}
            />
          ) : (
            ""
          )}
        </div>
      </form>
      {/* <div className="flex justify-between content-evenly mt-[10px]">
        <div className="flex   ">
          <div className=" bg-gray-500  rounded-full p-[6px] my-auto mr-[8px]">
            <FcLock />
          </div>
          <h1 className="text-left text-black ">
            We guarantee 100% Security of your information All information you
            provide will be kept strictly confidential .
          </h1>
        </div>
        <div className="flex flex-col items-end my-auto  ml-[5px]">
          <img src="/Images/formlogo.png" alt="" width="40px" />
          <h1>website SSL sequre</h1>
        </div>
      </div> */}
    </div>
  );
}

export default Form;
