import React from "react";
import ServiceCart from "./carts/ServiceCart";
import Heading from "./Heading";
import SubHeading from "./SubHeading";

const Services = () => {
  return (
    <>
      <div className=" py-[7%]  flex flex-col justify-center items-center  w-[100%] bg-[#faf7f7]">
        <div className="text-center mx-4 md:mx-[6%]">
          <Heading content={" Services We Offer"} />
          <div className="bg-gradient-to-r from-yellow-500 via-purple-500 to-black w-40 mx-auto h-2 rounded-full my-[2%] "></div>
          <span className="md:text-center  text-justify">
            <SubHeading
              SubHeading={
                "Our services are designed to be customizable and scalable, so we can adapt to our clients' unique requirements and deliver value that aligns with their business objectives."
              }
            />
          </span>
        </div>
        <div className="flex flex-row flex-wrap  justify-center mx-[2%] pt-[2%] ">
          <ServiceCart
            background="linear-gradient(270deg, #406CD7 0%, #3BC1E4 100%)"
            content={
              "Social media platforms provide businesses with an opportunity to showcase their brand and products/services to a large audience. By consistently posting high-quality content."
            }
            img="/Images/SocialMedia1.png"
            heading={"Social Media Marketing"}
            route="/social-media-marketing"
          />
          <ServiceCart
            background="linear-gradient(270deg, #406CD7 0%, #3BC1E4 100%)"
            content={
              "A well-designed website that is easy to navigate can provide a positive user experience for visitors. This can lead to increased engagement, longer session times, and higher conversion ."
            }
            img="/Images/websitedev.png"
            heading={"Website Development"}
            route="/website-development"
          />
          <ServiceCart
            content={
              "We specialize in providing top-notch mobile app development services to cater to the unique needs of our clients. Our team of skilled professionals works diligently to create customized solutions ."
            }
            img="/Images/mobileapp.png"
            heading={"Mobile App"}
            route="/mobile-application-development"
          />
          <ServiceCart
            content={
              "By using Advanced Analytics, businesses can gain a competitive advantage by making data-driven decisions that allow them to stay ahead of the competition."
            }
            img="/Images/DigitalMarketing.png"
            heading={"Digital Marketing"}
            route="/digital-marketing"
          />
          <ServiceCart
            content={
              "Compared to other forms of digital marketing, SEO Optimization can be a cost-effective way to increase visibility and drive traffic to a website."
            }
            img="/Images/seo.png"
            heading={"Search Engine Optimization"}
            route="/search-engine-optimization"
          />
          <ServiceCart
            content={
              "Our team of skilled software engineers, designers, and developers work collaboratively to create innovative, customized solutions."
            }
            img="/Images/software.png"
            heading={"Software Development"}
            route="/software-development"
          />

          <ServiceCart
            content={
              "A well-designed website not only looks great, but it also helps businesses establish their online presence, attract and engage visitors, and convert them into loyal customers. "
            }
            img="/Images/WebDesign.png"
            heading={"Web Design"}
            route="/web-design-development"
          />
          <ServiceCart
            content={
              " The goal of content marketing is to establish a connection with customers by providing them with useful and engaging content that addresses their pain points, educates them about relevant topics."
            }
            img="/Images/ContentMarketing.png"
            heading={"Content Marketing"}
            route="/content-marketing"
          />
          <ServiceCart
            content={
              " A well-designed logo can serve as the cornerstone of a brand's visual identity and help it stand out in a crowded marketplace. Professional logo design services typically involve a collaborative process "
            }
            img="/Images/LogoDesign.png"
            heading={"Logo Design"}
            route="/logo-design"
          />
        </div>
      </div>
    </>
  );
};

export default Services;
