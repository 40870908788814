import React from "react";
import Heading from "../../components/Heading";

const MobileAppDevelopmentServices = () => {
  return (
    <div className="mx-[5%] pt-[5%] min-h-screen ">
      <span className="text-center">
        <Heading content=" App Development " />
        <p className="my-[2%]">
          User-Friendly, Affordable, Engaging, and highly trusted custom Android
          App to bring you above your competitors.
        </p>
      </span>
      <div className="flex flex-wrap justify-center">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 p-2">
          <div
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
            className="group bg-white rounded-lg  p-2 flex  items-center h-16 hover:bg-[#e87a3b] transition-all duration-300"
          >
            <div className="h-10 w-10 bg-black group-hover:bg-white rounded-full flex justify-center items-center transition-all duration-300">
              <div className="group-hover:bg-white transition-all duration-300">
                <img
                  src="/Images/service-icon1.png"
                  alt=""
                  className="w-6 group-hover:bg-white object-cover transition-all duration-300"
                />
              </div>
            </div>
            <p className="ml-4 text-[18px] font-[600] group-hover:text-white text-black transition-all duration-300">
              Android App
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 p-2">
          <div
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
            className="group bg-white rounded-lg  p-2 flex  items-center h-16 hover:bg-[#e87a3b] transition-all duration-300"
          >
            <div className="h-10 w-10 bg-black group-hover:bg-white rounded-full flex justify-center items-center transition-all duration-300">
              <div className="group-hover:bg-white transition-all duration-300">
                <img
                  src="/Images/service-icon2.png"
                  alt=""
                  className="w-6 object-cover transition-all duration-300"
                />
              </div>
            </div>
            <p className="ml-4 text-[18px] font-[600] group-hover:text-white text-black transition-all duration-300">
              IOS App
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 p-2">
          <div
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
            className="group bg-white rounded-lg  p-2 flex  items-center h-16 hover:bg-[#e87a3b] transition-all duration-300"
          >
            <div className="h-10 w-10 bg-black group-hover:bg-white rounded-full flex justify-center items-center transition-all duration-300">
              <div className="group-hover:bg-white transition-all duration-300">
                <img
                  src="/Images/service-icon3.png"
                  alt=""
                  className="w-6 object-cover transition-all duration-300"
                />
              </div>
            </div>
            <p className="ml-2 text-[18px] font-[600] group-hover:text-white text-black transition-all duration-300">
              React Native App
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 p-2">
          <div
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
            className="group bg-white rounded-lg  p-2 flex  items-center h-16 hover:bg-[#e87a3b] transition-all duration-300"
          >
            <div className="h-10 w-10 bg-black group-hover:bg-white rounded-full flex justify-center items-center transition-all duration-300">
              <div className="group-hover:bg-white transition-all duration-300">
                <img
                  src="/Images/service-icon4.png"
                  alt=""
                  className="w-6 object-cover transition-all duration-300"
                />
              </div>
            </div>
            <p className="ml-4 text-[18px] font-[600] group-hover:text-white text-black transition-all duration-300">
              website App
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 p-2">
          <div
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
            className="group bg-white rounded-lg  p-2 flex  items-center h-16 hover:bg-[#e87a3b] transition-all duration-300"
          >
            <div className="h-10 w-10 bg-black group-hover:bg-white rounded-full flex justify-center items-center transition-all duration-300">
              <div className="group-hover:bg-white transition-all duration-300">
                <img
                  src="/Images/service-icon5.png"
                  alt=""
                  className="w-6 object-cover transition-all duration-300"
                />
              </div>
            </div>
            <p className="ml-4 text-[18px] font-[600] group-hover:text-white text-black transition-all duration-300">
              UX/UI
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 p-2">
          <div
            style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
            className="group bg-white rounded-lg  p-2 flex  items-center h-16 hover:bg-[#e87a3b] transition-all duration-300"
          >
            <div className="h-10 w-10 bg-black group-hover:bg-white rounded-full flex justify-center items-center transition-all duration-300">
              <div className="group-hover:bg-white transition-all duration-300">
                <img
                  src="/Images/service-icon6.png"
                  alt=""
                  className="w-6 object-cover transition-all duration-300"
                />
              </div>
            </div>
            <p className="ml-2 text-[18px] font-[600] group-hover:text-white text-black transition-all duration-300">
              Digital Marketing
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly items-center mt-8">
        <div className=" flex flex-wrap md:flex-nowrap ">
          <div className="shadow-black shadow-xl md:w-[60%] lg:w-[60%] w-full my-[1%] ml-[5%]">
            <div className="text-black bg-white  p-5 flex flex-col justify-evenly justify-items-stretch">
              <p className="pb-4">
                At our company, we take pride in our expertise in mobile app
                development. Our team consists of highly skilled developers and
                designers who are passionate about creating innovative,
                intuitive, and visually stunning mobile applications that
                provide the ultimate user experience.
              </p>
              <p className="pb-4">
                We specialize in developing custom mobile apps for businesses of
                all sizes, from startups to enterprise-level organizations. Our
                comprehensive development process includes thorough research,
                strategy, design, development, testing, and deployment, ensuring
                that your app meets your business objectives and exceeds your
                expectations.
              </p>
              <p className="">
                Our expertise is not limited to only smartphones but we provide
                Android Apps for various Android devices and platforms.
              </p>
            </div>
          </div>
          <div className="ml-[2%] my-[3%] lg:my-0 mr-[7%]">
            <img
              src="/Images/abc.png"
              alt=""
              className="rounded-tr-[50%] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppDevelopmentServices;
