import React, { useState } from "react";

const Rating = () => {
  const [rating] = useState(5);
  const maxRating = 5;

  return (
    <div>
      {[...Array(maxRating)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <span
            key={ratingValue}
            style={{
              cursor: "default",
              color: "#fca102",
              fontSize: "25px",
              lineHeight: "10px",
              letterSpacing: "10px",
            }}
          >
            {ratingValue <= rating ? "★" : "☆"}
          </span>
        );
      })}
    </div>
  );
};

export default Rating;
