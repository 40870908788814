import React from "react";
import Heading from "../../components/Heading";
import FeaturesCart from "../WebsiteDevelopment/FeaturesCart";
const Services = () => {
  return (
    <div className="py-[5%]">
      <div className="text-center leading-[28px] mx-[7%] ">
        <Heading content="Maximize Your Brand's Reach and Engagement with Effective Social Media Marketing Strategies" />
      </div>

      <div className="flex flex-wrap justify-center py-[2%] mx-[5%]">
        <FeaturesCart
          content={
            "Search advertising is the most common form of paid search marketing. Search ads appear to prospects who are already looking for your industry or brand offerings online."
          }
          img="/Images/SearchAds.png"
          heading={"Search Ads"}
        />
        <FeaturesCart
          content={
            "Display advertising is known for its effectiveness in reaching more than 90 percent of online users. Display ads appear on Google’s partner websites, targeting people "
          }
          img="/Images/DisplayAds.png"
          heading={"Display Ads"}
        />
        <FeaturesCart
          content={
            "Social media paid advertising is the fastest-growing segment of pay per click advertising services. Social ads appear on social media platforms websites, targeting people"
          }
          img="/Images/SocialAds1.png"
          heading={"Social Ads"}
        />
        <FeaturesCart
          content={
            "Remarketing is one of the best ways to reach high-converting customers and double your turnovers. Remarketing ads remind people who have already visited your website"
          }
          img="/Images/RemarketingAds1.png"
          heading={"Remarketing Ads"}
        />
        <FeaturesCart
          content={
            "Google Shopping ads are suitable for websites with a large volume of products. These PPC ads appear in a carousel above or beside the main Google search results, allowing"
          }
          img="/Images/GoogleShoppingAds.png"
          heading={"Google Shopping Ads"}
        />
        <FeaturesCart
          content={
            "In-stream ads are also called YouTube ads. These are pay-per-click ads that appear on YouTube search results, YouTube videos and video partners on Display Network."
          }
          img="/Images/In-streamAds.png"
          heading={"In-stream Ads"}
        />
      </div>
    </div>
  );
};

export default Services;
