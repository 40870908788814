import React from "react";
import { Link } from "react-router-dom";
import { ImLinkedin2, ImFacebook } from "react-icons/im";
import { BsYoutube } from "react-icons/bs";
import { FiPhoneCall, FiMail, FiMapPin } from "react-icons/fi";
import { FaInstagramSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="">
      <div className="bg-[#151d29] py-[1%]">
        <div className="flex justify-between content-around  mx-[2%] md:mx-[6%]">
          <div className=" ">
            <img
              src="/Images/abscodwhite.webp"
              alt=""
              height="160px"
              width="150px"
              className="object-cover"
            />
          </div>
          <div className="my-auto ">
            <ul className="flex  ">
              <li className="w-10 hover:scale-[1.55]  duration-500 h-10 rounded-full bg-[#0077B5]  flex justify-center content-center items-center text-white m-2">
                <Link
                  to="https://www.linkedin.com/company/abscod-informatics/?original_referer="
                  className="mx-5"
                >
                  <ImLinkedin2 className="" />
                </Link>
              </li>
              <li className="hover:scale-[1.55]  duration-500 w-10 h-10 rounded-full bg-[#3b5998]  flex justify-center items-center text-white m-2">
                <Link
                  to="https://www.facebook.com/abscod.informatics/"
                  className="mx-5"
                >
                  <ImFacebook className="" />
                </Link>
              </li>
              <li className="hover:scale-[1.55]  duration-500 w-10 h-10 rounded-full bg-[#c4302b]  flex justify-center items-center text-white m-2">
                <Link
                  to="https://www.youtube.com/@abscodinformatics"
                  className="mx-5 "
                >
                  <BsYoutube className="text-white" />
                </Link>
              </li>
              <li
                className="hover:scale-[1.55]  duration-500 w-10 h-10 rounded-full   flex justify-center items-center text-white m-2"
                style={{
                  background:
                    "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
                }}
              >
                <Link
                  to="https://www.instagram.com/abscod.informatics/"
                  className="mx-5 "
                >
                  <FaInstagramSquare className="text-white" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#000000] text-white">
        <footer class="bg-[#000000]">
          <div class="mx-auto w-full max-w-screen-xl">
            <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
              <div>
                <h2 class="mb-6 text-lg  font-bold text-white md:mx-[5%] ">
                  About Company
                </h2>
                <p className=" md:mx-[5%]">
                  At Abscod Informatics, we specialize in a range of digital
                  services to help businesses of all sizes succeed online.
                </p>
              </div>
              <div className="flex flex-col justify-start md:items-center ">
                <h2 class="mb-6 text-lg  font-bold text-white ">Quick Links</h2>
                <ul class="text-white  dark:text-gray-400 font-medium">
                  <li class="mb-4">
                    <Link
                      to="/"
                      className="text-white hover:underline hover:text-[#fca102] font-bold"
                    >
                      Home
                    </Link>
                  </li>
                  <li class="mb-4">
                    <Link
                      to="/contactus"
                      className="text-white hover:underline hover:text-[#fca102] font-bold"
                    >
                      Contact us
                    </Link>
                  </li>
                  <li class="mb-4">
                    <Link
                      to="/aboutus"
                      className="text-white hover:underline hover:text-[#fca102] font-bold"
                    >
                      About
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-6  text-lg  font-bold text-white ">Legal</h2>
                <ul class="text-white dark:text-gray-400 font-medium">
                  <li class="mb-4">
                    <Link
                      to="/Privacy"
                      className="text-white hover:underline hover:text-[#fca102] font-bold"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li class="mb-4">
                    <Link
                      to="/TermsAndConditions"
                      className="text-white hover:underline hover:text-[#fca102] font-bold"
                    >
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="">
                <h2 className="text-lg  font-bold mb-6 text-white">
                  Get In Touch
                </h2>
                <ul className=" list-inside mb-4 ">
                  <li className="my-2">
                    <Link
                      to="tel:+918630062102,+919560438136"
                      className="text-white hover:text-[#fca102] "
                    >
                      <div className="flex flex-col md:flex-row content-center hover:underline">
                        <FiPhoneCall className="mr-2 mt-1 " />
                        <span>+918630062102 , </span>
                        <span>+919560438136</span>
                      </div>{" "}
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      to="mailto:info@abscod.com"
                      className="text-white hover:text-[#fca102]"
                    >
                      {" "}
                      <span className="flex  content-center hover:underline">
                        <FiMail className="mr-2 mt-1" /> info@abscod.com
                      </span>
                    </Link>
                  </li>
                  <li className="flex text-white hover:text-[#fca102] hover:underline">
                    <FiMapPin className="mr-2 mt-1" />
                    <span>
                      <h2>Grovy Optiva, A5 Sector 68, Noida Uttar Pradesh </h2>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="px-10 py-6 bg-[#151d29] text-white   ">
              Copyright 2023 Abscod Informatics | All Rights Reserved
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
