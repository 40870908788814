import React from "react";
import Heading from "../../components/Heading";
// import DetailCart from "../../components/carts/DetailCart";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Autoplay } from "swiper";

const Client = () => {
  return (
    <h1 className="text-center  py-[8%] md:py-[5%] bg-[#fdf5f5]">
      <h1 className="mx-2 md:mx-10 lg:mx-[15%]">
        <Heading
          content={
            "Since 2020, we have earned the trust of leading startups and enterprises"
          }
        />
      </h1>
      <div className="bg-gradient-to-r from-yellow-500 via-purple-500 to-black w-40 mx-auto h-2 rounded-full mt-[2%] "></div>

      <div className="mt-[5%] mx-[6%] ">
        <Swiper
          loop={true}
          autoplay={{
            delay: 2000,
          }}
          breakpoints={{
            640: { slidesPerView: 1 },
            778: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
            1224: { slidesPerView: 4 },
            1324: { slidesPerView: 4 },
            1524: { slidesPerView: 4 },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img src="/Images/cevious.png" alt="" className="object-cover" />
              {/* cvs11.png */}
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="  mx-[2%]">
              <img
                src="/Images/lordkrishna.png"
                alt=""
                className="object-cover"
              />
              {/* lord11.png */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img src="/Images/glaa.png" alt="" className="object-cover" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img src="/Images/swasth.png" alt="" className="object-cover" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img src="/Images/trends.png" alt="" className="object-cover" />
              {/* trends11.png */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]  ">
              <img
                src="/Images/effectual.png"
                alt=""
                className="object-cover "
              />
              {/* effectual.jpg */}
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className=" mx-[2%]">
              <img src="/Images/odhini.png" alt="" className="object-cover" />
            </div>
            {/* odhini11 */}
          </SwiperSlide>

          <SwiperSlide>
            <div className=" mx-[2%]">
              <img src="/Images/silkMart.png" alt="" className="object-cover" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className=" mx-[2%] flex">
              <img src="/Images/brSr.png" alt="" className="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img
                src="/Images/okinawaAvia.png"
                alt=""
                className="object-cover"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className=" mx-[2%]">
              <img
                src="/Images/asianIndian.png"
                alt=""
                className="object-cover"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img
                src="/Images/marsMainpuri.png"
                alt=""
                className="object-cover"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img
                src="/Images/ssCrabgrasss.png"
                alt=""
                className="object-cover"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" mx-[2%]">
              <img
                src="/Images/prathNewtalent.png"
                alt=""
                className="object-cover"
              />
            </div>
          </SwiperSlide>

          
        </Swiper>
      </div>
    </h1>
  );
};

export default Client;
