import React,{useState} from "react";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { TbEdit } from "react-icons/tb";
import FreeQuote from "../FreeQuotes/FreeQuote";
const WebsiteDevelopmentHome = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  }; 
  return (
    <>
      <div className=" px-[6%] pt-[25%] pb-[5%] md:pb-[10%] mb-[10%] lg:pt-[7%]  bg-gradient-to-b from-[#352446]  to-[#643c90]   text-white">
        <div className="flex justify-evenly  flex-wrap lg:flex-nowrap mt-[5%]">
          <div className=" lg:w-9/12">
            <h1 className=" tracking-[0.02em] pb-[2%] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
              Certified Website Development Company
            </h1>
            <h1 className="mb-[1rem] text-justify md:mr-[3%]">
              Abscod is a highly reputed web development company based in India,
              known for delivering exceptional web solutions to clients
              worldwide. We understand the unique needs of each client and work
              collaboratively to create bespoke web solutions that align with
              their business goals.
            </h1>
            <div className="flex  flex-col md:flex-row lg:gap-32 ">
              <div className="flex flex-col ">
                <ul>
                  <li className="my-2 flex items-center">
                    <span className=" mr-3">
                      <IoIosArrowDroprightCircle className="text-white" />
                    </span>
                    Custom Web Development Services
                  </li>
                  <li className="my-2 flex  items-center">
                    <span className=" mr-3">
                      <IoIosArrowDroprightCircle className="text-white" />
                    </span>
                    Mobile Optimized Web Development
                  </li>
                  <li className="mt-2 flex  items-center">
                    <span className=" mr-3">
                      <IoIosArrowDroprightCircle className="text-white" />
                    </span>
                    Intranet Applications
                  </li>
                </ul>
              </div>
              <div className="flex flex-col">
                <ul>
                  <li className="my-2 flex  items-center">
                    <span className=" mr-3">
                      <IoIosArrowDroprightCircle className="text-white" />
                    </span>
                    B2B Portal
                  </li>
                  <li className="my-2 flex  items-center">
                    <span className=" mr-3">
                      <IoIosArrowDroprightCircle className="text-white" />
                    </span>
                    User-Friendly Interface
                  </li>
                  <li className="my-2 flex  items-center">
                    <span className="bg-whifte mr-3">
                      <IoIosArrowDroprightCircle className="text-white" />
                    </span>
                    Responsive Website
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="mt-[4%] text-[#643c90] text-center font-[600] flex  items-center bg-[#ffffff] px-6 py-4   leading-[1.2] uppercase rounded-full "
              onClick={popHandler}
            >
              <TbEdit className=" mr-2" /> Request a Quote
            </button>
          </div>

          <div className="md:mt-[3%] mt-[3%] lg:mt-[-2%] lg:col-md-6 lg:ml-10 lg:w-1/2 text-center ">
            <img
              src="/Images/webh.webp"
              alt=""
              className="lg:h-[400px] lg:w-[585px] mb-[3%]"
            />
          </div>
        </div>
      </div>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
    </>
  );
};

export default WebsiteDevelopmentHome;
