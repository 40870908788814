import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceCart = ({ img, heading, content, background, route }) => {
  return (
    <div className=" shadow-md mx-[20px] my-[20px] bg-white  flex flex-col content-evenly justify-evenly box-border border-[2px] items-start text-[#131111] px-[20px] py-[20px] max-w-[350px]  rounded-[30px] ">
      <div className="mx-auto h-32  ">
        <img src={img} alt="" className="h-28 w-full object-cover" />
      </div>
      <h2 className="mx-auto font-[500] mb-1 md:font-[700] text-[20px] md:text-[22px] leading-[22px] md:leading-[28px] ">
        {heading}
      </h2>

      <p className="text-[#202121] mb-2 text-justify   ">{content}</p>
      <button className=" rounded-full flex mb-[5px] py-[12px] md:py-[17px] mx-auto px-[10%] md:px-[70px] text-[16px] hover:text-[#000000] text-white bg-black hover:bg-[#ffc107] transition-[0.5s] duration-500  text-center md:font-[700] ">
        <Link to={route}> Read More</Link>
        <span className="ml-[8px] mt-[5px] ">
          <FaChevronCircleRight />
        </span>
      </button>
    </div>
  );
};

export default ServiceCart;
