import React from "react";
import Heading from "../../components/Heading";

const LogoDesignPre = () => {
  return (
    <div className="mx-[6%] py-[3%]">
      <span className="text-center ">
        <Heading content="Some previous Work" />
      </span>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 mt-[5%]">
        <img
          src="/Images/logomars.png"
          alt=""
          className="w-60  object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logosr.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logomart.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logoaviaWell.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logoElite.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logossenter.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logoanushka.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logomainpri.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logoiifem.jpg"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
        <img
          src="/Images/logoslik.png"
          alt=""
          className="w-60 object-cover lg:h-60 md:h-52 h-40 shadow-md rounded-[12px] transition-transform duration-500 ease-in-out transform hover:scale-90"
        />
       
      </div>
    </div>
  );
};

export default LogoDesignPre;
