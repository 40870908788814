import React from 'react'

const SoftwareWhyWE = () => {
  return (
    <div
      style={{
        backgroundImage: `url("/Images/softwarebg.png")`,
        backgroundSize: "cover",
      }}
      className="py-[2%]"
    >
      {" "}
      <div
        className="text-white text-center font-[500]  md:font-[800] text-[25px]
     md:text-[35px] my-[20px]  leading-[1.2]  md:tracking-[-0.02em] 
      whitespace-wrap "
      >
        Why Choose <span className="text-[#FFDB16]">Abscod</span>
      </div>
      <div className="flex md:justify-evenly flex-wrap lg:flex-nowrap justify-evenly py-[2%] ">
        <div className=" py-[2%] px-[1%] w-32 bg-bhack  md:mt-[2%] mx-1 md:ml-[10%]">
          <img
            src="/Images/satisfactionguarantee.png"
            alt=""
            className="object-cover"
          />
          <h1 className="text-white text-center mt-2">
            100% Satisfaction Guaranty
          </h1>
        </div>
        <div className=" py-[2%] px-[1%] w-32 bg-bhack  md:mt-[6%] mx-1 md:ml-[10%]">
          <img
            src="/Images/customersupport.png"
            alt=""
            className="object-cover"
          />
          <h1 className="text-white text-center mt-2">24x7 Custom Support</h1>
        </div>
        <div className=" py-[2%] px-[1%] w-32 bg-bhack  md:mt-[0%] mx-1 md:ml-[10%]">
          <img src="/Images/AB.png" alt="" className="object-cover" />
          <h1 className="text-white text-center mt-2">
            Cost-effective development solutions
          </h1>
        </div>
        <div className=" py-[2%] px-[1%] w-32 bg-bhack  md:mt-[5%] mx-1 md:ml-[10%]">
          <img src="/Images/9ab.png" alt="" className="object-cover" />
          <h1 className="text-white text-center mt-2">
            Integration with different platforms
          </h1>
        </div>
      </div>
    </div>
  );
}

export default SoftwareWhyWE