import React,{useState} from "react";
import Counter from "../../components/Counter";
import { FaChevronRight } from "react-icons/fa";
import FreeQuote from "../FreeQuotes/FreeQuote";

const SocialMediaMarkectingHome = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  };
  return (
    <>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
      <div className="bg-[#086e9a] pt-[25%]  md:pt-[20%] lg:pt-[10%]  px-[6%]  pb-[15%] mb-[5%] ">
        <div className=" flex flex-wrap lg:flex-row lg:flex-nowrap lg:justify-between ">
          <div className="md:w-full lg:w-7/12  ">
            <h1 className="text-[#FFDB16] mb-[3%]  tracking-[0.28em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
              Engage and Grow
            </h1>
            <p className=" text-[#FCFCFD]  font-[500] md:font-[900] text-[28px] md:text-[42px] leading-[38px] md:leading-[48px]">
              Power Up Your Social Media Presence with Our Expert Marketing
              Strategies
            </p>
            <p className="text-white text-[18px] mt-2 md:mr-[4%]">
              Embracing the power of Social Media, Abscod Online Marketing helps
              you influence your target audience and add value to your brand the
              result of which is increased sales and quality traffic on your
              business site.
            </p>
            <button
              onClick={popHandler}
              className="mt-[7%] bg-[#FFDB16] uppercase text-black font-[700] py-3 px-10 rounded-full focus:outline-none focus:shadow-outline transition duration-500 ease-in-out hover:bg-[black] hover:text-white"
              type="submit"
            >
              <div className="flex">
                <span>Request a quote</span>
                <span className="ml-[8px] mt-[5px]">
                  <FaChevronRight />
                </span>
              </div>
            </button>
          </div>
          <div className="flex flex-col pt-[7%] items-center w-full ml-[-5%]  lg:w-[30%]  ">
            <div className="bg-[#ffc107] w-56 h-56 md:w-64 md:h-64 rounded-full ">
              <div className="text-center mt-[21px] md:mt-[15px] ml-[-40px] md:ml-[-35px] w-40 md:w-44 rounded-[12px]  h-24 md:h-28  mx-1 bg-white  shadow-md ">
                <span className="m-[3%] text-center">
                  <h1 className="font-[900] text-2xl flex justify-center">
                    <Counter maxCount={10} /> <b className="ml-[2%]">+</b>
                  </h1>
                  <p>SEO</p>
                </span>
              </div>
              <div className="mt-[7px] ml-[-35px] w-40 md:w-44 rounded-[12px] h-24 md:h-28  mx-1 bg-white  shadow-md ">
                <span className="m-[3%] text-center">
                  <h1 className="font-[900] text-2xl flex justify-center">
                    <Counter maxCount={100} /> <b className="ml-[2%]">+</b>
                  </h1>
                  <p>SEO</p>
                </span>
              </div>
              <div className="md:ml-[150px] ml-[135px] mt-[-159px] md:mt-[-178px] w-40 md:w-44 rounded-[12px]  h-24 md:h-28  mx-1 bg-white  shadow-md ">
                <span className="m-[3%] text-center">
                  <h1 className="font-[900] text-2xl flex justify-center">
                    <Counter maxCount={400} />
                    <b className="ml-[2%]">+</b>
                  </h1>
                  <p>SEO</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaMarkectingHome;
