import React from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";
import FeaturesCart from "../WebsiteDevelopment/FeaturesCart";
const Services = () => {
  return (
    <div className="py-[5%]">
      <div className="text-center leading-[28px] mx-[7%] ">
        <Heading content="Why Abscod is the No.1 Choice for Businesses" />
        <span>
          <SubHeading SubHeading=" We understand the importance of timely branding, and that's why we provide top-notch quality logo designs along with prompt customer service to ensure that you get the branding your company deserves." />
        </span>{" "}
      </div>

      <div className="flex flex-wrap justify-center py-[2%] mx-[5%]">
        <FeaturesCart
          content={
            "With over 2+ years in the industry, we are pretty sure we understand what kind of branding small businesses and startups need. You will find all the necessarily brand designs."
          }
          img="/Images/WorkingBusinessNeeds.jpg"
          heading={"Working Business Needs"}
        />
        <FeaturesCart
          content={
            "We take pride in our team of skilled and experienced graphic and logo designers who ensure that every design we create is of top-notch quality social media graphic, stationery, or website design."
          }
          img="/Images/PremiumGraphicDesign.png"
          heading={"Premium  Graphic Design"}
        />
        <FeaturesCart
          content={
            "Not all customers like DIY logo maker tools which is why we also have the option of custom logo design. Work with experienced and professional graphic designers or logo designers."
          }
          img="/Images/WorkCustomDesigners.png"
          heading={"Work Custom Designers"}
        />
        <FeaturesCart
          content={
            "Our support team can get you started with our logo maker free for try out or design a company logo by a logo designer. We can even help guide you how to build an awesome brand from scratch."
          }
          img="/Images/DedicatedSupport.png"
          heading={"Dedicated Support 24/7"}
        />
        <FeaturesCart
          content={
            "All our graphic designs are ready for integration and use on all types of digital media whether it is a social media, website design, or app design. You can download your business logo..."
          }
          img="/Images/DigitalMediaDesign.png"
          heading={"Digital Media Design"}
        />
        <FeaturesCart
          content={
            "If you are looking for affordable logo designs and brand designs to make up for your brand kit, then LogoDesign.net offers just the tools to jumpstart your brand. Just start using our logo maker, and download "
          }
          img="/Images/BrandKitforStartups.png"
          heading={"Brand Kit for Startups"}
        />
      </div>
    </div>
  );
};

export default Services;
