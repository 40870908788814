import React from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";
import FeaturesCart from "./FeaturesCart";

const WebFeatures = () => {
  return (
    <div className=" py-[3%]  flex flex-col justify-center items-center  w-[100%] bg-[#faf7f7]">
      <div className="text-center">
        <Heading content={"Web Development Features"} />
        <div className="mt-[1%] px-[3%]"><SubHeading
            SubHeading={"Fine Design Doesn't Just Happen, We make it happen!"}
          />
        </div>
      </div>
      <div className=" w-[90%] flex flex-row flex-wrap  justify-evenly pt-[20px] ">
        <FeaturesCart
          content={
            "We strive to ensure that your website looks great across different devices and offers best user experience."
          }
          img="/Images/responsive-web.png"
          heading={"Responsive Websites"}
        />
        <FeaturesCart
          content={
            "We can optimize your website to make it SEO friendly and help it reach the top in Google search results."
          }
          img="/Images/seo-friendly1.png"
          heading={"SEO Friendly"}
        />
        <FeaturesCart
          content={
            "We promote effective communication between you and your audience by using easy-to-use components"
          }
          img="/Images/socialmedia.png"
          heading={"Social Integration"}
        />
        <FeaturesCart
          content={
            "We integrate all your blog post to keep your customers informed about the latest development"
          }
          img="/Images/blog-integration.png"
          heading={"Blog Integration"}
        />
        <FeaturesCart
          content={
            "We make sure to adjust the style and aesthetic value that suit your brand in the best possible manner"
          }
          img="/Images/theme.png"
          heading={"Theme Integration"}
        />
        <FeaturesCart
          content={
            "We allow your visitors to make a general inquiry about your products or services without efforts"
          }
          img="/Images/INQUIRYINTEGRATION.png"
          heading={"Inquiry Integration"}
        />
      </div>
    </div>
  );
};

export default WebFeatures;
