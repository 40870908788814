import React, { useState } from "react";
import Heading from "../../components/Heading";
import FreeQuote from "../FreeQuotes/FreeQuote";


const DigitalMarketingHome = () => {
 const [showPopup, setShowPopup] = useState(false);
 const popHandler = () => {
   window.scrollTo(0, 0);
   setShowPopup(!showPopup);
 };
  return (
    <div className="  pb-[10%] bg-[#022dbb] flex flex-col justify-between">
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
      <div className="p-[6%] pt-[7%] py-[2%]">
        <div className="lg:w-7/12 text-white md:pt-[3%] pt-[30%]">
          <Heading
            content=" Build A Formidable Brand Identity With Our Digital Marketing
            Services"
          />

          <h2 className="text-[20px] mt-[20px] text-white mb-[10px] md:mb-[30px] font-[800]">
            Skyrocket your visibility, foster lasting customer relationships,
            and soar ahead of your competitors.
          </h2>
          <h3 className="text-[18px] text-white leading-[1.5] font-[400] mb-[10px] md:mb-[30px] text-justify md:mr-[6%]">
            Be it garnering new leads, consolidating your brand’s online
            footprint, or maximizing your revenue, digital marketing helps lend
            a competitive edge to your business. As a leading digital marketing
            firm, we help you achieve all these goals and more. Powered by our
            skilled technicians and customer-first ideology, our digital
            marketing services are sure to elevate your brand’s growth to the
            next level.
          </h3>
          <button
            onClick={popHandler}
            className="bg-white hover:bg-black hover:text-white transition-all duration-300 text-black rounded-full text-[16px] md:text-[18px] text-center px-8 py-4 font-[700]"
          >
            Explore Digital Marketing Packages
          </button>
        </div>
      </div>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
    </div>
  );
};

export default DigitalMarketingHome;

// {/* <div
//   name="home"
//   className="w-full lg:h-screen md:h-screen h-screen bg-[#83c0fc] flex flex-col justify-between"
// >
//   {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
//   <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
//     <div className="flex flex-col justify-center md:items-start w-full px-2 text-white ">
//       <p className="text-2xl  text-[#FFDB16] tracking-[0.28em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
//         Unique Sequencing & Production
//       </p>
//       <span>
//         {" "}
//         <Heading content="digital marketing " />
//       </span>
//       <p className="text-2xl tracking-[0.28em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px] text-white">
//         Unlock Your Business's Potential with Our Expert Digital Marketing
//         Solutions
//       </p>
//       <button
//         onClick={popHandler}
//         className="text-[18px] font-semibold py-3 px-6 sm:w-[60%] mt-[1%] mb-[3%] bg-black text-white hover:text-black duration-500 hover:bg-[#FFDB16] rounded-full"
//       >
//         Get Started
//       </button>
//     </div>
//     <div className="hidden sm:block md:block">
//       <img
//         className="w-full h-[90%]"
//         src="/Images/digitalMarHome.svg"
//         alt="/"
//       />
//     </div>
//     <div
//       style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
//       className="absolute flex flex-col py-8 md:min-w-[760px] bottom-[-40px]
//             mx-1 md:left-1/2 transform md:-translate-x-1/2 bg-zinc-200
//             border border-slate-300 rounded-xl text-center "
//     >
//       <p className="font-900 text-[22px] font-[900] text-black">
//         Digital Marketing
//       </p>
//       <div className="flex justify-between flex-wrap px-4">
//         <p className="flex px-4 py-2 font-bold text-[20px] text-black">
//           <CloudUploadIcon className="h-6 mr-1 text-[#ffc107]" /> App
//           Remarketing
//         </p>
//         <p className="flex px-4 py-2 font-bold text-[20px] text-black">
//           <DatabaseIcon className="h-6 mr-1 text-[#ffc107]" />
//           Marketing Automation
//         </p>
//         <p className="flex px-4 py-2 font-bold text-[20px] text-black">
//           <ServerIcon className="h-6 mr-1 text-[#ffc107]" /> Cloud
//         </p>
//         <p className="flex px-4 py-2 font-bold text-[20px] text-black">
//           <PaperAirplaneIcon className="h-6 mr-1 text-[#ffc107]" /> API
//         </p>
//       </div>
//     </div>
//   </div>
//   </div>; */}
