import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import NavLinks from "./NavLinks";
import FreeQuote from "../FreeQuotes/FreeQuote";
const Navbar = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [hideShow, setHideShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  const openPopupHandler = () => {
    setOpen(!open);
  };
  const popHandler = () => {
    setHideShow(!hideShow);
    setShowPopup(!showPopup);
  };

  return (
    <nav className=" z-[5] absolute w-full ">
      <div
        className={
          color
            ? "w-full bg-[#ffffff]  duration-50 transition-[1.5s] fixed "
            : ""
        }
      >
        <div
          className={
            color
              ? "w-full bg-[#ffffff]  flex items-center   justify-around text-black duration-50 transition-[1.5s] fixed "
              : " flex items-center pt-[10px] text-white  justify-around "
          }
        >
          <div className="hidden md:block  lg:ml-[-2.7%] md:ml-0 ml-0 md:w-auto w-[100%]">
            {color ? (
              <Link to="/">
                {" "}
                <img
                  src="/Images/abscodblack.webp"
                  alt="logo"
                  width="160px"
                  height="160px"
                  className="md:cursor-pointer object-cover"
                />
              </Link>
            ) : (
              <Link to="/">
                {" "}
                <img
                  src="/Images/abscodwhite.webp"
                  alt="logo"
                  width="160px"
                  height="160px"
                  className="md:cursor-pointer object-cover"
                />
              </Link>
            )}
          </div>
          {hideShow ? (
            <div className=" px-[3%] md:w-auto w-[100%]  flex justify-between lg:hidden md:hidden">
              {color ? (
                <Link to="/">
                  {" "}
                  <img
                    src="/Images/abscodblack.webp"
                    alt="logo"
                    width="150px"
                    height="160px"
                    className="md:cursor-pointer object-cover"
                  />
                </Link>
              ) : (
                <Link to="/">
                  {" "}
                  <img
                    src="/Images/abscodwhite.webp"
                    alt="logo"
                    width="150px"
                    height="160px"
                    className="md:cursor-pointer object-cover"
                  />
                </Link>
              )}
              <div
                className="text-3xl pt-7 pr-2  md:hidden"
                onClick={openPopupHandler}
              >
                <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* window */}
          <ul className="md:flex text-lg hidden items-center gap-8 ">
            <li>
              <Link to="/" className="py-7 px-3 inline-block">
                Home
              </Link>
            </li>
            <NavLinks />
            <li>
              <Link to="/aboutus" className="py-7 px-3 inline-block">
                About us
              </Link>
            </li>
            <li>
              <Link to="/faq" className="py-7 px-3 inline-block">
                FAQs
              </Link>
            </li>
            <li>
              <Link to="/contactus" className="py-7 px-3 inline-block">
                Contact us
              </Link>
            </li>
          </ul>
          <div
            className="md:block hidden "
            onClick={() => setShowPopup(!showPopup)}
          >
            <Button
              color="black"
              content="Request a Quote"
              background="linear-gradient(to right, #FFDB16, #FFDB16)"
            />
          </div>
          {/* Mobile nav 
          
          lazzing loading img code  <LazyLoad
          once={true}
          placeholder={<img src="/Images/abc1-placeholder.png" alt="loading" />}
        >
          <img
            src="/Images/abscodwhite.webp"
            alt="logo"
            className="md:cursor-pointer  w-40"
          />
        </LazyLoad>
          */}
          <ul
            className={`bg-gradient-to-br from-sky-400 to-transparent 
        md:hidden text-white bg-black fixed w-full top-0 overflow-y-auto bottom-0 pt-[2%]
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
          >
            <div className=" px-[4%] pt-2 flex justify-between  md:hidden">
              <Link to="/">
                <img
                  src="/Images/abscodwhite.webp"
                  alt="logo"
                  height="160px"
                  width="150px"
                  className="md:cursor-pointer object-cover"
                />
              </Link>
              <div
                className="text-3xl pt-5 pr-2 text-white md:hidden"
                onClick={openPopupHandler}
              >
                <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
              </div>
            </div>
            <ul className="mt-[7%]">
              <li>
                <Link to="/" className="pb-2 text-lg  px-5 inline-block">
                  Home
                </Link>
              </li>
              <div className="pb-2 px-2 text-lg">
                <NavLinks />
              </div>
              <li>
                <Link to="/aboutus" className="pb-2 text-lg px-5 inline-block">
                  About us
                </Link>
              </li>
              <li>
                <Link to="/faq" className="pb-2 text-lg px-5 inline-block">
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to="/contactus"
                  className="pb-2 text-lg px-5 inline-block"
                >
                  Contact us
                </Link>
              </li>
            </ul>

            {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
            {/* mobile */}
            <div className="py-5 px-5 " onClick={popHandler}>
              <Button content="Request a Quote" />
            </div>
          </ul>
        </div>
        <div className="hidden sm:block ">
          {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
