import React, { useState } from "react";
import WebsiteClientFeedbackCart from "./WebsiteClientFeedbackCart";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation, Mousewheel, Keyboard } from "swiper";

const WebsiteClientFeedback = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const images = [
    {
      src: "/Images/search.jpg",
      alt: "Image 1",
      name: "Research",
      definition:
        "Our committed team works closely with your company to establish a clear understanding of your objectives and the services you wish to promote. We then develop a well-defined plan that ensures efficient utilization of your resources while protecting against unnecessary spending.",
    },
    {
      src: "/Images/design.png",
      alt: "Image 2",
      name: "Design",
      definition:
        "we understand that your website is the digital face of your brand. That's why we take a personalized approach to website design, ensuring that your website not only meets but exceeds your business needs and expectations.",
    },
    {
      src: "/Images/eye.jpg",
      alt: "Image 3",
      name: "Client Approval",
      definition:
        "We believe that our clients are the key to our success. We take great care to listen to your feedback and incorporate it into the website design, ensuring that the final product meets and exceeds your expectations.",
    },
    {
      src: "/Images/setting.jpg",
      alt: "Image 4",
      name: "Testing",
      definition:
        "Our qualified developers make sure to attend to the final details to test the last minute compatible issues. We assure that your website will be optimized properly to be viewed even in the most recent browsers by your target audience!",
    },
    {
      src: "/Images/launch.png",
      alt: "Image 5",
      name: "Launch",
      definition:
        "Once you've given your final approval to the design, our team will begin the process of uploading all the necessary files to the server.",
    },
  ];

  return (
    <div className="">
      <div className="px-[5%] py-[3%] hidden sm:block">
        <h1 className="font-[800] text-center text-black mb-[1%] text-[3rem]">
          Collaboration, From Concept to Code
        </h1>
        <div className="grid grid-cols-5  pt-[3%]">
          {images.map((image, index) => (
            <div
              key={index}
              className="group-hover:cursor-pointer"
              onClick={() => setSelectedImage(index)}
            >
              <img
                src={image.src}
                alt={image.alt}
                className={`cursor-pointer object-cover h-10 w-10 mx-auto ${
                  selectedImage === index
                    ? "active:border-red-800 object-cover"
                    : ""
                }`}
              />
              <p className="mt-4 font-[600] text-center text-gray-800 active:text-gray-400">
                {image.name}
              </p>
              {selectedImage === index && (
                <hr className="mt-3 h-[3px] bg-red-500" />
              )}
              {selectedImage !== index && (
                <hr className="mt-3 h-[3px] bg-black" />
              )}
            </div>
          ))}
        </div>

        <div>
          {selectedImage !== null && (
            <p className="mt-2 py-3 text-[18px]">
              {images[selectedImage].definition}
            </p>
          )}
        </div>
      </div>

      <div className="px-[5%] py-[5%] bg-[#13152c]">
        <h1 className="font-[800] text-center text-white mb-[20px] text-[3rem]">
          Our Happy Clients
        </h1>

        <div className="mt-[5%]">
          <SwiperSlide>
            <div
              style={{
                marginTop: "120px",
                color: "#fbcc0d",
                marginLeft: "-30px",
                zIndex: "1",
              }}
              className="swiper-button-prev "
            ></div>
          </SwiperSlide>
          <Swiper
            cssMode={true}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            mousewheel={true}
            keyboard={true}
            breakpoints={{
              640: { slidesPerView: 1 },
              778: { slidesPerView: 2 },
              1024: { slidesPerView: 2 },
              1224: { slidesPerView: 3 },
              1324: { slidesPerView: 3 },

              1524: { slidesPerView: 3 },
            }}
            modules={[Navigation, Mousewheel, Keyboard]}
            className="mySwiper"
          >
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Harshil Mathur"
                companyName="IIFEM"
                feedback="I recently hired Abscod Infomatics to develop a website for my business, and I was thoroughly impressed with the level of professionalism and expertise displayed throughout the entire process."
              />
            </SwiperSlide>
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Gaurav Munjal"
                companyName="Cevious Technologies"
                feedback="I had been struggling to find a reliable and trustworthy website development service until I came across Abscod Infomatics. Their team was extremely helpful and communicative, guiding me through each step of the development process and ensuring."
              />
            </SwiperSlide>
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Zender Lama"
                companyName="Kulmaya"
                feedback="Working with Abscod Infomatics was a seamless experience from start to finish. Their team was incredibly responsive and attentive to my needs, and they delivered a website that not only looked great but also functioned flawlessly."
              />
            </SwiperSlide>
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Amit Chauhan"
                companyName="DG Collection"
                feedback="Abscod Infomatics exceeded my expectations in every way possible. Their team was incredibly knowledgeable and skilled, and they were able to develop a website that perfectly aligned with my vision for my business. "
              />
            </SwiperSlide>
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Saurabh Tanwani"
                companyName="Crabgrass"
                feedback="Abscod Infomatics provided exceptional website development services for my business. Their attention to detail and dedication to ensuring that every aspect of the website was functioning properly was impressive. I would definitely recommend them ."
              />
            </SwiperSlide>
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Saurabh Trivedi"
                companyName="24 India Times"
                feedback="I am extremely happy with the website that Abscod Infomatics developed for my business. Not only does it look great, but it also functions perfectly and has helped me to increase my online visibility and drive more traffic to my site."
              />
            </SwiperSlide>
            <SwiperSlide>
              <WebsiteClientFeedbackCart
                clientName="Amit Aggarwal"
                companyName="Effectual Services"
                feedback="Abscod Infomatics was a pleasure to work with. They were always available to answer my questions and address any concerns that I had, and they delivered the website within the agreed-upon timeframe. I would definitely work with them again in the future."
              />
            </SwiperSlide>
          </Swiper>
          <SwiperSlide>
            <div
              style={{
                marginTop: "-189px",
                color: "#fbcc0d",
                marginRight: "-30px",
                zIndex: "1",
              }}
              className="swiper-button-next "
            ></div>
          </SwiperSlide>
        </div>
      </div>
    </div>
  );
};

export default WebsiteClientFeedback;
