import React from "react";


const AboutHome = () => {
  return (
    <div
      className=" bg-[#244c68]"
      style={{
        backgroundImage: `url("/Images/Rectangle.webp")`,
        backgroundSize: "cover",
      }}
    >
      <div className="md:py-[20%] lg:py-[10%] pt-[30%] md:h-[96vh]  lg:px-[5%] md:px-[3%]  px-[10px]  flex flex-wrap  lg:flex-row lg:flex-nowrap md:justify-between ">
        <div className="py-[2%] flex flex-col     md:justify-center lg:w-7/12">
          <h1 className="text-[#FFDB16] pb-[2%]  tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
            Innovative Technology Solutions for Businesses
          </h1>
          <p className="  tracking-[0.02em] pb-[2%] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
            Where Innovation Meets Excellence
          </p>
          <p className="text-[#EEF2F6] pb-[2%] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px] mr-[5%]">
            At Abscod Informatics, we specialize in a range of digital services
            to help businesses of all sizes succeed online. Our talented team
            includes experts in digital marketing, software development, mobile
            app development, logo design, content marketing, and web design.
            
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
