import React, { useRef } from "react";
import Heading from "./Heading";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
SwiperCore.use([Autoplay]);

const Testimonial = () => {
  const swiperRef = useRef(null);

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  };
  return (
    <div className="bg-black py-[3%]">
      <div className="mx-[9%] flex flex-col text-center  mb-[10px] ">
        <div className="text-white">
          <Heading content={" What our clients say?"} />
        </div>
        <div className="bg-gradient-to-r from-yellow-500 via-purple-500 to-black w-40 mx-auto h-2 rounded-full my-[2%] "></div>

        <p className="text-[20px] text-[white] mx-auto text-justify md:justify-center">
          We are committed in delivering exceptional services that delivers high
          quality results
        </p>
      </div>
      <div
        className="mt-[3%] mx-auto px-[5%]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Swiper
          style={{
            "--swiper-pagination-color": "#fbcc0d",
            "--swiper-pagination-bullet-inactive-color": "#000000",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
          }}
          loop={true}
          autoplay={{ delay: 1500 }}
          breakpoints={{
            640: { slidesPerView: 1 },
            778: { slidesPerView: 1 },
            1024: { slidesPerView: 2 },
            1224: { slidesPerView: 3 },
            1324: { slidesPerView: 3 },
            1524: { slidesPerView: 3 },
          }}
          modules={[Autoplay]}
          onSwiper={handleSwiper}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/client1.png"
                  alt=""
                  className="rounded-full object-cover w-32 h-32"
                />
              </div>
              <h1 className="text-[20px]  mt-[20px] font-[600] text-black">
                Harshil Mathur
              </h1>
              <h2 className="text-[17px] mb-[15px] font-[500] text-gray-700">
                Innowise Group
              </h2>
              <p className="text-black text-justify ">
                Abscod has been an excellent partner for our digital marketing
                needs. Their team is responsive, knowledgeable, and has a deep
                understanding of our industry. They have helped us challenging
                projects achieve great results with their creative campaign and
                data-driven approach.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/client2.png"
                  alt=""
                  className="rounded-full object-cover w-32 h-32"
                />
              </div>
              <h1 className="text-[20px] mt-[20px] font-[600] text-black">
                Gaurav Munjal
              </h1>
              <h2 className="text-[17px] mb-[20px] font-[500] text-gray-700">
                Cred
              </h2>
              <p className="text-black text-justify ">
                We had a complex software development project that Abscod was
                able to handle with ease. Their team is highly skilled and able
                to handle even the most challenging projects. They communicated
                with us throughout the process that exceeded our expectations.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/client3.png"
                  alt=""
                  className="rounded-full object-cover w-32 h-32"
                />
              </div>
              <h1 className="text-[20px] mt-[20px] font-[600] text-black">
                Bir Blues Boho
              </h1>
              <h2 className="text-[17px] mb-[20px] font-[500] text-gray-700">
                Silk Route Cafe
              </h2>
              <p className="text-black text-justify ">
                Abscod developed a beautiful and functional website for our
                business. Their team is detail-oriented and really understood
                our brand and target audience. The end result was a website that
                is easy to navigate and has helped us drive more leads and
                sales.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/client4.png"
                  alt=""
                  className="rounded-full w-32 object-cover h-32"
                />
              </div>
              <h1 className="text-[20px] mt-[20px] font-[600] text-black">
                Aditya Patil
              </h1>
              <h2 className="text-[17px] mb-[20px] font-[500] text-gray-700">
                Shape me Now
              </h2>
              <p className="text-black text-justify ">
                We were impressed with Abscod's mobile app development services.
                Their team is highly skilled and experienced, and they were able
                to develop an app that met all of our requirements. They were
                responsive throughout process and delivered a great product.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/rishi.png"
                  alt=""
                  className="rounded-full object-cover w-32 h-32"
                />
              </div>
              <h1 className="text-[20px]  mt-[20px] font-[600] text-black">
                Rishi Srivastava
              </h1>
              <h2 className="text-[17px] mb-[15px] font-[500] text-gray-700">
                SatSure
              </h2>
              <p className="text-black text-justify ">
                Abscod has been an excellent partner for our digital marketing
                needs. Their team is responsive, knowledgeable, and has a deep
                understanding of our industry. They have helped us challenging
                projects achieve great results with their creative campaign and
                data-driven approach.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/client6.png"
                  alt=""
                  className="rounded-full object-cover w-32 h-32"
                />
              </div>
              <h1 className="text-[20px]  mt-[20px] font-[600] text-black">
                Dhruv Kumar
              </h1>
              <h2 className="text-[17px] mb-[15px] font-[500] text-gray-700">
                Librari
              </h2>
              <p className="text-black text-justify ">
                Abscod has been an excellent partner for our digital marketing
                needs. Their team is responsive, knowledgeable, and has a deep
                understanding of our industry. They have helped us challenging
                projects achieve great results with their creative campaign and
                data-driven approach.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:w-[350px] mb-8 mt-[20%] mx-auto rounded-[20px] text-center bg-white p-6">
              <div className="w-36 h-36 rounded-full p-2 bg-white mt-[-85px] ml-[80px] mx-auto">
                <img
                  src="/Images/client8.png"
                  alt=""
                  className="rounded-full object-contain w-32 h-32"
                />
              </div>
              <h1 className="text-[20px] mt-[20px] font-[600] text-black">
                Naya
              </h1>
              <h2 className="text-[17px] mb-[20px] font-[500] text-gray-700">
                Quixy
              </h2>
              <p className="text-black text-justify ">
                Abscod developed a beautiful and functional website for our
                business. Their team is detail-oriented and really understood
                our brand and target audience. The end result was a website that
                is easy to navigate and has helped us drive more leads and
                sales.
              </p>
            </div>
          </SwiperSlide>
         
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial;
