import React,{useRef} from 'react'
import Rating from "../ClientFeedback/Rating";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Pagination } from "swiper";
SwiperCore.use([Autoplay]);

const SEOAppreciation = () => {
   const swiperRef = useRef(null);
   const handleSwiper = (swiper) => {
     swiperRef.current = swiper;
   };

   const handleMouseEnter = () => {
     if (swiperRef.current) {
       swiperRef.current.autoplay.stop();
     }
   };
   const handleMouseLeave = () => {
     if (swiperRef.current) {
       swiperRef.current.autoplay.start();
     }
   };
  
  return (
    <div className="px-[6%] py-[5%]">
      <div className="text-[2.5rem] mb-4  text-center font-[500] leading-[1.2]">
        <b>Clients Appreciation</b>
      </div>
      <div className="hidden sm:block md:flex lg:flex justify-between my-[3%] ">
        <div
          style={{ boxShadow: "0px 0px 29px 1px #ccc" }}
          className="  flex flex-col items-center  w-[250px] rounded-[14px] p-4 text-center shadsow-xl  "
        >
          <div className="mb-1 text-[18px] leading-[1.2] font-[400]">
            Totally Recommended!
          </div>
          <div>
            <Rating />
          </div>
        </div>
        <div
          style={{ boxShadow: "0px 0px 29px 1px #ccc" }}
          className="flex flex-col items-center  w-[250px] rounded-[14px] p-4 text-center "
        >
          <div className="mb-1 text-[18px] leading-[1.2] font-[400]">
            Fabulous Services!
          </div>
          <div>
            <Rating />
          </div>
        </div>
      </div>
      <div
        className="mt-[-5%]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Swiper
          style={{
            "--swiper-pagination-color": "#fbcc0d",
            "--swiper-pagination-bullet-inactive-color": "#000000",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
          }}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 1500 }}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper"
          onSwiper={handleSwiper}
        >
          <SwiperSlide>
            <div className="text-[2rem] font-[700] leading-[1.2] text-center">
              <div>Sarah M</div>
              <div>
                <Rating />
              </div>
            </div>
            <div className="font-[400] text-[20px] text-justify  mb-[15%] md:mb-[5%] lg:mb-[3%] leading-[1.2] my-[2%]">
              I have worked with several SEO agencies in the past, but Abscod
              stands out as the best of the best. They took the time to
              understand my business needs and goals, and created a customized
              SEO strategy that has exceeded my expectations.
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="text-[2rem] font-[700] leading-[1.2] text-center">
              <div>Pankaj Suri</div>
              <div>
                <Rating />
              </div>
            </div>
            <div className="font-[400] text-[20px] text-justify mb-[15%] md:mb-[5%]   leading-[1.2] my-[2%]">
              The team at Abscod my business achieve incredible results in a
              short period of time. They are professional, reliable, and always
              available to answer any questions or concerns. I highly recommend
              their services to anyone looking to improve their website rankings
              .
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="text-[2rem] font-[700] leading-[1.2] text-center">
              <div>Satyam Swarup</div>
              <div>
                <Rating />
              </div>
            </div>
            <div className="font-[400] text-[20px] text-justify mb-[15%] md:mb-[5%] leading-[1.2] my-[2%]">
              all thanks to their expertise in SEO. Their team is professional,
              knowledgeable, and always goes above and beyond to ensure that we
              are happy with the results. I highly recommend their services to
              anyone looking to improve their online presence.
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default SEOAppreciation