import React from "react";
import Button from "../pages/NavBar/Button";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Autoplay } from "swiper";

const Main = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <div
        className=" lg:px-[5%] px-[10px]  md:pb-36  flex flex-wrap lg:flex-row lg:flex-nowrap lg:justify-evenly "
        style={{
          position: "relative",
          backgroundImage: `url("/Images/Rectangle.webp")`,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "1197px",
            left: "712px",
            top: "8px",
            zIndex: "0",
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(239, 205, 19, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
            filter: "blur(79px)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "1310px",
            height: "1197px",
            left: "-638px",
            top: "-539px",
            zIndex: "0",
            background:
              "radial-gradient(50% 50% at 50% 50%, rgb(114 184 229 / 80%) 0%, rgba(0, 0, 0, 0.5) 100%)",
            filter: "blur(79px)",
          }}
        ></div>
        <div className="md:pt-[14%] lg:pt-[14%] pt-[40%] flex flex-col z-[0] lg:content-center  md:justify-center w-full  lg:w-7/12 ">
          <div className="lg:pr-5">
            <Swiper
              loop={true}
              autoplay={{
                delay: 6000,
              }}
              breakpoints={{
                640: { slidesPerView: 1 },
                778: { slidesPerView: 1 },
                1024: { slidesPerView: 1 },
                1224: { slidesPerView: 1 },
                1324: { slidesPerView: 1 },
                1524: { slidesPerView: 1 },
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <h1 className="text-[#FFDB16]   tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Transform Your Online Presence
                </h1>
                <p className="  tracking-[0.02em] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
                  Expert Digital Marketing Solutions
                </p>
                <p className="text-[#EEF2F6] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Our team of digital marketing experts utilizes a range of
                  strategies to boost your online visibility, including advanced
                  SEO techniques, social media management, targeted PPC
                  campaigns, and engaging content marketing.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="text-[#FFDB16]   tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Transform Your Online Presence
                </h1>
                <p className="  tracking-[0.02em] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
                  Expert Software Solutions
                </p>
                <p className="text-[#EEF2F6] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Our team of digital marketing experts utilizes a range of
                  strategies to boost your online visibility, including advanced
                  SEO techniques, social media management, targeted PPC
                  campaigns, and engaging content marketing.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="text-[#FFDB16]   tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Transform Your Online Presence
                </h1>
                <p className="  tracking-[0.02em] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
                  Website Development Solutions
                </p>
                <p className="text-[#EEF2F6] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Our team of skilled web developers leverages a variety of
                  techniques to create stunning websites that engage your
                  audience, including responsive design, user-friendly
                  navigation, strategic content development, and advanced SEO
                  optimization.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className="text-[#FFDB16]   tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Transform Your Online Presence
                </h1>
                <p className="  tracking-[0.02em] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
                  Expert Mobile Application Solutions
                </p>
                <p className="text-[#EEF2F6] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
                  Our team of seasoned mobile app developers employs innovative
                  strategies to create user-friendly and high-performing
                  applications, including native and hybrid app development,
                  UI/UX design, quality assurance testing, and ongoing
                  maintenance and support.
                </p>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="flex flex-wrap  lg:flex-nowrap md:justify-start md:content-start md:my-[20px] mr-[20%]   ">
            <Button
              onClick={() => {
                navigate("/digital-marketing");
              }}
              content="Digital Marketing"
              color="black"
              background="linear-gradient(to right, #FFDB16, #FFDB16)"
            />
            <Button
              onClick={() => {
                navigate("/software-development");
              }}
              content="Software Development"
              background=" linear-gradient(270deg, #239be6, #239be6)"
            />
          </div>
        </div>
        <div className="lg:mt-[150px] lg:w-1/2  my-10 z-0 ">
          <img
            src="/Images/img3.png"
            alt=""
            className=" ml-[14%] w-[70%] md:w-[100%] lg:w-[70%] mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
