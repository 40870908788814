import React from "react";
import Navbar from "../NavBar/Navbar";
import SoftwareHome from "./SoftwareHome";
import SoftwareAbout from "./SoftwareAbout";
import SoftwareStackUse from "./SoftwareStackUse";
import SoftwareWhyWE from "./SoftwareWhyWE";
import SoftwarePlan from "./SoftwarePlan";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import FormHeroSection from "../../components/FormHeroSection";

const SoftwareDevelopment = () => {
  return (
    <>
      <Navbar />
      <SoftwareHome />
      <SoftwareAbout />
      <FormHeroSection/>
      <SoftwareStackUse />
      <SoftwareWhyWE />
      <SoftwarePlan/>
      <FAQ
        title="What products or services does your company offer?"
        title1="How does your company ensure quality and customer satisfaction?"
        title2="How can your company help my business grow?"
        title3="What sets your company apart from the competition?"
        title4="What is the process for working with your company?"
        paragraph="Our company offers a range of products and services that are designed to meet the needs of businesses and individuals. We specialize in software development, web design and development, mobile app development, and digital marketing."
        paragraph1="At our company, we are committed to delivering high-quality products and services that meet or exceed our customers' expectations. We use a rigorous quality assurance process to ensure that our software and websites are error-free and meet all of the requirements. We also provide excellent customer support and are always available to answer any questions or concerns you may have."
        paragraph2="Our company specializes in helping businesses grow by providing them with the tools and resources they need to succeed. We offer custom software development, web design and development, mobile app development, and digital marketing services that are tailored to meet the unique needs of each business."
        paragraph3="At our company, we are committed to delivering exceptional products and services that are designed to meet the needs of our customers. We are constantly innovating and staying up-to-date with the latest technologies and trends in our industry."
        paragraph4="Our process for working with clients is simple and straightforward. We start by conducting a thorough analysis of your business needs and goals, then we create a detailed plan for your project. From there, we work closely with you throughout the development process to ensure that we are meeting your expectations and delivering high-quality results."
      />
      <Footer />
    </>
  );
};

export default SoftwareDevelopment;
