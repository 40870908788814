import React from 'react'
import Heading from '../../components/Heading';

const MobileAppProcess = () => {
     const images = [
       {
         src: "/Images/logoclick.png",
         alt: "Image 1",
         heading: "Requirement Gathering",
         definition:
           "Our concerned team members get in touch with you in no time to ask, assist, and analyze for all the necessary details required for your mobile application's development.",
       },
       {
         src: "/Images/logoclick.png",
         alt: "Image 2",
         heading: "Wireframe",
         definition:
           "As soon as we get your requirements right, our design teams start preparing the wireframe for your application. It helps you get an idea of the key features and their placement on the interface.",
       },
       {
         src: "/Images/Logoclick.png",
         alt: "Image 3",
         heading: "UI/UX Design",
         definition:
           "Once you give us the go-ahead for the wireframe, we proceed with the designing phase of your application. We make sure you only get what captivates your target audience.",
       },
       {
         src: "/Images/Logoclick.png",
         alt: "Image 4",
         heading: "Live Prototyping",
         definition:
           "With the beta version of your app in hand, we conduct a series of quality tests, carried under yours and our direct supervision, in real-world conditions.",
       },
       {
         src: "/Images/Logoclick.png",
         alt: "Image 5",
         heading: "App Development",
         definition:
           "Finally, our cross-functional development team takes it from here. We build a robust mobile application that stands the test of time.",
       },
       {
         src: "/Images/Logoclick.png",
         alt: "Image 6",
         heading: "Quality Assurance",
         definition:
           "Our QA team becomes an integral part of the whole journey as soon as the development process starts. From day one, your app starts to get the attention it deserves.",
       },
       {
         src: "/Images/Logoclick.png",
         alt: "Image 7",
         heading: "App Launch",
         definition:
           "Get! Set! And Launch! Take your application live and see users flood in. Enjoy your success while your users get to use and experience the best mobile ever.",
       },
       {
         src: "/Images/Logoclick.png",
         alt: "Image 8",
         heading: "Support & Maintenance",
         definition:
           "We never lose touch. Our quality assistance team provides you with 24/7 lifetime technical support.",
       },
     ];
  return (
    <div className="p-[6%]">
      <div className="py-[3%] text-center">
        <Heading content="Our Process" />
      </div>
      <div>
        <ul className="grid  lg:grid-cols-4 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 ">
          {images.map((image) => (
            <li
              style={{ boxShadow: "4px 5px 15px 2px #dedede" }}
              key={image.id}
              className="   rounded-[4px] p-[5%] "
            >
              <img
                src="/Images/logoclick.png"
                alt={image.alt}
                className="w-[40px] object-cover mb-[25px] "
              />
              <h1 className="font-[600] text-[18px] leading-[1.2] mb-[16px] text-start">
                {image.heading}
              </h1>
              <hr className="w-52 text-center h-1 bg-black" />
              <p className=" pb-4 mt-4 text-justify leading-[30px] text-[18px]">
                {image.definition}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MobileAppProcess