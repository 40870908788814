import React from "react";
import Heading from "../../components/Heading";
import TeamsCart from "./TeamsCart";

const Teams = () => {
  return (
    <div className="bg-slate-50 py-[5%] text-center">
      <Heading content="Our Team Members " />
      <div className="flex justify-center flex-wrap mt-[3%] mx-[5%] ">
        <TeamsCart
          nameOfMember="Ishfaq Khawaja"
          position="Deep Learning Engineer"
          memberImg="/Images/ishfak.png"
        />

        <TeamsCart
          nameOfMember="Mayank Chaturvedi"
          position="UI/UX Desginer / Operational Manager"
          memberImg="/Images/mayank.png"
        />
        <TeamsCart
          nameOfMember="Muskan Gupta"
          position={"Flutter Developer                                        "}
          memberImg="/Images/muskangupta.png"
        />
        <TeamsCart
          nameOfMember="Devansh Pathak"
          position="Graphic designer / Motion Graphics"
          memberImg="/Images/devansh.png"
        />
        <TeamsCart
          nameOfMember="Jayesh Singh"
          position="Video Editor & Motion  Graphic Designer"
          memberImg="/Images/jayesh.jpg"
        />
        <TeamsCart
          nameOfMember="Tushar Sharma"
          position="Digital Marketing Executive"
          memberImg="/Images/TusharSharma.jpg"
        />
        <TeamsCart
          nameOfMember="Rajat Verma"
          position="Full Stack Developer"
          memberImg="/Images/rajatverma.jpg"
        />

        <TeamsCart
          nameOfMember="Devendra Pandey"
          position="Full Stack Developer"
          memberImg="/Images/devendrapandey.png"
        />
      </div>
    </div>
  );
};

export default Teams;
