import React from "react";
import Navbar from "../NavBar/Navbar";
import HeroSection from "../../components/HeroSection";
import LogoDesignPre from "./LogoDesignPre";
import Services from "./Services";
import Client from "../client/Client";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import FormHeroSection from "../../components/FormHeroSection";

const LogoDesign = () => {
  return (
    <>
      <Navbar />
      <HeroSection
        background="linear-gradient(to right, #462444, #462444)"
        heading=" Memorable Branding Concepts"
        img="/Images/logoDesignHome.webp"
        subheading="logo designs to boost businesses"
        deatailhedaing=" we offer professional logo design services to help your business stand out in a crowded marketplace. Our team of experienced designers will work closely with you to develop a custom logo that represents your brand's unique personality and values. Whether you're looking for a simple and classic design or a bold and modern look, we have the expertise to bring your vision to life. Let us help you create a memorable and effective logo that will leave a lasting impression on your customers."
      />
      <Services />
      <FormHeroSection/>
      <LogoDesignPre />
      <Client />
     
      <FAQ
        title="How can I design a logo on Abscod"
        title1="What is the logo design process?"
        title2="How long does it take to design a logo?"
        title3="What file formats will I receive?"
        title4="Can I use my logo on social media and my website?"
        paragraph="A logo is an essential part of your brand's identity and serves as a visual representation of your business. A well-designed logo can help you stand out in a crowded market, build brand recognition, and establish trust with your target audience."
        paragraph1="Our designers work closely with you to understand your brand's personality, values, and target audience, and then create a range of concepts for you to choose from. Once you've chosen a concept, we refine it until it's perfect."
        paragraph2="The time it takes to design a logo can vary depending on several factors, such as the complexity of the design, the number of revisions required, and the responsiveness of the client. Typically, the logo design process takes between 2-4 weeks from start to finish."
        paragraph3="Once your logo is finalized, we provide you with a range of file formats, including JPEG, PNG, PDF, and vector files. These files are optimized for various uses, such as web, print, and signage."
        paragraph4="Yes, you can use your logo on social media, your website, and other marketing materials. In fact, we recommend using your logo consistently across all platforms to build brand recognition and establish a strong visual identity for your business."
      />
      <Footer />
    </>
  );
};

export default LogoDesign;