import React,{useState} from 'react'
import FreeQuote from '../FreeQuotes/FreeQuote';

const DigitalMarInQuary = () => {
     const [showPopup, setShowPopup] = useState(false);
     const popHandler = () => {
       window.scrollTo(0, 0);
       setShowPopup(!showPopup);
     };
  return (
    <div className="px-[6%] flex   flex-col md:flex-row justify-evenly py-[9%]">
      <div className="lg:w-1/3">
        <img
          src="/Images/digital-marketing-firm.png"
          alt=""
          className="object-cover"
        />
      </div>
      <div className="lg:w-1/2  p-[3%]">
        <h1
          className="font-[500]  md:font-[800] text-[22px]
     md:text-[35px]  mb-[20px] text-justify md:text-start leading-[1.2]  md:tracking-[-0.02em] 
      whitespace-wrap "
        >
          A Digital Marketing Firm That Believes In Unleashing Limitless
          Possibilities
        </h1>
        <h2 className="mb-[20px] text-justify text-[18px]">
          An effective digital marketing campaign requires strategic planning
          and consistent optimization to drive results. Our digital marketing
          agency services have the bandwidth and faculty to execute multiple
          campaigns with minimal turnaround time at scale. We chalk out
          well-defined and focused strategies in a bid to minimize the time to
          returns and maximize ROI.
        </h2>
        <button
          onClick={popHandler}
          className="bg-black hover:bg-white hover:text-black transition-all duration-300 text-white rounded-full text-[18px] text-center px-8 py-4 font-[700]"
        >
          Inquire Now
        </button>
      </div>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
    </div>
  );
}

export default DigitalMarInQuary