import React, { useState } from "react";
import Heading from "./Heading";
import Accordion from "./Accordion";
import FreeQuote from "../pages/FreeQuotes/FreeQuote";

const FAQ = ({
  title,
  title1,
  title2,
  title3,
  title4,
  paragraph,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  };
  return (
    <>
      <div className="my-[3%] mx-[6%]">
        <div className="flex flex-wrap flex-col md:flex-row justify-between ">
          <Heading content="Frequently Asked Questions" />
          <button
            onClick={popHandler}
            className="rounded-full my-[5%] md:my-0 w-[180px] h-14 mx-auto md:mx-0 items-center  text-[18px] font-semibold bg-yellow-400"
          >
            Contact US
          </button>
        </div>
        <div className=" mt-[2%] shadow-lg shadow-gray-100 border rounded-[25px]">
          <Accordion title={title}>
            <p>{paragraph}</p>
          </Accordion>
          <Accordion title={title1}>
            <p>{paragraph1}</p>
          </Accordion>
          <Accordion title={title2}>
            <p>{paragraph2}</p>{" "}
          </Accordion>
          <Accordion title={title3}>
            <p>{paragraph3}</p>{" "}
          </Accordion>
          <Accordion title={title4}>
            <p>{paragraph4}</p>{" "}
          </Accordion>
        </div>
      </div>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
    </>
  );
};

export default FAQ;
