import React from "react";
import Navbar from "../NavBar/Navbar";
import SocialMediaMarkectingHome from "./SocialMediaMarkectingHome";
import Services from "./Services";
import SocialMediaBlog from "./SocialMediaBlog";
import FormHeroSection from "../../components/FormHeroSection";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import SocialServicesChecklist from "./SocialServicesChecklist";

const SocialMediaMarkecting = () => {
  return (
    <>
      <Navbar />
      <SocialMediaMarkectingHome />
      <Services/>
      <SocialMediaBlog />
      <FormHeroSection/>
      <SocialServicesChecklist/>
      <FAQ
        title="What is social media marketing?"
        title1="Why is social media marketing important for my business?"
        title2="What are the benefits of social media marketing?"
        title3="What types of social media content should I be posting?"
        title4="How do I measure the success of my social media campaigns?"
        paragraph="Social media marketing is the practice of using social media platforms, such as Facebook, Twitter, Instagram, and LinkedIn, to promote a business or product and connect with customers. It involves creating and sharing content, engaging with followers, and running paid advertising campaigns."
        paragraph1="Social media marketing is important because it allows you to reach a large audience, build brand awareness, and establish a strong relationship with your customers. It also provides valuable insights into your target audience's behavior and preferences, which you can use to inform your marketing strategy."
        paragraph2="Social media marketing offers several benefits, including increased brand awareness, improved customer engagement, and higher website traffic and conversions. It also provides a cost-effective way to reach a large audience and build a community around your brand."
        paragraph3="The types of social media content you should be posting depend on your audience and business goals. Generally, you should aim to provide value to your audience through educational or entertaining content, showcase your products or services, and engage with your followers through comments and conversations."
        paragraph4="To measure the success of your social media campaigns, you should track key metrics such as reach, engagement, website traffic, and conversions. You can use tools such as Google Analytics and social media analytics to gather data and analyze the performance of your campaigns."
      />
      <Footer />
    </>
  );
};

export default SocialMediaMarkecting;
