import React from "react";

const AboutCart = ({ Icon, cartHeading, cartContent, background }) => {
  return (
    <div className="shadow-md cursor-pointer  hover:!blur-none group-hover:scale-[0.85] hover:!scale-100 duration-500 bg-slate-50 p-[32px]  box-border rounded-[28px] border-[1px]  flex flex-col content-evenly justify-center ">
      <div
        style={{ background }}
        className="flex justify-center mx-auto md:mx-0 items-center text-center rounded-[9px] w-[60px] h-[60px] bg-[#FFDA10]"
      >
        <span className="w-[37px] flex  h-[37px] m-[13px]">
          <img src={Icon} alt="" />
        </span>
      </div>
      <h1 className=" font-[500] md:font-[700] text-center md:text-start leading-[41px] md:leading-[56px] text-[25px] tracking-[0.02em] md:tracking-[-0.02em] uppercase text-[#00000f]">
        {cartHeading}
      </h1>
      <p className="w-[250px] md:w-[311px] text-justify  font-[300px] text-[16px] leading-[28px] text-[#000000] ">
        {cartContent}
      </p>
    </div>
  );
};

export default AboutCart;
