import React from "react";
import Heading from "../../components/Heading";
import SoftwareDevCostCart from "./SoftwareDevCostCart";

const SoftwareAbout = () => {
  return (
    <div className="mx-[7%] text-center py-[1%] my-[3%]">
      <div className="py-[3%]">
        <Heading content="Custom Software Development Company" />
      </div>
      <p className=" text-[18px] text-justify m-[1%] font-[500] text-slate-800">
        As a prominent software development company, we have a proven track
        record of providing exceptional assistance to brands across various
        industries worldwide. With our years of experience in software
        development and advanced technology, we cater to a complete set of
        services, including designers, developers, advisors, managers, and
        analysts, delivering unparalleled software development solutions that
        align with your business needs. We have successfully served enterprises
        across several industries, such as logistics and transport, healthcare,
        e-learning, travel, manufacturing, and more. Our focus on delivering
        tailored software solutions has allowed us to unlatch the full potential
        of businesses.
      </p>

      <div>
        <SoftwareDevCostCart />
      </div>
    </div>
  );
};

export default SoftwareAbout;
