import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";

const NavLinks = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link, index) => (
        <div key={index} className="">
          <div className="px-3  md:cursor-pointer group  ">
            <div
              className=" flex justify-between items-center md:pr-0 pr-5 group  "
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.name}
              <span className="text-xl md:hidden ">
                <ion-icon
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                ></ion-icon>
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 ">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </div>
            {link.submenu && (
              <div className="">
                <div className=" absolute ml-[-15%]  hidden group-hover:md:block hover:md:block  ">
                  {/* <div className="text-black bg-white pt-3 font-[700]  ">
                   <div className="flex items-center justify-center">
                   <div className="border-t border-b-2 border-yellow-500 w-1/2 mx-2"></div>
                    <div className="mx-2 whitespace-nowrap "> {link.name}</div>
                    <div className="border-t border-b-2 border-yellow-500 w-1/2 mx-2"></div>
                   </div>
                  </div> */}
                  <div className="bg-whicte bg-[white]  grid grid-cols-2  border-b-8 shadow-md shadow-black  rounded-[8px] border-t-0 border-r-0 border-l-0 border-[#FFDB16] border-[2px] py-[]">
                    {link.sublinks.map((mysublinks, sIndex) => (
                      <div key={sIndex} className="mx-8">
                        <li className="font-bold text-lg  py-2  text-black ">
                          {mysublinks.Head}
                        </li>
                        {mysublinks.sublink.map((slink, ssIndex) => (
                          <li
                            key={ssIndex}
                            className="text-base font-[500] p-[2px] pb-2 text-[#190c0c] "
                          >
                            <Link
                              to={slink.link}
                              className="hover:text-primary"
                            >
                              {slink.name}
                            </Link>
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks, sssIndex) => (
              <div key={sssIndex}>
                <div>
                  <div
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                    }
                    className="py-2 pl-3 text-lg  flex justify-between items-center md:pr-0 pr-5"
                  >
                    {slinks.Head}

                    <span className=" md:mt-1 md:ml-2 inline mr-[3.5%]">
                      <ion-icon
                        name={`${
                          subHeading === slinks.Head
                            ? "chevron-up"
                            : "chevron-down"
                        }`}
                      ></ion-icon>
                    </span>
                  </div>
                  <div
                    className={` ${
                      subHeading === slinks.Head ? "md:hidden" : "hidden"
                    }`}
                  >
                    {slinks.sublink.map((slink, s1Index) => (
                      <li key={s1Index} className="py-2  text-lg pl-7 ">
                        <Link to={slink.link}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
