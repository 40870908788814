import React from "react";

const SubHeading = ({ SubHeading }) => {
  return (
    <div className="text-justify md:text-center font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px] ">
      {SubHeading}
    </div>
  );
};

export default SubHeading;
