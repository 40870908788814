import React, { useRef } from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";
import { ImCross } from "react-icons/im";
import { FaQuoteLeft, FaQuoteRight, FaGoogle } from "react-icons/fa";
import Form from "./Form";
import Rating from "../ClientFeedback/Rating";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Autoplay } from "swiper";
import { Link } from "react-router-dom";
const FreeQuote = ({ popHandler }) => {
  const swiperRef = useRef(null);

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  };
  return (
    <div className="z-[6] w-[100vw] h-screen rounded-[4px]  border-[1px] border-gray-500  bg-gradient-to-b from-[#45547c] to-[#2b344c] bg-[#800080]   shadow-[#FFFFFF29] absolute top-0">
      <div className="flex justify-between content-center px-[3%]  md:px-[3%]  md:mx-[4%]">
       
        <Link to="/">
          {" "}
          <img
            src="/Images/abscodwhite.webp"
            alt="logo"
            width="160px"
            height="160px"
            className="md:cursor-pointer object-cover"
          />
        </Link>
        <div className=" hidden sm:block text-white items-center  text-center">
          <Heading content={"Want to discuss your idea?"} />
          <SubHeading
            SubHeading={
              "We are too! Fill out the form below and we'll contact you very shortly."
            }
          />
        </div>
        <button
          className="mx-[1%] m-auto rounded-full h-10 w-10 bg-[#FFDB16] "
          onClick={popHandler}
        >
          <ImCross className="text-black mx-auto " />
        </button>
      </div>

      <div className="md:flex justify-evenly  pt-1 ">
        <div className=" hidden sm:block py-[1%] bg-gradient-to-b from-[#45547c] via-transparent  to-transparent mx-auto  md:mx-[0px] md:px-[25px] text-[#ffffff]  md:w-[18%] ">
          <h1 className="mb-[10px] text-[18px] text-center uppercase  tracking-[-0.02em] ">
            Testimonials
          </h1>
          <div className="mb-[10px] flex justify-center">
            <img src="/Images/freequimg.png" alt="" />
          </div>
          <div className="mb-[10px] flex justify-between py-[3%]">
            <span>
              <FaQuoteLeft />
            </span>
            <span>
              <FaGoogle />
            </span>
          </div>
          <Swiper
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            loop={true}
            autoplay={{ delay: 1500 }}
            breakpoints={{ slidesPerView: 1 }}
            modules={[Autoplay]}
            className="mySwiper"
            onSwiper={handleSwiper}
          >
            <SwiperSlide>
              <p className="mb-[10px]">
                Abscod has been an excellent partner for our digital marketing
                needs. Their team is responsive, knowledgeable, and has a deep
                understanding of our industry. They have helped us achieve great
                results with their creative campaign and data-driven approach.
                <FaQuoteRight />
              </p>

              <Rating />
              <div className="flex my-[10px]">
                <img
                  src="/Images/client3.png"
                  alt=""
                  width="72px"
                  height="54px"
                />
                <div className="flex flex-col text-center mx-[5px]">
                  <h1 className=" md:font-[700] text-[20px] leading-[110%]">
                    Gaurva Munjal
                  </h1>
                  <p className="md:font-[500] text-[16px]  leading-[110%]">
                    Cred
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <p className="mb-[10px] ">
                We had a complex software development project that Abscod was
                able to handle with ease. Their team is highly skilled and able
                to handle even the most challenging projects. They communicated
                with us throughout the process that exceeded our expectations.
                <FaQuoteRight />
              </p>
              <Rating />
              <div className="flex my-[10px]">
                <img
                  src="/Images/client1.png"
                  alt=""
                  width="72px"
                  height="54px"
                />
                <div className="flex flex-col text-center mx-[5px]">
                  <h1 className=" md:font-[700] text-[20px] leading-[110%]">
                    Aditya
                  </h1>
                  <p className="md:font-[500] text-[16px]  leading-[110%]">
                    Shape me Now
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <p className="mb-[10px]  ">
                Abscod developed a beautiful and functional website for our
                business. Their team is detail-oriented and really understood
                our brand and target audience. The end result was a website that
                is easy to navigate and has helped us drive more leads and
                sales.
                <FaQuoteRight />
              </p>
              <Rating />
              <div className="flex my-[10px]">
                <img
                  src="/Images/client2.png"
                  alt=""
                  width="72px"
                  height="54px"
                />
                <div className="flex flex-col text-center mx-[5px]">
                  <h1 className=" md:font-[700] text-[20px] leading-[110%]">
                    Abhik Nandi
                  </h1>
                  <p className="md:font-[500] text-[16px]  leading-[110%]">
                    Abhik_Nandi Pvt. Ltd.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="md:w-[50%] mt-[5%] border-b-0 border-t-4 border-r-0 border-l-0 border-[#000000] border-[2px] bg-white my-[5%] md:mt-[0px] bg-[#fffff]  md:py-[10px]  flex  justify-center">
          <Form />
        </div>

        <div className="hidden sm:block bg-gradient-to-b from-[#45547c] via-transparent  to-transparent  p-[1%] md:w-[18%] ">
          <h1 className="uppercase tracking-[-0.02em]  text-[#ffffff] text-[18px]  text-center">
            <Rating /> AWARDS AND RECOGNITION
          </h1>
          <div className="  flex flex-col items-center mt-[5%] text-[#ffffff] ">
            <img className="" src="/Images/freeqimg1.png" alt="" width="60%" />
            <img className="" src="/Images/freeqimg1.png" alt="" width="60%" />
            <img className="" src="/Images/freeqimg1.png" alt="" width="60%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeQuote;
