import React from "react";

const CustomerCart = ({ img, content, value, maxCount, add }) => {
  return (
    <div className="text-white my-[10px]  ">
      <div className=" md:flex">
        <div className="flex mx-auto w-20  h-20 ">
          <img src={img} alt="" className=" object-cover" />
        </div>
        <div>
          <h1 className=" mt-[8px] font-[500] md:font-[900] text-[28px] leading-[22px]  md:text-[41px] md:leading-[46px] text-center flex justify-center ">
            {value}
            <b className="ml-[3%]">{add}</b>
          </h1>
          <p className=" w-[170px]  md:w-[200px] text-[16px] md:text-[20px] md:leading-[28px] text-center ">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerCart;
