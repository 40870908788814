import React from "react";


const HeroSection = ({ heading, subheading, deatailhedaing, img,background, bgColor,button }) => {
  return (
    <div style={{ background }}>
      <div className="md:py-[20%] px-[3%] md:px-0 lg:py-[10%] pt-[30%] pb-[10%] md:pb-[5%] lg:pb-[15%]  h-full flex flex-wrap  lg:flex-row lg:flex-nowrap md:justify-evenly ">
        <div className="py-[2%] flex flex-col    md:mx-[10%] lg:mx-[5.5%] md:justify-center w-full lg:w-[60%]  ">
          <h1 className="text-[#FFDB16] pb-[2%]  tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
            {heading}
          </h1>
          <p className=" tracking-[0.02em] pb-[2%] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
            {subheading}
          </p>
          <p className="text-[#EEF2F6] md:mr-[6%] pb-[2%] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
            {deatailhedaing}
          </p>
        </div>
        <div className=" lg:mr-[3%] md:mt-[2%] lg:mt-[2%]  h-[100%]  lg:w-[40%]">
          <img src={img} alt="" className="object-cover" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
