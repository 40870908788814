import React, { useState, useEffect } from "react";

function Counter(props) {
  const [count, setCount] = useState(0);
  const { maxCount } = props;

  useEffect(() => {
    let interval = null;
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(interval);
      } else {
        interval = setInterval(() => {
          if (count < maxCount) {
            setCount(count + 1);
          } else {
            clearInterval(interval);
          }
        }, 10);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    handleVisibilityChange();
    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [count, maxCount]);

  return (
    <div>
      <h1>{count}</h1>
    </div>
  );
}

export default Counter;
