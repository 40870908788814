import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import Heading from "../../components/Heading";
const AllInOne = () => {
  return (
    <div name="platforms" className="w-full my-32">
      <div className="max-w-[1240px] mx-auto px-2">
        <span className="text-center">
          <Heading content="All-In-One Platform" />
        </span>
        <p className="text-xl md:text-2xl py-8 text-gray-500 text-justify lg:text-center">
          With our comprehensive platform, you can manage all aspects of your
          marketing strategy in one place, from SEO and social media to email
          marketing and PPC advertising.
        </p>

        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 pt-4">
          
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Email Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Send email notifications to your subscribers and customers about
                new products, promotions, and other updates.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">SMS Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Send SMS notifications to customers who have opted in to receive
                updates from your business.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Push Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Send push notifications to users who have installed your mobile
                app, notifying them about new content, promotions, and other
                updates.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">In-App Notification</h3>
              <p className="text-lg pt-2 pb-4">
                Send in-app notifications to users who have downloaded your
                mobile app, keeping them engaged with your brand and encouraging
                them to take action.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Browser Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Send browser notifications to users who have opted in to receive
                updates from your website, notifying them about new content,
                promotions, and other updates.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Social Media Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Use social media notifications to alert followers about new
                content, promotions, and other updates.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107] " />
            </div>
            <div>
              <h3 className="font-bold text-lg">Automated Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Use automated notifications to send reminders, confirmations,
                and other messages to customers who have interacted with your
                business.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle className="w-7 mr-4 text-[#ffc107]" />
            </div>
            <div>
              <h3 className="font-bold text-lg">Custom Notifications</h3>
              <p className="text-lg pt-2 pb-4">
                Create custom notifications tailored to your specific business
                needs, such as abandoned cart reminders, product updates, and
                more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
