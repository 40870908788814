import React from "react";

const Uxuiservices = () => {
  return (
    <div className="px-[6%] mt-[5%] pb-[6%]">
      <div>
        <h1 className=" text-[25px] font-[500] md:text-[3.5rem] md:font-[400] text-justify md:text-center leading-[1.2]  text-[black] ">
          Our UI UX Design Services
        </h1>
        <p className="md:text-[1.5rem] md:font-[300] text-justify md:text-center leading-[1.2]  text-[black] ">
          A best-in-class mixture of technology and art in our projects
          captivates you with elegance and style of visuals, sounds, contents,
          and interactions in our solutions.
        </p>
      </div>
      <div className="grid md:grid-cols-2  grid-cols-1 md:gap-5 gap-2 mt-[3%]">
        <div className="border-[2px]   rounded-[12px] mt-[10%]">
          <div className="border-[2px] bg-amber-50 text-center font-[500]  text-[24px] mt-[-7%]  md:ml-[7%] py-[4%] px-[3%] rounded-[30px] max-w-[450px]">
            Web Design & Development
          </div>
          <p className="m-[5%]">
            We provide customized and modernized website solutions that are
            tailored and designed for your services and products, to suit your
            needs and fulfil organizational objectives. Our creative designers
            offer a bountiful of smart UI and UX designs, keeping in mind your
            requirements.
          </p>
        </div>
        <div className="border-[2px]   rounded-[12px] mt-[10%]">
          <div className="border-[2px] bg-amber-50 text-center font-[500]  text-[24px] mt-[-7%]  md:ml-[7%]   py-[4%] px-[3%] rounded-[30px] max-w-[450px]">
            Corporate Branding
          </div>
          <p className="m-[5%]">
            Our skilled designers understand the value of corporate branding and
            ensure the finest of corporate graphic designs that encompasses
            creating and developing the brand’s graphical identity. The design
            elements showcase a visual presentation of the brand, making the
            organizational ideals noticeable.
          </p>
        </div>
        <div className="border-[2px]   rounded-[12px] mt-[10%]">
          <div className="border-[2px] bg-amber-50 text-center font-[500]  text-[24px] mt-[-7%]  md:ml-[7%] py-[4%] px-[3%] rounded-[30px] max-w-[450px]">
            Wireframing
          </div>
          <p className="m-[5%]">
            Keeping in mind user requirements, our designing team offers an
            overview of products determining the flow and structure of design
            elements. These wireframes assist teams in creating effective
            prototypes and going through the structure of the website without
            getting distracted by other elements.
          </p>
        </div>
        <div className="border-[2px]   rounded-[12px] mt-[10%]">
          <div className="border-[2px] bg-amber-50 text-center font-[500]  text-[24px] mt-[-7%]  md:ml-[7%] py-[4%] px-[3%] rounded-[30px] max-w-[450px]">
            Mobile App Design
          </div>
          <p className="m-[5%]">
            We ensure a perfect look and feel of mobile apps consisting of good
            quality user interface and user experience elements. Our designers
            offer top-notch ideas to create mobile app designs that have faster
            loading, aesthetic look, simplistic usage, and enhanced customer
            satisfaction.
          </p>
        </div>
        <div className="border-[2px]   rounded-[12px] mt-[10%]">
          <div className="border-[2px] bg-amber-50 text-center font-[500]  text-[24px] mt-[-7%]  md:ml-[7%] py-[4%] px-[3%] rounded-[30px] max-w-[450px]">
            Wearables App Design
          </div>
          <p className="m-[5%]">
            Designing and creation of enriched wearable app designs for your
            wearables, devices, and gadgets is what our team excels at. We think
            out-of-the-box to bring to you, a variety of interfaces and
            experiences that work best with your smartwatches, fitness trackers,
            smart jewelry, head-mounted displays etc.
          </p>
        </div>
        <div className="border-[2px]   rounded-[12px] mt-[10%]">
          <div className="border-[2px] bg-amber-50 text-center font-[500]  text-[24px] mt-[-7%]  md:ml-[7%] py-[4%] px-[3%] rounded-[30px] max-w-[450px]">
            Product Design
          </div>
          <p className="m-[5%]">
            We understand user expectations and as a holistic idea, we imagine,
            create and design products that can address industry-specific needs.
            A product design includes all UI/UX-related aspects that offer a
            creative, authentic, and unique experience to create a stronger
            brand loyalty.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Uxuiservices;
