import React, { useState} from "react";
import FreeQuote from "../pages/FreeQuotes/FreeQuote";
import Button from "../pages/NavBar/Button";

const UiuxDesignProcess = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  };
  return (
    <div>
      <div>
        {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
        <div className="p-[6%] md:p-[1%] lg:px-[5%] lg:py-1 ">
          <div>
            <p className="text-[2.5rem] pb-[1%]  text-center font-[500] leading-[1.2]">
              {" "}
              Our UI UX Design Process
            </p>

            <p className=" mb-[3%] text-justify font-[500]">
              Creativity, customer focus, and technological innovation at its
              finest with well-defined design processes at SPEC INDIA. With a
              unique blend of modern technologies and innovative approaches, we
              create responsive and adaptive designs with uniform cross-platform
              compatibility.
            </p>
          </div>

          <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-0 gap-10">
            <div className="p-8 md:border-l-0 shadow-lg md:shadow-none md:border-t-0 py-[10%] md:border-[1px]">
              <div className="flex justify-between">
                <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
                  Product Definition
                </h2>
                <p className="text-5xl font-[900] text-[#ddecd5] ">01</p>
              </div>
              <p className="mb-[16px] text-justify font-[400] text-[18px]">
                Before creating a product, it is important to perceive the
                entire context and create a firm base for the product. All
                involved stakeholders get involved and create an outline with
                team structure, communication methods, user expectations.
              </p>
            </div>
            <div className="p-8 md:border-t-0 shadow-lg md:shadow-none md:border-[1px] py-[10%]">
              <div className="flex justify-between">
                <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
                  Research
                </h2>
                <p className="text-5xl font-[900] text-[#fff3cc]">02</p>
              </div>
              <p className="mb-[16px] text-justify font-[400] text-[18px]">
                Once the product definition is done, designers engage themselves
                in researching well with a user and market perspective. Experts
                perform detailed research based on qualitative data collected
                through different mediums as against their complexity, timings,
                resources, and other aspects.
              </p>
            </div>
            <div className="p-8 md:border-t-0 shadow-lg md:shadow-none md:border-r-0 md:border-[1px] py-[10%]">
              <div className="flex justify-between">
                <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
                  Analysis
                </h2>
                <p className="text-5xl font-[900] text-[#f2ccd7]">03</p>
              </div>
              <p className="mb-[16px] text-justify font-[400] text-[18px]">
                It is now time to analyze the insightful information collated
                from data sources in the research phase. Designers get involved
                in creating a look of how the final product will look like,
                creation of user groups, and business workflow involved.
              </p>
            </div>
            <div className="p-8 md:border-l-0 shadow-lg md:shadow-none md:border-[1px] py-[10%]">
              <div className="flex justify-between">
                <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
                  Design
                </h2>
                <p className="text-5xl font-[900] text-[#e0d1f2] ">04</p>
              </div>
              <p className="mb-[16px] text-justify font-[400] text-[18px]">
                Designers get onto the job of designing different components
                right from information architecture to the real user interface
                and user experience design. There is equal participation from
                all involved stakeholders for sketching, creation of wireframes
                and prototypes.
              </p>
            </div>
            <div className="p-8 md:border-[1px] shadow-lg md:shadow-none py-[10%]">
              <div className="flex justify-between">
                <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
                  Validation or Testing
                </h2>
                <p className="text-5xl font-[900] text-[#d1e7f2]">05</p>
              </div>
              <p className="mb-[16px] text-justify font-[400] text-[18px]">
                Once the design is finalized, it is vital to test and validate
                all design components as against user requirements. Feedback
                from testers and end users is important to ascertain if UI/UX
                design is in synchronization with what was expected.
              </p>
            </div>
            <div className="p-8 border-[1px] shadow-lg md:shadow-none md:border-r-0 py-[10%]">
              <div className="flex justify-between">
                <h2 className="text-[20px] my-[20px] font-[600] leading-[1.5]">
                  Launch
                </h2>
                <p className="text-5xl font-[900] text-[#e9d8d4] ">06</p>
              </div>
              <p className="mb-[16px] text-justify font-[400] text-[18px]">
                Once your product is completely tested, it is time to launch it
                further to the development team for the creation of UI/UX. There
                could be different levels of testing like user testing, internal
                testing, etc. that can be carried out simultaneously.
              </p>
            </div>
          </div>
          <div className="lg:w-[20%] my-[3%] mx-auto">
            <Button
              onClick={popHandler}
              content="Request A Quote"
              color="black"
              background="linear-gradient(to right, #FFDB16, #FFDB16"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiuxDesignProcess;
