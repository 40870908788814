import React from "react";
import Navbar from "../NavBar/Navbar";
import DigitalMarketingHome from "./DigitalMarketingHome";
import FormHeroSection from "../../components/FormHeroSection";
import DigitalMarInQuary from "./DigitalMarInQuary";
import About from "./About";
import AllInOne from "./AllInOne";
import Support from "./Support";
import Plan from "../../components/Plan";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";

function DigitalMArketing() {
  return (
    <>
      <Navbar />
      <DigitalMarketingHome />
      <FormHeroSection/>
      <DigitalMarInQuary/>
      <Support />
      <AllInOne />
      <About />
      <Plan
        planHeading="TRANSPARENT PRICING FOR OUR QUALITY SERVICES"
        planSubheding="Check Our Service Charges Now"
        user1="Basic Plan"
        Strorage="15 Keyword Optimization"
        userAcces="Off-Page SEO"
        dataCapcity="15 creatives & 10 Reel for social media"
        user2="Advanced Plan"
        user3="Premium Plan"
        Strorage2="25 Keyword Optimization"
        Strorage3="35 Keyword Optimization"
        userAcces2="Local SEO"
        userAcces3="Google PPC"
        dataCapcity2="15 creatives & 10 Reel for social media"
        dataCapcity3="20 creatives & 20 Reel for social media"
      />
      <FAQ
        title="Is Expand My Business a digital marketing company?"
        title1="Why should I choose Expand My Business for Digital Marketing Services?"
        title2="How are your digital marketing services different from others in the industry?"
        title3="What are the benefits of digital marketing?"
        title4="What digital marketing tactics should I use for my business?"
        paragraph="One of
        our key service offering verticals is digital marketing. We offer
        digital marketing services by helping you scope your work and find
        the best-suited agency partner to work on your project
        requirements."
        paragraph1="  Furthermore, we help you choose the right
        agency and assist you with end-to-end delivery management while
        assuring complete payment security through our milestones-based
        Escrow Payment Solutions."
        paragraph2="Many factors set us apart from others in the industry. We are
        Asia’s biggest managed marketplace with1500+ verified partner
        agencies. The
        dedicated delivery managers are another added benefit, as they
        take care of the end-to-end delivery management of your email
        marketing campaign."
        paragraph3="The benefits of digital marketing include increased website 
        traffic, improved search engine rankings, higher engagement and social media 
        followers, increased leads and conversions, and greater brand awareness and 
        recognition. It also provides a cost-effective way to promote your business and
         reach a large audience."
        paragraph4="The digital marketing tactics you should use depend on your business
         goals and target audience. Some effective tactics include search engine optimization
          (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and
           content marketing. It's important to have a strategy in place that utilizes the tactics 
           that are most relevant to your business."
      />
      <Footer />
    </>
  );
}

export default DigitalMArketing;
