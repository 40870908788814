import React from "react";

const SEOWork = () => {
  const images = [
    {
      src: "/Images/DigitalMarketing1.png",
      alt: "Image 1",
      name: "50+",
      definition: "Digital Marketing Experts",
    },
    {
      src: "/Images/YearsofExperience.png",
      alt: "Image 2",
      name: "3+",
      definition: "Years of Experience",
    },
    {
      src: "/Images/IncreaseTrafficAnnually.png",
      alt: "Image 3",
      name: "82%",
      definition: "Increase in Traffic Annually",
    },
    {
      src: "/Images/Feedbacks.png",
      alt: "Image 4",
      name: "99%",
      definition: "Positive Feedbacks",
    },
  ];
  return (
    <div>
      <div className="px-[6%] py-[5%] bg-white">
        <ul className="grid lg:grid-cols-4 grid-cols-2  md:grid-cols-2 lg:gap-4 lg:leading-[1.2]">
          {images.map((image) => (
            <li key={image.id} className=" flex lg:flex-row flex-col my-3  ">
              <img
                src={image.src}
                alt={image.alt}
                className="w-[60px] object-cover lg:mx-0 mx-auto"
              />
              <div className="lg:ml-4 lg:mx-0 mx-auto">
                <h1 className=" text-[28px] text-center font-sans font-poppins md:text-center lg:text-start   font-[700]  mb-1">
                  {image.name}
                </h1>
                <p className="text-[18px] text-center font-[400]">
                  {image.definition}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SEOWork;
