import React, { useState } from "react";
import Navbar from "../NavBar/Navbar";
import Footer from "../Footer/Footer";
import FormHeroSection from "../../components/FormHeroSection";
import FreeQuote from "../FreeQuotes/FreeQuote";
import FAQ from "../../components/FAQ";
import UxuidesignPrivious from "../../components/UxuidesignPrivious";
import UiuxDesignProcess from "../../components/UiuxDesignProcess";
import Uxuiservices from "../../components/Uxuiservices";
const UxUiDesign = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    setShowPopup(!showPopup);
  };
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url("/Images/UXUI.png")`,
          backgroundSize: "cover",
        }}
      >
        {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
        <div className="bg-transparent py-[5%] px-[2%] h-[96vh] lg:pb-[18%] md:pb-[20%] pb-0 ">
          <div className="md:w-7/12 md:mt-[3%]  py-20 px-4 sm:px-6 lg:px-8">
            <h1 className="text-[#FFDB16] pb-[2%]  tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
              Transform Your Website with Expert UX/UI Design
            </h1>
            <p className=" tracking-[0.02em] pb-[2%] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
              Enhance User Experience
            </p>
            <p className="text-[#EEF2F6] md:mr-[6%] pb-[2%] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
              At our company, we offer comprehensive software development
              services to help businesses their unique needs and requirements.
              Craft engaging experiences with intuitive user interfaces Elevate
              your website with custom-designed user experiences Simplify user
              interactions with streamlined UX/UI design User-centered design
              for better engagement and conversions
            </p>
           
            <div className="text-center mt-[3%]">
              <button
                onClick={() => setShowPopup(!showPopup)}
                className=" uppercase  bg-transparent text-white hover:text-black transition-[0.5s] hover:bg-[#FFDB16] border-[2px] border-[#FF9800] font-[700] py-3 px-10  rounded-full focus:outline-none focus:shadow-outline"
                type="submit"
              > get started
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormHeroSection />
      <Uxuiservices />
      <UiuxDesignProcess />
      <UxuidesignPrivious />
      <FAQ
        title="What is UI/UX design?"
        title1="Why is UI/UX design important?"
        title2="What are some key elements of good UI/UX design?"
        title3="What is the difference between UI and UX design?"
        title4="How can I improve the UI/UX design of my digital product?"
        paragraph="UI/UX design refers to the process of designing the user interface and user experience of a digital product, such as a website or mobile app."
        paragraph1="UI/UX design is important because it can greatly impact the success of a digital product. A well-designed interface can make it easier for users to navigate and understand a product, leading to increased user satisfaction and engagement."
        paragraph2="Some key elements of good UI/UX design include:
            Clear and intuitive navigation,
            Consistent and visually appealing design,
            User-centered design approach,
            Accessibility and inclusivity,
            Fast load times and responsive design "
        paragraph3="UI design refers to the visual design of a digital product, such as its layout, color scheme, and typography. UX design, on the other hand, refers to the overall experience of using the product, including factors such as usability, accessibility, and user satisfaction."
        paragraph4="There are several ways to improve the UI/UX design of a digital product, including:
                Conducting user research to better understand user needs and behaviors,
                Creating user personas to guide design decisions,
                Conducting usability testing to identify areas for improvement,
                Following established design principles and best practices,
                Continuously iterating and improving the design based on user feedback."
      />
      <Footer />
    </>
  );
};

export default UxUiDesign;
