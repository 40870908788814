import React from "react";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import AboutCart from "./carts/AboutCart";

const About = () => {
  return (
    <>
      <div className="py-[5%]  overflow-hidden bg-gradient-to-b from-gray-50 to-transparent ">
        <div className="flex flex-col justify-center text-center text-[#1a1818] py-[3%] mx-4 md:mx-[5%]">
          <Heading content={" Who we are"} />
          <div className="bg-gradient-to-r from-yellow-500 via-purple-500 to-black w-40 mx-auto h-2 rounded-full mt-[2%] "></div>
          <span className="md:text-center mt-[2%] text-justify">
            {" "}
            <SubHeading
              SubHeading={
                "Our core services include mobile app development, web development, digital marketing, and software development, and we pride ourselves on delivering high-quality solutions that meet our clients' needs and exceed their expectations."
              }
            />
          </span>
        </div>
        <div className=" md:mt-[5px] flex  justify-evenly content-evenly items-center flex-wrap lg:flex-nowrap gap-5 md:mx-[5%] ">
          <div className="gup  flex flex-col justify-center content-center md:justify-between md:content-between">
            <div className="mb-[30px] flex ">
              <AboutCart
                Icon="/Images/mission.png"
                background="linear-gradient(180deg, #3DA6E0 0%, #406CD7 100%)"
                cartContent={
                  "Our mission is centered on delivering groundbreaking and dependable solutions that enable businesses to attain their objectives"
                }
                cartHeading={"our mission"}
              />
            </div>
            <AboutCart
              background="linear-gradient(180deg, #3DA6E0 0%, #406CD7 100%)"
              Icon="/Images/values.png"
              cartContent={
                " Upholding honesty and ethical behavior in all aspects of business, and building trust with customers, partners, and stakeholders."
              }
              cartHeading={"Our values"}
            />
          </div>
          <div className="hidden sm:block">
            <img
              src="/Images/about1.png"
              alt=""
              className=" h-[590px] object-cover my-[30px] lg:my-0 rounded-[28px]"
            />
          </div>
          <div className="gup  flex flex-col justify-center content-center md:justify-between md:content-between">
            <div className="mb-[30px] flex ">
              <AboutCart
                Icon="/Images/teamIcon.png"
                background="linear-gradient(180deg, #3DA6E0 0%, #406CD7 100%)"
                cartContent={
                  "Fostering a culture of teamwork, integrity, and open communication, while providing each other with assistance and encouragement."
                }
                cartHeading={"Our team"}
              />
            </div>
            <AboutCart
              Icon="/Images/acheivement.png"
              background="linear-gradient(180deg, #3DA6E0 0%, #406CD7 100%)"
              cartContent={
                " Our company has established a strong reputation in the industry and has become a trusted partner for businesses  ."
              }
              cartHeading={"Our achievements"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
