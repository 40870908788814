import React from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";

const MobileFeature = () => {
  return (
    <div className="bg-[#e87a3b]">
      <div className=" mx-[7%] py-[5%]">
        <span className="text-center text-white">
          <Heading content="Apps for all devices and platforms" />
        </span>
        <div className="my-[1%] text-center text-white">
          <SubHeading
            SubHeading="Be it Phones, Tablets, wearable or TV, we hold excellent experience in making apps
             compataible for all modern platforms."
          />
        </div>

        <div className="flex items-center flex-wrap md:flex-nowrap py-[3%] ">
          <div className=" w-[400px] md:ml-4 pt-4 md:pt-0 text-center text-white">
            <img
              src="/Images/mobile1.png"
              alt=""
              className="text-center  object-cover mx-auto h-32"
            />
            <h1 className="mt-[2%] font-medium text-[18px]">Mobile</h1>
            <p>
              India has close to 260 million smart phone users. We understand
              best, how imprtant is it for your app to perform good for such
              huge user base.
            </p>
          </div>
          <div className="pt-4 md:pt-0 w-[400px] md:ml-4 text-center text-white">
            <img
              src="/Images/tablet1.png"
              alt=""
              className="text-center object-cover mx-auto h-32"
            />
            <h1 className="mt-[2%] font-medium text-[18px]">Tablet</h1>
            <p>
              We analyse product specifications & its user-basequickly and
              deploy best tools and techniques to make apps compatible to
              tables.
            </p>
          </div>
          <div className="pt-4 md:pt-0 w-[400px] md:ml-4 text-center text-white">
            <img
              src="/Images/desktop1.png"
              alt=""
              className="text-center object-cover mx-auto h-32"
            />
            <h1 className="mt-[2%] font-medium text-[18px]">Wearable</h1>
            <p>
              Smart Objects & Wearable are picking up fast. Our experience of
              making apps for smaller screens make us best fit for your such
              needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFeature;
