import React,{useState} from "react";
import Heading from "./Heading";
import FreeQuote from "../pages/FreeQuotes/FreeQuote";

const Plan = ({
  planHeading,
  planRate,
  planSubheding,
  user1,
  Strorage,
  userAcces,
  dataCapcity,
  user2,
  user3,
  Strorage2,
  Strorage3,
  userAcces2,
  userAcces3,
  dataCapcity2,
  dataCapcity3,
  planRate2,
  planRate3,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    window.scrollTo(0, 0);
    setShowPopup(!showPopup);
  }; 
  return (
    <>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
      <div className="w-full py-[3%] px-4 bg-white">
        <div className="text-center pb-[5%]">
          <Heading content={planHeading} />
          
        </div>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img
              style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
              className="w-20 mx-auto object-cover  mt-[-3rem] rounded-full p-2 bg-white"
              src="/Images/price1.png"
              alt="/"
            />
            <h2 className="text-2xl font-bold text-center py-8">{user1}</h2>
            <p className="text-center text-4xl font-bold">{planRate}</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">{Strorage}</p>
              <p className="py-2 border-b mx-8">{userAcces}</p>
              <p className="py-2 border-b mx-8">{dataCapcity}</p>
              {/* <p className="py-2 border-b mx-8">{userpara1}</p>
              <p className="py-2 border-b mx-8">{dataCap1}</p> */}
            </div>
            <button
              onClick={popHandler}
              className="bg-[#ffc107] w-[200px] rounded-full font-bold my-6 mx-auto px-6 py-3"
            >
              Start Trial
            </button>
          </div>
          <div className="w-full shadow-xl bg-gray-50 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
            <img
              style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
              className="w-20 mx-auto p-2 object-cover rounded-full mt-[-3rem] bg-transparent"
              src="/Images/price2.png"
              alt="/"
            />
            <h2 className="text-2xl font-bold text-center py-8">{user2}</h2>
            <p className="text-center text-4xl font-bold">{planRate2}</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">{Strorage2}</p>
              <p className="py-2 border-b mx-8">{userAcces2}</p>
              <p className="py-2 border-b mx-8">{dataCapcity2}</p>
              {/* <p className="py-2 border-b mx-8">{userpara2}</p>
              <p className="py-2 border-b mx-8">{dataCap2}</p>*/}
            </div>
            <button
              onClick={popHandler}
              className="bg-black text-[#ffffff] w-[200px] rounded-full font-bold my-6 mx-auto px-6 py-3"
            >
              Start Trial
            </button>
          </div>
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img
              style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}
              className="w-20 mx-auto p-2 object-cover rounded-full mt-[-3rem] bg-white"
              src="/Images/price3.png"
              alt="/"
            />
            <h2 className="text-2xl font-bold text-center py-8">{user3}</h2>
            <p className="text-center text-4xl font-bold">{planRate3}</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">{Strorage3}</p>
              <p className="py-2 border-b mx-8">{userAcces3}</p>
              <p className="py-2 border-b mx-8">{dataCapcity3}</p>
              {/* <p className="py-2 border-b mx-8">{userpara3}</p>
              <p className="py-2 border-b mx-8">{dataCap3}</p> */}
            </div>
            <button
              onClick={popHandler}
              className="bg-[#ffc107] w-[200px] rounded-full font-bold my-6 mx-auto px-6 py-3"
            >
              Start Trial
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
