import React from "react";
import Heading from "../../components/Heading";

const SoftwareStackUse = () => {
  return (
    <div className="px-[1%] md:px-[6%] py-[3%] mb-[3%]">
      <div className="text-center mb-[5%] md:mb-[2%]">
        <Heading content="Technology We Expertise" />
        <p className=" text-[18px] text-justify md:text-center px-[3%] m-[1%] font-[500] text-slate-800">
          Abscod, an IT consulting company, stays ahead of the curve by
          continually acquiring insights into both leading and emerging
          technologies. This approach allows us to proactively identify
          solutions that meet the ever-evolving needs of our clients.
        </p>
      </div>
      <div className=" grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-5 mx-[25%]  md:mx-[7%]">
        <div className="w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">SAAS DEVELOPMENT</h1>
        </div>
        <div className="- w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">CRM DEVELOPMENT</h1>
        </div>
        <div className="- w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">BI APPS DEVELOPMENT</h1>
        </div>
        <div className="- w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">INTEGRATION DEVELOPMENT</h1>
        </div>
        <div className="w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">DATABASE DEVELOPMENT</h1>
        </div>
        <div className=" w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">PHP/MYSQL DEVELOPMENT</h1>
        </div>
        <div className=" w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">HTML5 / CSS</h1>
        </div>

        <div className=" w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">MAGENTO ECOMMERCE DEVELOPMENT</h1>
        </div>
        <div className=" w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">MICROSOFT DEVELOPMENT</h1>
        </div>
        <div className="w-48 h-44  text-white font-[900] bg-gradient-to-b from-[#326287] to-[#087dd6]  rounded-[12px] shadow-md  flex justify-center items-center px-2  text-center transition-transform duration-500 ease-in-out transform hover:scale-90">
          <h1 className="">DRUPAL CMS DEVELOPMENT</h1>
        </div>
      </div>
    </div>
  );
};

export default SoftwareStackUse;
