import React from "react";
import Navbar from "../NavBar/Navbar";
import SEOWorkWeProvide from "./SEOWorkWeProvide";
import FAQ from "../../components/FAQ";
import Footer from "../Footer/Footer";
import SEOHome from "./SEOHome";
import SEOWork from "./SEOWork";
import SEOServices from "./SEOServices";
import SEOWeWorkProvide from "./SEOWeWorkProvide";
import SEOAppreciation from "./SEOAppreciation";
import FormHeroSection from "../../components/FormHeroSection"
import Plan from "../../components/Plan";

const SEOPAGE = () => {
  return (
    <>
      <Navbar />
      <SEOHome />
      <SEOWork />
      <FormHeroSection />
      <SEOServices />
      <SEOWeWorkProvide />
      <SEOAppreciation />
      <SEOWorkWeProvide />
      <Plan
        planSubheding="Check Our Service Charges Now"
        user1="Basic Plan"
        Strorage="15 Keyword Optimization"
        userAcces="Off-Page SEO"
        dataCapcity="On-Page SEO"
        user2="Advanced Plan"
        user3="Premium Plan"
        Strorage2="25 Keyword Optimization"
        Strorage3="35 Keyword Optimization"
        userAcces2="Local SEO"
        userAcces3="Google PPC"
        dataCapcity2="On-Page SEO"
        dataCapcity3="On-Page SEO"
      />
      <FAQ
        title="What is SEO and why is it important?"
        title1="How does SEO work?"
        title2="How long does it take to see results from SEO?"
        title3="What is white hat SEO?"
        title4="Can I do SEO myself, or should I hire an agency?"
        paragraph="SEO stands for search engine optimization, which is the practice of optimizing your website to improve its visibility and ranking in search engine results pages (SERPs). This is important because the higher your website ranks in search results, the more traffic it will receive, and the more opportunities you'll have to convert visitors into customers."
        paragraph1="SEO involves a range of techniques, including keyword research, content optimization, backlink building, and technical optimization. By implementing these techniques, you can improve your website's relevance, authority, and overall quality, which can lead to higher search engine rankings and increased traffic."
        paragraph2="SEO is a long-term strategy, and it can take several months to see significant results. However, the time it takes to see results can vary depending on various factors, such as your website's current state, your industry, and the competitiveness of your target keywords"
        paragraph3="White hat SEO refers to ethical SEO techniques that comply with search engine guidelines and focus on providing value to users. Examples of white hat SEO include creating high-quality content, optimizing website structure and navigation, and building quality backlinks."
        paragraph4="While it's possible to do SEO yourself, it can be a complex and time-consuming process, especially if you're new to SEO. Hiring an experienced SEO agency can provide you with the expertise and resources needed to achieve your goals and improve your website's visibility in search results."
      />
      <Footer />
    </>
  );
};

export default SEOPAGE;
