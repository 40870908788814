import React from 'react'

const WebsiteExpeties = () => {
     const images = [
       {
         id: 1,
         src: "/Images/php1.jpg",
         alt: "Image 1",
         name: "php",
       },
       {
         id: 2,
         src: "/Images/net1.jpg",
         alt: "Image 2",
         name: "dot net",
       },
       {
         id: 3,
         src: "/Images/html1.jpg",
         alt: "Image 3",
         name: "html5",
       },
       {
         id: 4,
         src: "/Images/futter.png",
         alt: "Image 4",
         name: "Flutter ",
       },
       {
         id: 5,
         src: "/Images/wordpress.jpg",
         alt: "Image 5",
         name: "wordpress",
       },
       {
         id: 6,
         src: "/Images/magento1.jpg",
         alt: "Image 6",
         name: "magento",
       },
       {
         id: 7,
         src: "/Images/shopify1.jpg",
         alt: "Image 7",
         name: "shopify",
       },
       {
         id: 8,
         src: "/Images/node1.jpg",
         alt: "Image 8",
         name: "node.js",
       },
       {
         id: 9,
         src: "/Images/angular1.jpg",
         alt: "Image 9",
         name: "angular.js",
       },
       {
         id: 10,
         src: "/Images/mysql1.jpg",
         alt: "Image 10",
         name: "mysql",
       },
       {
         id: 11,
         src: "/Images/mssql1.jpg",
         alt: "Image 11",
         name: "mssql",
       },
       {
         id: 12,
         src: "/Images/configure.jpg",
         alt: "Image 12",
         name: "configure.it",
       },
     ];
      
  return (
    <div className="px-[6%] py-[5%]">
      <h1 className="font-[800] text-center text-black mb-[1%] text-[3rem]">
        Our Expertise
      </h1>
      <h1 className="text-center my-5">
        In-House Specialists in Contemporary Server-Side and Front-End
        Technologies
      </h1>
      <div className="my-[5%]">
        <ul className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-4">
          {images.map((image) => (
            <li
              key={image.id}
              className="text-center flex flex-col items-center p-3 border-[2px]"
            >
              <img src={image.src} alt={image.alt} className="w-16 object-contain h-16 mb-5" />
              <h1 className="uppercase">{image.name}</h1>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default WebsiteExpeties