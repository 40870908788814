import React from "react";
import Heading from "../../components/Heading";
import SubHeading from "../../components/SubHeading";
import FeaturesCart from "../WebsiteDevelopment/FeaturesCart";
const Services = () => {
  return (
    <div className="py-[5%]">
      <div className="text-center leading-[28px] mx-[2%] md:mx-[3%] ">
        <Heading content=" Your Brand with Effective Content Marketing Strategies." />
        <div className="my-[1%] mx-[2%]">
          <SubHeading SubHeading="Drive Your Content Marketing Success by Leveraging Effective PPC Strategies to Boost Audience Engagement." />
        </div>
      </div>

      <div className="flex flex-wrap justify-center py-[2%] mx-[5%]">
        <FeaturesCart
          content={
            "Our team of SEO experts can help you improve your website's search engine ranking and drive more traffic to your site. We use the latest SEO strategies to optimize your website."
          }
          img="/Images/DriveTraffic.png"
          heading={"Drive Traffic to Website with Our SEO Service"}
        />
        <FeaturesCart
          content={
            "We have a proficient team of writers and content creators who can assist you in crafting engaging and effective content that speaks to your intended audience blog posts, articles, videos,and more."
          }
          img="/Images/EngageYourAudience.png"
          heading={"Engage Your Audience with Compelling Content"}
        />
        <FeaturesCart
          content={
            "You can expand your audience and drive increased traffic to your website with the help of social media advertising. Our team can create customized social media ad campaigns that target your ideal audience ."
          }
          img="/Images/ExpandYourReach.png"
          heading={"Expand Your Reach with Social Media Advertising"}
        />
        <FeaturesCart
          content={
            "Video marketing is a powerful way to build your brand and engage your audience. Our team can create high-quality videos that showcase your products or services and tell your brand's story."
          }
          img="/Images/BuildYourBrand.png"
          heading={" Build Your Brand with Video Marketing"}
        />
        <FeaturesCart
          content={
            "Our ecommerce marketing services can help you generate more sales and drive revenue growth. We can optimize your ecommerce site for conversions, create targeted ad campaigns, and more."
          }
          img="/Images/GenerateMoreSales.png"
          heading={"Generate More Sales with Ecommerce Marketing"}
        />
        <FeaturesCart
          content={
            "Our branding services can help you develop a unique brand identity that resonates with your target audience. we can create a brand that sets you apart from the competition."
          }
          img="/Images/DevelopBrandIdentity.png"
          heading={"Develop Brand Identity with Branding Services"}
        />
      </div>
    </div>
  );
};

export default Services;
