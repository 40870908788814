import React,{useState} from 'react'
import WebsiteFeatureCart from './WebsiteFeatureCart';
import { BsChevronDown } from "react-icons/bs";


const WebsiteFeature = () => {
  const [hover, setHover] = useState(false);
  return (
    <div className="mt-[15%] lg:mt-[2%]">
      {" "}
      <div className=" bg-[#13152c] px-[5%] py-[5%]">
        <div
          className="w-24 h-24 flex items-center justify-center rounded-full mt-[-18%] md:mt-[-10%] lg:mt-[-9%] mx-auto bg-[#13152c] border-4 border-white
                  hover:scale-[1.02] duration-500 transform hover:rotate-360 cursor-pointer transition-all duration-400 ease-in-out "
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <BsChevronDown
            className={`h-12 w-12  text-white ${
              hover ? "animate-[spin_0.2s_linear]" : ""
            }`}
          />
        </div>
        <div className="text-center text-white mt-[5%] lg:mt-[2%]">
          <h1 className="font-[800]  lg:mb-[20px] text-[2rem] md:text-[2.5rem] lg:text-[3rem]">
            Web Development Features
          </h1>
          <h1>Fine Design Doesn't Just Happen, We make it happen!</h1>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-[3%] gap-7">
          <WebsiteFeatureCart
            img={"/Images/reponsive-web-design.png"}
            title="We strive to ensure that your website looks great across different devices and offers best user experience."
            heading="RESPONSIVE WEBSITES"
          />
          <WebsiteFeatureCart
            img={"/Images/seo-friendly.png"}
            title="With our SEO-friendly approach, your website will rank higher in search engine results, driving more traffic and increasing your online presence."
            heading="SEO FRIENDLY"
          />
          <WebsiteFeatureCart
            img={"/Images/chat-social-integration.png"}
            title="Chat integration allows you to offer real-time support to your users, helping them quickly resolve any issues or queries they may have."
            heading="CHAT & SOCIAL INTEGRATION"
          />
          <WebsiteFeatureCart
            img={"/Images/inquiry-form-integration.png"}
            title="One effective way to achieve this is by integrating an inquiry form into your website. This allows your users to easily submit inquiries or requests ."
            heading="INQUIRY FORM INTEGRATION"
          />
          <WebsiteFeatureCart
            img={"/Images/blog-integration.png"}
            title=" By integrating a blog into your website, you can keep your users engaged with fresh and relevant content while improving your search engine rankings."
            heading="BLOG INTEGRATION"
          />
          <WebsiteFeatureCart
            img={"/Images/cstmize-theme-integration.png"}
            title="As such, it's crucial to make a great first impression. One effective way to achieve this is by customizing your website's theme to align with your brand's."
            heading="CUSTOMIZE / THEME INTEGRATION"
          />
        </div>
      </div>
    </div>
  );
}

export default WebsiteFeature