import React from "react";

const Button = ({ content, color, borderColor, background, img, onClick }) => {
  return (
    <button
      style={{ color, borderColor, background }}
      onClick={onClick}
      className=" w-full text-white text-center font-[700] bg-[#0dcaf0] px-4 py-4 mr-5 mt-2  tracking-[0.75] rounded-full  "
    >
      {content}
    </button>
  );
};

export default Button;
