import React from "react";
import { PhoneIcon, ArrowSmRightIcon } from "@heroicons/react/outline";
import { ChipIcon, SupportIcon } from "@heroicons/react/solid";

const Support = () => {
  return (
    <div name="support" className="w-full mt-24 md:mt-0">
      <div className="w-full h-[700px] bg-gray-900/90 absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src="/Images/abc.png"
          alt="/"
        />
      </div>

      <div className="max-w-[1240px] mx-auto text-white relative">
        <div className="px-4 py-12 text-justify lg:text-center">
          <h2 className="text-3xl pt-8 text-slate-300 uppercase text-center">
            Support
          </h2>
          <h1
            className="font-[700]  md:font-[900] text-[28px] md:text-[40px] text-center uppercase whitespace-wrap  "
          >
            Expert Support Services to Help You Succeed{" "}
          </h1>
          <p className="py-4 text-2xl text-justify text-slate-300 hidden sm:block md:block">
            Our team of dedicated support professionals is available to answer
            any questions you may have and help you overcome any issues that
            arise. Whether you need technical support, assistance with campaign
            optimization, or guidance on best practices, our team is here to
            help.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <PhoneIcon className="w-16 p-4 bg-[#ffc107] text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Targeted Advertising</h3>
              <p className="text-gray-600 text-justify text-base">
                Digital marketing allows businesses to reach their target
                audience with precision and accuracy. Through various targeting
                options, such as demographics, interests, behaviors, and more,
                businesses can deliver personalized marketing messages.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <p className="flex items-center text-[#000000] font-bold text-[18px]">
                Contact Us <ArrowSmRightIcon className="w-5 ml-2" />
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <SupportIcon className="w-16 p-4 bg-[#ffc107] text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-justify text-2xl my-6">
                Measurable Result
              </h3>
              <p className="text-gray-600 text-justify text-base">
                Digital marketing provides businesses with the ability to
                measure and analyze the effectiveness of their marketing
                campaigns in real-time. By tracking metrics like website
                traffic, click-through rates, conversion rates, and more.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <p className="flex items-center text-black font-bold text-[18px]">
                Contact Us <ArrowSmRightIcon className="w-5 ml-2" />
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <ChipIcon className="w-16 p-4 bg-[#ffc107] text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Cost-Effective</h3>
              <p className="text-gray-600 text-justify text-base">
                Digital marketing is generally more cost-effective than
                traditional marketing methods like print or television
                advertising. With digital marketing, businesses can reach a
                larger audience at a lower cost, and can adjust their budget and
                strategies.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <p className="flex items-center text-black font-bold text-[18px]">
                Contact Us <ArrowSmRightIcon className="w-5 ml-2" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
