import React, { useState } from "react";

function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleAccordion() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="border-t-2 rounded-[25px] border-gray-300  py-[1%]  ">
      <div
        className="flex items-center justify-between px-4 py-2 cursor-pointer select-none"
        onClick={toggleAccordion}
      >
        <div className="flex">
          <img
            src="/Images/help.png"
            alt=""
            className="md:h-10 md:w-10 h-6 w-6 my-auto"
          />
          <h3 className="font-medium text-lg ml-6">{props.title}</h3>
        </div>
        <svg
          className={`w-10 h-10 transition-transform ${
            isOpen ? "transform rotate-0" : "transform rotate-180"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M9 13l3-3 3 3H9z" />
        </svg>
      </div>
      {isOpen && <div className="px-4 py-2">{props.children}</div>}
    </div>
  );
}

export default Accordion;
