import React from "react";
import Navbar from "../NavBar/Navbar";
import Accordion from "../../components/Accordion";
import Footer from "../Footer/Footer";

const FAQs = () => {
  return (
    <div>
      <div>
        <Navbar />

        <div
          className="pt-[10%]"
          style={{
            position: "relative",
            backgroundImage: `url("/Images/faqs.png")`,
            backgroundSize: "cover",
          }}
        >
          <div className="p-[5%] pb-[3%]">
            <div className="flex mt-[15%] md:mt-0 items-center bg-slate-600 w-[310px] rounded-[6px] mb-[25px] p-2">
              <img src="/Images/b-glob.svg" alt="" className="w-[35px]" />
              <span className="text-white text-[18px] ml-[1%]">
                {" "}
                India’s Trusted Service Provider
              </span>
            </div>
            <h1 className="text-[2rem] text-white leading-[1.2] mb-[8px] font-[600]">
              Frequently Asked <b className="text-[#dada3c]"> Questions...</b>
            </h1>
            <p className="text-white mb-[10%] md:mb-0">
              Read Some of the Commonly Asked User Queries Related to Our
              Products & Services.
            </p>
          </div>
        </div>
        <div className="mx-[3%]  mt-[2%] shadow-lg shadow-gray-100 border rounded-[25px]">
          <Accordion title="What kind of software development services do you offer?">
            <p>
              We offer custom software development, web application development,
              desktop application development, mobile app development, and cloud
              application development services.
            </p>
          </Accordion>
          <Accordion title="What types of websites can you build?">
            <p>
              We can build all types of websites, including static websites,
              dynamic websites, e-commerce websites, CMS-based websites, and web
              portals.
            </p>
          </Accordion>
          <Accordion title="Can you develop mobile apps for both iOS and Android platforms?">
            <p>
              Yes, we have expertise in developing mobile apps for both iOS and
              Android platforms using native and hybrid app development
              technologies.
            </p>{" "}
          </Accordion>
          <Accordion title="How can you help us with SEO?">
            <p>
              We can help you with on-page optimization, off-page optimization,
              local SEO, keyword research, content optimization, and technical
              SEO to improve your website's search engine ranking and
              visibility.
            </p>{" "}
          </Accordion>
          <Accordion title="What digital marketing services do you offer?">
            <p>
              We offer a complete range of digital marketing services, including
              social media marketing, email marketing, PPC advertising, content
              marketing, and online reputation management.
            </p>{" "}
          </Accordion>
          <Accordion title="Can you help us with video editing and production?">
            <p>
              Yes, we have a team of skilled video editors and producers who can
              help you with video editing, animation, motion graphics, and
              post-production services.
            </p>
          </Accordion>
          <Accordion title="Do you provide content writing services?">
            <p>
              Yes, we provide high-quality content writing services, including
              website content, blog posts, articles, social media posts, and
              product descriptions.
            </p>
          </Accordion>
          <Accordion title="How long does it take to develop a website or a mobile app?">
            <p>
              The development time depends on the complexity and features of the
              project. We can provide you with an estimated timeline and deliver
              the project within the deadline.
            </p>{" "}
          </Accordion>
          <Accordion title="How much does it cost to develop a website or a mobile app?">
            <p>
              The cost of development depends on the project's scope, features,
              and requirements. We can provide you with a detailed proposal with
              a breakdown of costs and payment milestones.
            </p>{" "}
          </Accordion>
          <Accordion title="Do you provide post-development support and maintenance?">
            <p>
              Yes, we provide post-development support and maintenance services,
              including bug fixing, security updates, performance optimization,
              and feature enhancements.
            </p>{" "}
          </Accordion>
          <Accordion title="How do you ensure the security of our website or app?">
            <p>
              We follow industry-standard security practices and use the latest
              security tools and technologies to ensure the security of your
              website or app.
            </p>
          </Accordion>
          <Accordion title="Can you integrate third-party services or APIs into our website or app?">
            <p>
              Yes, we have experience in integrating third-party services and
              APIs into websites and mobile apps, including payment gateways,
              social media platforms, and other web services.
            </p>
          </Accordion>
          <Accordion title="Do you provide hosting and domain registration services?">
            <p>
              Yes, we can help you with hosting and domain registration services
              for your website or app.
            </p>{" "}
          </Accordion>
          <Accordion title="How do you ensure the quality of your work?">
            <p>
              We have a team of skilled developers, designers, and digital
              marketing experts who follow a rigorous quality assurance process
              to ensure the quality of our work.
            </p>{" "}
          </Accordion>
          <Accordion title="Do you offer any guarantees or warranties?">
            <p>
              Yes, we offer a warranty period for our work and provide
              guarantees on the functionality and performance of the software,
              website, or app we develop.
            </p>{" "}
          </Accordion>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
