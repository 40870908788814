import React from "react";

const Heading = ({ content, margin }) => {
  return (
    <h1
      style={{ margin }}
      className="font-[700]  md:font-[900]    text-[28px]
     md:text-[40px]   leading-[38px] md:leading-[56px] uppercase  md:tracking-[-0.02em] 
      whitespace-wrap  "
    >
      {content}
    </h1>
  );
};

export default Heading;
