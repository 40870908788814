import React, { useState } from "react";
import FreeQuote from "../FreeQuotes/FreeQuote";

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popHandler = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div
      style={{
        backgroundImage: `url("/Images/softwarebgimg.jpg")`,
        backgroundSize: "cover",
      }}
    >
      <div className="bg-transparent py-[5%] px-[2%] h-[96vh] lg:pb-[18%] md:pb-[20%] pb-0 ">
        <div className="md:w-7/12 md:mt-[3%]  py-20 px-4 sm:px-6 lg:px-8">
          <h1 className="text-[#FFDB16] pb-[2%]  tracking-[0.1em]  uppercase  md:font-[700] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
            Your Online Business
          </h1>
          <p className=" tracking-[0.02em] pb-[2%] text-[#FCFCFD] uppercase font-[500] md:font-[900] text-[28px] md:text-[52px] leading-[38px] md:leading-[68px]">
            Get everything you need for
          </p>
          <p className="text-[#EEF2F6] md:mr-[6%] pb-[2%] text-justify tracking-[-0.02em]  my-[10px] font-[300] md:font-[400] text-[16px] md:text-[20px] leading-[20px] md:leading-[28px]">
            At our company, we offer comprehensive software development services
            to help businesses of all sizes build custom software solutions
            tailored to their unique needs and requirements. Our team of
            experienced software developers, designers, and engineers works
            closely with clients to understand their specific business
            challenges and design software solutions that meet those challenges
            head-on.
          </p>
          <div className="text-center mt-[3%]">
            <button
              onClick={() => setShowPopup(!showPopup)}
              className=" uppercase  bg-transparent text-white hover:text-black transition-[0.5s] hover:bg-[#FFDB16] border-[2px] border-[#FF9800] font-[700] py-3 px-10  rounded-full focus:outline-none focus:shadow-outline"
              type="submit"
            >
              get started
            </button>
          </div>
        </div>
      </div>
      {showPopup ? <FreeQuote popHandler={popHandler} /> : ""}
    </div>
  );
};

export default Home;
