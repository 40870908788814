export const links = [
         {
           name: "Services",
           submenu: true,
           sublinks: [
             {
               Head: "Design & Development",
               sublink: [
                 { name: "Website Development", link: "/website-development" },
                 {
                   name: "Mobile App Development",
                   link: "/mobile-application-development",
                 },
                 {
                   name: "Software Development",
                   link: "/software-development",
                 },
                 {
                   name: "Web  Designing ",
                   link: "/web-design-development",
                 },

                 { name: "UI/UX Design ", link: "/uxuidesign" },
               ],
             },

             {
               Head: "Marketing",
               sublink: [
                 { name: "Digital Marketing ", link: "/digital-marketing" },
                 {
                   name: "Social Media Marketing",
                   link: "/social-media-marketing",
                 },
                 { name: "Content Marketing", link: "/content-marketing" },
                 {
                   name: "Search Engine Optimization",
                   link: "/search-engine-optimization",
                 },

                 { name: "Logo Design", link: "/logo-design" },
                
               ],
             },
            
           ],
         },
         
       ];
