import React,{useRef} from "react";
import Heading from "../../components/Heading";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation } from "swiper";
SwiperCore.use([Autoplay]);

const WebDesignPre = () => {
  const swiperRef = useRef(null);

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  };
  return (
    <div className="py-[2%]">
      <div className="text-center">
        <Heading content="Some of our web Design work" />
      </div>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="my-[3%] ml-[3%]"
      >
        <Swiper
          style={{
            "--swiper-pagination-color": "#fbcc0d",
            "--swiper-pagination-bullet-inactive-color": "#000000",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
          }}
          spaceBetween={10}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{ delay: 1500 }}
          breakpoints={{
            640: { slidesPerView: 1 },
            778: { slidesPerView: 1 },
            1024: { slidesPerView: 2 },
            1224: { slidesPerView: 2 },
            1324: { slidesPerView: 2 },

            1524: { slidesPerView: 2.1 },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          onSwiper={handleSwiper}
        >
          <SwiperSlide>
            <div className="md:mx-[15%] mb-[10%]">
              <span className=" ">
                <img
                  src="/Images/webDesignpre1.png"
                  alt=""
                  className="border-[8px] object-cover border-gray-100"
                />
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:mx-[15%] mb-[10%] ">
              <span className=" ">
                <img
                  src="/Images/webDesignpre2.png"
                  alt=""
                  className="border-[8px] object-cover border-gray-100"
                />
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:mx-[15%] mb-[10%]">
              <span className=" ">
                <img
                  src="/Images/webDesignpre3.png"
                  alt=""
                  className="border-[8px] object-cover border-gray-100"
                />
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:mx-[15%] mb-[10%] ">
              <span className=" ">
                <img
                  src="/Images/webDesignpre4.png"
                  alt=""
                  className="border-[8px] object-cover border-gray-100"
                />
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:mx-[15%] mb-[10%]">
              <span className=" ">
                <img
                  src="/Images/webDesignpre5.png"
                  alt=""
                  className="border-[8px] object-cover border-gray-100"
                />
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:mx-[15%] mb-[10%] ">
              <span className=" ">
                <img
                  src="/Images/webDesignpre6.png"
                  alt=""
                  className="border-[8px] object-cover border-gray-100"
                />
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default WebDesignPre;
