import React from 'react'

const WebsiteFeatureCart = ({img,heading,title}) => {
  return (
    <div className="bg-[#373f52] flex flex-col items-center text-center  shadow-2xl rounded-[8px] mt-[18%] p-6 text-white">
      <div className="bg-[#13152c] border rounded-full mt-[-18%] mb-[8%] h-24 w-24">
        <img src={img} alt="" className="  mx-auto object-cover my-4  w-16 h-16 " />
      </div>
      <h1 className="my-3 text-[18px] font-[500] uppercase">{heading}</h1>
      <h2 className="mb-[1rem] text-justify ">{title}</h2>
    </div>
  );
}

export default WebsiteFeatureCart